/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useUnits } from '@web-stories-wp/units';
import {
  useBatchingCallback,
  useCallback,
  useMemo,
} from '@web-stories-wp/react';
/**
 * Internal dependencies
 */
import { useDropTargets } from '../../dropTargets';
import { useCanvas, useStory } from '../../../app';
import useElementOutOfCanvas from '../utils/useElementOutOfCanvas';

function useSingleSelectionDrag({
  setIsDragging,
  resetMoveable,
  selectedElement,
  setTransformStyle,
  shouldDuplicateOnDrag,
  shouldThrottleDrag,
  frame,
}) {
  const {
    actions: { handleDrag, handleDrop, setDraggingResource, isDropSource },
  } = useDropTargets();

  const { handleElementOutOfCanvas } = useElementOutOfCanvas();

  const { duplicateElementsById, updateSelectedElements } = useStory(
    (state) => ({
      duplicateElementsById: state.actions.duplicateElementsById,
      updateSelectedElements: state.actions.updateSelectedElements,
    })
  );

  const { editorToDataX, editorToDataY } = useUnits(
    ({ actions: { editorToDataX, editorToDataY } }) => ({
      editorToDataX,
      editorToDataY,
    })
  );

  const { duplicateDragData, setDuplicateDragData } = useCanvas(
    ({ actions: { setDuplicateDragData }, state: { duplicateDragData } }) => ({
      duplicateDragData,
      setDuplicateDragData,
    })
  );

  const resetDragging = useBatchingCallback(
    (target) => {
      setIsDragging(false);
      setDraggingResource(null);
      resetMoveable(target);

      if (duplicateDragData) {
        const isOriginal =
          duplicateDragData.originalElId &&
          selectedElement.id === duplicateDragData.originalElId;

        if (isOriginal) return;

        setDuplicateDragData(null);
      }
    },
    [
      duplicateDragData,
      setIsDragging,
      setDraggingResource,
      setDuplicateDragData,
      resetMoveable,
    ]
  );

  const onDragEnd = useCallback(
    ({ target, isDrag }) => {
      if (!isDrag) {
        return false;
      }
      if (handleElementOutOfCanvas(target)) {
        setIsDragging(false);
        setDraggingResource(null);
        return undefined;
      }

      const roundToZero = (num) => (Math.abs(num) <= 1 ? 0 : num);

      // When dragging finishes, set the new properties based on the original + what moved meanwhile.
      const [deltaX, deltaY] = frame.translate;
      if (deltaX !== 0 || deltaY !== 0) {
        updateSelectedElements({
          properties: ({ x, y }) => ({
            x: roundToZero(x + editorToDataX(deltaX)),
            y: roundToZero(y + editorToDataY(deltaY)),
          }),
        });
        if (isDropSource(selectedElement.type)) {
          handleDrop(selectedElement.resource, selectedElement.id);
        }
      }
      resetDragging(target);
      return undefined;
    },
    [
      editorToDataX,
      editorToDataY,
      frame.translate,
      isDropSource,
      handleDrop,
      handleElementOutOfCanvas,
      resetDragging,
      selectedElement.id,
      selectedElement.resource,
      selectedElement.type,
      setDraggingResource,
      setIsDragging,
      updateSelectedElements,
    ]
  );

  const onDrag = useCallback(
    ({ target, beforeTranslate, inputEvent, clientX, clientY }) => {
      const isDrag = inputEvent instanceof MouseEvent;

      if (isDrag && shouldDuplicateOnDrag && !duplicateDragData) {
        setDuplicateDragData({
          inputEvent,
          originalElId: selectedElement.id,
        });
        duplicateElementsById({
          elementIds: [selectedElement.id],
          pasteInPlace: true,
        });
        onDragEnd({ target, isDrag });

        return;
      }
      setIsDragging(true);
      if (isDropSource(selectedElement.type)) {
        setDraggingResource(selectedElement.resource);
      }
      setTransformStyle(target, { translate: beforeTranslate });
      if (isDropSource(selectedElement.type)) {
        handleDrag(
          selectedElement.resource,
          clientX,
          clientY,
          selectedElement.id
        );
      }
      return undefined;
    },
    [
      duplicateDragData,
      duplicateElementsById,
      onDragEnd,
      handleDrag,
      isDropSource,
      selectedElement.id,
      selectedElement.resource,
      selectedElement.type,
      setDraggingResource,
      setDuplicateDragData,
      setIsDragging,
      setTransformStyle,
      shouldDuplicateOnDrag,
    ]
  );

  const onDragStart = useCallback(
    ({ set }) => {
      // Note: we can't set isDragging true here since a "click" is also considered dragStart.
      set(frame.translate);
      return undefined;
    },
    [frame.translate]
  );

  const dragProps = useMemo(
    () => ({
      onDrag,
      // ⇧ key locks the drag to the x and y axis only
      throttleDragRotate: shouldThrottleDrag ? 90 : 0,
      onDragStart,
      onDragEnd,
    }),
    [onDrag, onDragEnd, onDragStart, shouldThrottleDrag]
  );

  return dragProps;
}

export default useSingleSelectionDrag;
