import { Text, THEME_CONSTANTS } from '@web-stories-wp/design-system';
import Dialog from '@web-stories-wp/story-editor/src/components/dialog';

function StoryWasModifiedDialog({ isOpen, onClose, onClick }) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      ariaHideApp={false}
      primaryText="Ok"
      onPrimary={onClick}
      onSecondary={onClose}
      secondaryText="Cancel"
    >
      <Text size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.MEDIUM}>
      This story has been edited by someone else while you were editing in Studio. None of your changes have been lost. Any pages you have added will appear at the end of the story - you will need to reorder these when you are returned to the CMS.
      </Text>
    </Dialog>
  );
}

export default StoryWasModifiedDialog;
