/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useCallback, useMemo, useRef } from '@web-stories-wp/react';

/**
 * Internal dependencies
 */
import areEventsDragging from '../../../utils/areEventsDragging';
import { useDropTargets } from '../../dropTargets';
import { useCanvas, useStory } from '../../../app';

function useMultiSelectionDrag({
  targetList,
  frames,
  setTransformStyle,
  onGroupEventStart,
  onGroupEventEnd,
  isDragging,
  setIsDragging,
  shouldDuplicateOnDrag,
  shouldThrottleDrag,
}) {
  const {
    state: { draggingResource },
  } = useDropTargets();
  const {
    duplicateDragData,
    nodesById,
    handleSelectElement,
    setDuplicateDragData,
  } = useCanvas(
    ({
      state: { duplicateDragData, nodesById },
      actions: { handleSelectElement, setDuplicateDragData },
    }) => ({
      duplicateDragData,
      nodesById,
      handleSelectElement,
      setDuplicateDragData,
    })
  );

  const { duplicateElementsById } = useStory((state) => ({
    duplicateElementsById: state.actions.duplicateElementsById,
  }));

  const eventTracker = useRef({});

  // Let's check if we consider this a drag or a click, In case of a click handle click instead.
  // We are doing this here in Moveable selection since it takes over the mouseup event
  // and it can be captured here and not in the frame element.
  // @todo Add integration test for this!
  const clickHandled = useCallback(
    (inputEvent) => {
      if (areEventsDragging(eventTracker.current, inputEvent)) {
        // No click was found/handled.
        return false;
      }

      // Click was handled.
      return true;
    },
    [eventTracker.current]
  );

  const startEventTracking = useCallback((evt) => {
    const { timeStamp, clientX, clientY } = evt;
    eventTracker.current = {
      timeStamp,
      clientX,
      clientY,
    };
  }, []);

  const hideHandles = isDragging || Boolean(draggingResource);

  const onDragGroupEnd = useCallback(
    ({ targets, inputEvent }) => {
      setIsDragging(false);
      if (!clickHandled(inputEvent)) {
        onGroupEventEnd({ targets });
      }
    },
    [clickHandled, onGroupEventEnd, setIsDragging]
  );

  const onDragGroup = useCallback(
    ({ events, inputEvent, targets }) => {
      const isDrag = inputEvent instanceof MouseEvent;
      let originalElIds = [];

      for (let eventIndex = 0; eventIndex < events.length; eventIndex++) {
        const { beforeTranslate, target } = events[eventIndex];
        const { element } = targetList[eventIndex];

        if (isDrag && shouldDuplicateOnDrag && !duplicateDragData) {
          originalElIds.push(element.id);
          continue;
        }

        const sFrame = frames[eventIndex];
        sFrame.translate = beforeTranslate;
        setTransformStyle(element.id, target, sFrame);
      }

      if (originalElIds.length > 0) {
        setDuplicateDragData({ inputEvent, originalElIds });
        duplicateElementsById({
          elementIds: originalElIds,
          pasteInPlace: true,
        });
        onDragGroupEnd({
          targets,
          inputEvent,
        });
      }
    },
    [
      duplicateDragData,
      duplicateElementsById,
      frames,
      onDragGroupEnd,
      setDuplicateDragData,
      setTransformStyle,
      shouldDuplicateOnDrag,
    ]
  );

  const onDragGroupStart = useCallback(
    ({ events, inputEvent }) => {
      startEventTracking(inputEvent);
      if (!isDragging) {
        setIsDragging(true);
      }
      onGroupEventStart({ events, isDrag: true });
    },
    [isDragging, onGroupEventStart, setIsDragging, startEventTracking]
  );

  const dragProps = useMemo(
    () => ({
      onDragGroup,
      onDragGroupEnd,
      onDragGroupStart,
      // ⇧ key locks the drag to the x and y axis only
      throttleDragRotate: shouldThrottleDrag ? 90 : 0,
      // When dragging, let's disable resizing, rotating and handles.
      className: `default-moveable ${hideHandles ? 'hide-handles' : ''}`,
      resizable: !hideHandles,
      rotatable: !hideHandles,
    }),
    [
      hideHandles,
      onDragGroup,
      onDragGroupEnd,
      onDragGroupStart,
      shouldThrottleDrag,
    ]
  );

  return dragProps;
}

export default useMultiSelectionDrag;
