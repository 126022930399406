import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import { useUploadWithPreview } from '@web-stories-wp/story-editor';

function MediaUpload({ render }) {
  const fileInputRef = useRef(null);
  const uploadWithPreview = useUploadWithPreview();

  const open = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadFile = useCallback(
    (files) => {
      uploadWithPreview(files);
    },
    [uploadWithPreview]
  );

  return (
    <>
      <input
        hidden
        ref={fileInputRef}
        type="file"
        id="media-file"
        name="media-file"
        onChange={(evt) => {
          var files = evt.target.files;
          uploadFile([...files]);
        }}
      />
      {render(open)}
    </>
  );
}

MediaUpload.propTypes = {
  render: PropTypes.func.isRequired,
};

export default MediaUpload;
