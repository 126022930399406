export const fileToBase64 = (file: File) =>
  new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () =>
      reject(
        new Error(
          `Failed to create file reader for file ${file.name}, could not read as Data URL`
        )
      );
  });
