/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

function videoDuration({ pages, ...rest }) {
  return {
    pages: pages.map(reducePage),
    ...rest,
  };
}

function reducePage({ elements, ...rest }) {
  return {
    elements: elements.map(updateElement),
    ...rest,
  };
}

function updateElement(element) {
  if (
    element.type === 'video' &&
    element.resource &&
    element.resource?.lengthFormatted &&
    !element.resource?.length
  ) {
    const times = element.resource.lengthFormatted.split(':');
    const timesNumbers = times.map(Number);
    if (timesNumbers.length === 2) {
      const [minutes, seconds] = timesNumbers;
      const length = 60 * minutes + seconds;

      element.resource.length = length;
    } else if (timesNumbers.length === 3) {
      const [hours, minutes, seconds] = timesNumbers;
      const length = 60 * 60 * hours + 60 * minutes + seconds;

      element.resource.length = length;
    }
  }
  return element;
}

export default videoDuration;
