import styled from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import { HeaderTitle } from '@web-stories-wp/story-editor';
import React from 'react';
import Buttons from './buttons';

const Background = styled.header.attrs({
  role: 'group',
  'aria-label': __('Story canvas header', 'web-stories'),
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.bg.primary};
`;

const Head = styled.div`
  flex: 1 1 auto;
  padding: 1em;
`;

const ButtonCell = styled.div`
  grid-area: buttons;
`;

function HeaderLayout() {
  return (
    <Background>
      <Head>
        <HeaderTitle />
      </Head>
      <ButtonCell>
        <Buttons />
      </ButtonCell>
    </Background>
  );
}

export default React.memo(HeaderLayout);
