import React, { useState } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@web-stories-wp/story-editor';
import { CopyPageToClipboardButton } from './copyPageToClipboardButton';
import { ButtonStyle } from './studioButton';
import { useUser, useAnyResourceInLoadingState } from '@hooks';
import { BackToStoryButton } from './backToStoryButton';
import SavePagesButton from './savePagesButton';
import { CopyPageAmpHtmlToClipboardButton } from '@components/header/buttons/copyPageAmpHtmlToClipboardButton';
import { useLocalMedia } from '@src/web-stories-wp/story-editor/src/app';

const ButtonList = styled.nav`
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  height: 100%;
`;

const List = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const Spinner = styled.div`
  position: absolute;
  top: 0;
`;

const IconWithSpinner = styled.div`
  position: relative;
`;

function Loading({ className }) {
  return (
    <Spinner className={className}>
      <CircularProgress size={32} />
    </Spinner>
  );
}

const SaveButton = styled(SavePagesButton)`
  ${ButtonStyle}
  background-color: #1c62eb;
  &:hover,
  &:active,
  &:focus {
    background-color: #114dc3;
    /*important is required cause web-stories styles use it so to override we need to use it also*/
    color: white !important;
  }
`;

const CenteredLoading = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function Buttons() {
  const user = useUser();
  const [isSaving, setIsSaving] = useState(false);

  const { isUploading } = useLocalMedia((state) => ({
    isUploading: state.state.isUploading,
  }));

  const anyResourceInLoadingState = useAnyResourceInLoadingState();

  const saveDisabled = isSaving || isUploading || anyResourceInLoadingState;

  return (
    <ButtonList>
      <List>
        {user.isSystemAdmin && (
          <CopyPageAmpHtmlToClipboardButton disabled={saveDisabled} />
        )}
        {user.isSystemAdmin && (
          <CopyPageToClipboardButton disabled={saveDisabled} />
        )}
        <BackToStoryButton disabled={isSaving} />
        <IconWithSpinner>
          <SaveButton
            disabled={saveDisabled}
            onSave={() => setIsSaving(true)}
            onSaveFinish={() => setIsSaving(false)}
          />
          {isSaving && <CenteredLoading />}
        </IconWithSpinner>
      </List>
    </ButtonList>
  );
}

export default Buttons;
