import PropTypes from 'prop-types';
import { useCallback, useEffect } from '@web-stories-wp/react';
import { __ } from '@web-stories-wp/i18n';
import { SearchInput } from '../../../common';
import {
  PaneHeader,
  PaneInner,
  SearchInputContainer,
  StyledPane,
} from '../common/styles';
import paneId from './paneId';
import { useExternalMedia } from '@src/web-stories-wp/story-editor/src/app/externalMedia';
import ExternalMediaList from './components/externalMediaList';
import { useMemo, useRef } from 'react';
import ExternalMediaGallery from './components/externalMediaGallery';
import useMediaProvidersFeatures from '@src/web-stories-wp/story-editor/src/app/media/useMediaProvidersFeatures';
import OptionsList from '@src/components/optionsList/optionsList';

const INITIAL_SKIP_COUNT = 0;

function ExternalMediaContent(props) {
  const {
    actions: { loadAssets, updateFilters },
    state: {
      assets,
      isLoading,
      mediaType,
      hasMore,
      skipCount,
      searchTerm,
      filters,
      hasError,
    },
  } = useExternalMedia();
  const { isActive } = props;

  const searchRef = useRef();

  const mediaFeatures = useMediaProvidersFeatures(mediaType.value);

  useEffect(() => {
    if (!isActive) return;
    if (skipCount === INITIAL_SKIP_COUNT) {
      loadAssets({ isInitLoad: true });
    }
  }, [loadAssets, skipCount]);

  const onSearch = useCallback(
    (newSearchTerm) => {
      if (!isLoading)
        updateFilters({
          ...filters,
          searchText: { value: newSearchTerm },
        });
    },
    [updateFilters, isLoading, filters]
  );

  useEffect(() => {
    searchRef.current?.setValue('');
  }, [mediaType]);

  useEffect(() => {
    if (!filters.searchText?.value) {
      searchRef.current?.setValue('');
    }
  }, [filters]);

  const isGallery = mediaFeatures?.layoutType === 'grid';
  const isFolders = useMemo(
    () => assets.some((asset) => asset.type === 'folder'),
    [assets]
  );

  const filteredFolderOptions = useMemo(() => {
    if (!isFolders) return [];

    return assets
      .filter((folder) =>
        searchTerm
          ? folder.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          : true
      )
      .map((folder) => ({ label: folder.title, key: folder.id }));
  }, [assets, isFolders, searchTerm]);

  const selectFolder = useCallback(
    (folderId) => {
      const selectedOption = filteredFolderOptions.find(
        (folder) => folder.key === folderId
      );

      updateFilters({
        ...filters,
        folderName: { value: selectedOption.label },
        searchText: '',
      });
    },
    [filters, filteredFolderOptions, updateFilters]
  );

  return (
    <StyledPane id={paneId} {...props}>
      <PaneInner>
        <PaneHeader>
          <SearchInputContainer>
            <SearchInput
              initialValue={searchTerm}
              placeholder={__('Search', 'web-stories')}
              onSearch={onSearch}
              incremental
              ref={searchRef}
            />
          </SearchInputContainer>
        </PaneHeader>
        {isFolders ? (
          <OptionsList
            disableAllOptions
            options={filteredFolderOptions}
            setChosenOption={selectFolder}
            shouldDisplayOptionsList
            shouldDisplayNoOptionFoundMessage={
              filteredFolderOptions.length === 0
            }
          />
        ) : (
          <>
            {isGallery ? (
              <ExternalMediaGallery
                assets={assets}
                loadAssets={loadAssets}
                isLoading={isLoading}
                hasMore={hasMore}
                hasError={hasError}
                enableDeletion={mediaFeatures?.enableDeletion}
                enableEditInAzzuu={mediaFeatures?.enableEditInAzzuu}
              />
            ) : (
              <ExternalMediaList
                assets={assets}
                loadAssets={loadAssets}
                isLoading={isLoading}
                hasMore={hasMore}
                hasError={hasError}
                enableDeletion={mediaFeatures?.enableDeletion}
                enableEditInAzzuu={mediaFeatures?.enableEditInAzzuu}
              />
            )}
          </>
        )}
      </PaneInner>
    </StyledPane>
  );
}

ExternalMediaContent.propTypes = {
  isActive: PropTypes.bool,
};

export default ExternalMediaContent;
