/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Party Text', 'sticker name', 'web-stories');

function PartyCircleText({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 52 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M50.9298 26.6887C50.9086 27.0112 50.8029 27.2649 50.6126 27.45C50.4256 27.6352 50.1837 27.7181 49.8871 27.6987C49.5904 27.6792 49.3599 27.5653 49.1954 27.3569C49.0342 27.1488 48.9642 26.8835 48.9854 26.561L49.034 25.821L47.7861 25.739L47.8115 25.3521L51.0038 25.5617L50.9298 26.6887ZM49.352 26.5268C49.3357 26.7751 49.3737 26.9606 49.466 27.0832C49.5614 27.2093 49.7106 27.279 49.9138 27.2924C50.1169 27.3057 50.2724 27.256 50.3802 27.1433C50.491 27.0339 50.5546 26.8551 50.5709 26.6069L50.6157 25.9249L49.3968 25.8448L49.352 26.5268Z"
        fill="white"
      />
      <path
        d="M48.0438 30.3836L48.3425 28.9654L47.5444 28.4505L47.6283 28.0521L50.5021 29.9304L50.4072 30.381L47.021 30.936L47.1059 30.5328L48.0438 30.3836ZM48.4285 30.3209L49.6273 30.1326L50.0947 30.0774L50.0967 30.0679L49.7035 29.8415L48.6693 29.1778L48.4285 30.3209Z"
        fill="white"
      />
      <path
        d="M47.7172 32.9429L47.9782 32.2229L46.7295 31.7703L46.8616 31.4057L49.8694 32.4958L49.4565 33.6351C49.3453 33.942 49.1771 34.1594 48.952 34.2875C48.7268 34.4156 48.479 34.4306 48.2086 34.3326C47.996 34.2555 47.8382 34.1313 47.7353 33.96C47.6324 33.7887 47.5911 33.5898 47.6112 33.3634L46.0143 33.7436L46.1712 33.3106L47.7172 32.9429ZM48.32 32.3467L48.0409 33.1169C47.8867 33.5422 47.9889 33.8199 48.3474 33.9498C48.7059 34.0797 48.9622 33.932 49.1164 33.5067L49.3955 32.7365L48.32 32.3467Z"
        fill="white"
      />
      <path
        d="M47.2037 37.5873L47.7277 36.6032L45.2248 35.2704L45.4071 34.9281L47.91 36.2609L48.434 35.2769L48.7549 35.4478L47.5246 37.7582L47.2037 37.5873Z"
        fill="white"
      />
      <path
        d="M44.6632 38.5223L43.6438 37.7977L43.8685 37.4817L44.8878 38.2062L47.1389 38.4027L46.8721 38.7781L45.0995 38.5945L45.8555 40.2083L45.5887 40.5836L44.6632 38.5223Z"
        fill="white"
      />
      <path
        d="M43.2297 43.1085C42.9962 43.332 42.7469 43.4477 42.4816 43.4556C42.2185 43.4659 41.9842 43.3636 41.7786 43.1489C41.573 42.9341 41.4799 42.6944 41.4994 42.4297C41.5211 42.1673 41.6487 41.9244 41.8822 41.7009L42.4179 41.1881L41.5531 40.2847L41.8332 40.0165L44.0455 42.3275L43.2297 43.1085ZM42.1756 41.9233C41.9958 42.0954 41.8986 42.2578 41.8838 42.4107C41.869 42.568 41.9319 42.7203 42.0727 42.8673C42.2135 43.0144 42.3617 43.0827 42.5174 43.0724C42.6729 43.0667 42.8405 42.9778 43.0203 42.8057L43.514 42.3331L42.6693 41.4507L42.1756 41.9233Z"
        fill="white"
      />
      <path
        d="M38.6422 43.855L39.8173 43.0067L39.5733 42.0888L39.9034 41.8505L40.7658 45.1737L40.3924 45.4432L37.5138 43.5755L37.8479 43.3343L38.6422 43.855ZM38.9689 44.0675L39.9826 44.7347L40.3655 45.0083L40.3733 45.0026L40.2348 44.5706L39.9161 43.3837L38.9689 44.0675Z"
        fill="white"
      />
      <path
        d="M36.6541 45.532L37.3328 45.177L36.7172 44.0001L37.0608 43.8204L38.5436 46.6553L37.4698 47.2169C37.1806 47.3682 36.9097 47.4151 36.6571 47.3575C36.4046 47.3 36.2117 47.1438 36.0784 46.8889C35.9735 46.6885 35.941 46.4903 35.9808 46.2945C36.0206 46.0986 36.1243 45.924 36.2921 45.7706L34.8574 44.9729L35.2654 44.7595L36.6541 45.532ZM37.5013 45.4992L36.7754 45.8789C36.3745 46.0885 36.2624 46.3623 36.4392 46.7002C36.6159 47.0381 36.9047 47.1022 37.3056 46.8925L38.0315 46.5128L37.5013 45.4992Z"
        fill="white"
      />
      <path
        d="M33.1665 48.6112L34.216 48.2353L33.2599 45.5657L33.6249 45.4349L34.5811 48.1045L35.6307 47.7286L35.7533 48.0708L33.2891 48.9535L33.1665 48.6112Z"
        fill="white"
      />
      <path
        d="M30.6442 47.5881L30.3818 46.3653L30.761 46.284L31.0234 47.5067L32.5507 49.1719L32.1005 49.2686L30.9172 47.936L30.3848 49.6367L29.9346 49.7333L30.6442 47.5881Z"
        fill="white"
      />
      <path
        d="M26.5012 49.9995C26.1781 50.007 25.916 49.9242 25.7148 49.7511C25.5137 49.5812 25.4097 49.3476 25.4028 49.0504C25.3959 48.7532 25.489 48.5135 25.6819 48.3312C25.8749 48.1522 26.133 48.0589 26.4561 48.0514L27.1975 48.0343L27.1685 46.784L27.5562 46.775L27.6303 49.9734L26.5012 49.9995ZM26.5226 48.4136C26.2739 48.4193 26.0925 48.4736 25.9785 48.5765C25.8614 48.6826 25.8052 48.8375 25.8099 49.041C25.8146 49.2445 25.8779 49.395 25.9997 49.4924C26.1184 49.593 26.3022 49.6405 26.5509 49.6347L27.2342 49.6189L27.2059 48.3977L26.5226 48.4136Z"
        fill="white"
      />
      <path
        d="M22.524 47.4422L23.963 47.615L24.4056 46.7747L24.8099 46.8232L23.1917 49.8511L22.7345 49.7963L21.8837 46.4719L22.2928 46.521L22.524 47.4422ZM22.6202 47.8199L22.9134 48.9975L23.0094 49.4582L23.019 49.4594L23.21 49.0478L23.7801 47.9592L22.6202 47.8199Z"
        fill="white"
      />
      <path
        d="M19.9385 47.3358L20.6784 47.5333L21.0209 46.2501L21.3956 46.3501L20.5706 49.4411L19.3997 49.1286C19.0844 49.0444 18.8531 48.8957 18.706 48.6825C18.5589 48.4693 18.5224 48.2238 18.5966 47.9459C18.6549 47.7273 18.765 47.5594 18.9268 47.442C19.0885 47.3247 19.2831 47.2662 19.5104 47.2667L18.9931 45.7088L19.438 45.8275L19.9385 47.3358ZM20.5847 47.8846L19.7932 47.6733C19.3561 47.5566 19.0883 47.6825 18.99 48.0509C18.8917 48.4193 19.061 48.6619 19.4982 48.7786L20.2896 48.9898L20.5847 47.8846Z"
        fill="white"
      />
      <path
        d="M15.2903 47.2374L16.3171 47.6717L17.4218 45.06L17.7789 45.2111L16.6743 47.8228L17.7011 48.2571L17.5595 48.5919L15.1487 47.5722L15.2903 47.2374Z"
        fill="white"
      />
      <path
        d="M14.1258 44.7762L14.7619 43.6995L15.0958 43.8967L14.4597 44.9735L14.4535 47.233L14.057 46.9988L14.0906 45.2171L12.5462 46.1063L12.1498 45.8721L14.1258 44.7762Z"
        fill="white"
      />
      <path
        d="M9.45766 43.7606C9.21471 43.5475 9.07773 43.3092 9.04672 43.0456C9.01358 42.7844 9.09503 42.5421 9.29106 42.3185C9.48709 42.095 9.7178 41.9814 9.9832 41.9778C10.2465 41.9766 10.4996 42.0825 10.7425 42.2956L11.3001 42.7846L12.1247 41.8444L12.4162 42.1001L10.3068 44.5053L9.45766 43.7606ZM10.5465 42.6073C10.3595 42.4432 10.1892 42.3605 10.0357 42.3591C9.87758 42.358 9.73142 42.434 9.59718 42.587C9.46294 42.7401 9.40774 42.8937 9.43158 43.0478C9.45086 43.2023 9.55403 43.3616 9.74111 43.5256L10.255 43.9763L11.0604 43.0579L10.5465 42.6073Z"
        fill="white"
      />
      <path
        d="M8.31116 39.2442L9.25487 40.3442L10.149 40.0241L10.4142 40.3332L7.17492 41.4708L6.87508 41.1213L8.4952 38.0964L8.76348 38.4091L8.31116 39.2442ZM8.12677 39.5876L7.5468 40.6536L7.30618 41.058L7.31249 41.0653L7.73145 40.8911L8.88742 40.4742L8.12677 39.5876Z"
        fill="white"
      />
      <path
        d="M6.47615 37.4271L6.88965 38.0718L8.0076 37.3547L8.21696 37.6811L5.52411 39.4084L4.86984 38.3884C4.69362 38.1137 4.62302 37.848 4.65803 37.5913C4.69304 37.3347 4.83159 37.1287 5.07368 36.9734C5.26408 36.8513 5.45859 36.8014 5.65719 36.8237C5.8558 36.8461 6.03891 36.934 6.20651 37.0876L6.87439 35.588L7.12302 35.9756L6.47615 37.4271ZM6.58365 38.2681L6.14136 37.5785C5.8971 37.1977 5.61448 37.1103 5.29351 37.3162C4.97255 37.522 4.9342 37.8154 5.17846 38.1962L5.62075 38.8857L6.58365 38.2681Z"
        fill="white"
      />
      <path
        d="M3.10531 34.2213L3.57004 35.2347L6.1476 34.0527L6.30924 34.4052L3.73168 35.5872L4.19641 36.6006L3.86595 36.7521L2.77485 34.3729L3.10531 34.2213Z"
        fill="white"
      />
      <path
        d="M3.90173 31.625L5.09695 31.2569L5.21107 31.6275L4.01585 31.9956L2.49018 33.6623L2.35467 33.2222L3.57891 31.9272L1.83827 31.5452L1.70275 31.1051L3.90173 31.625Z"
        fill="white"
      />
      <path
        d="M1.13561 27.7115C1.10079 27.3902 1.16112 27.122 1.31658 26.9069C1.46883 26.6922 1.69274 26.5688 1.98831 26.5367C2.28388 26.5047 2.53064 26.5771 2.72857 26.7539C2.9233 26.9311 3.03807 27.1803 3.07288 27.5016L3.15278 28.2389L4.3961 28.1042L4.43788 28.4897L1.25728 28.8344L1.13561 27.7115ZM2.71771 27.5986C2.69091 27.3512 2.62144 27.1751 2.50931 27.0702C2.39363 26.9625 2.23458 26.9196 2.03218 26.9415C1.82978 26.9635 1.68522 27.0393 1.59851 27.1689C1.50823 27.2957 1.4765 27.4828 1.5033 27.7302L1.57694 28.4097L2.79134 28.2781L2.71771 27.5986Z"
        fill="white"
      />
      <path
        d="M3.3401 23.522L3.28976 24.9705L4.1645 25.3404L4.15036 25.7473L0.996338 24.3912L1.01233 23.931L4.25271 22.8019L4.2384 23.2137L3.3401 23.522ZM2.97189 23.6499L1.82332 24.0416L1.37242 24.1763L1.37209 24.186L1.79835 24.3415L2.93132 24.8174L2.97189 23.6499Z"
        fill="white"
      />
      <path
        d="M3.21373 20.9619L3.08176 21.7164L4.39006 21.9452L4.32324 22.3272L1.17187 21.7759L1.38068 20.5822C1.43692 20.2607 1.56479 20.0174 1.76428 19.8522C1.96377 19.6869 2.20517 19.6291 2.48847 19.6787C2.7113 19.7176 2.88825 19.8126 3.01933 19.9634C3.1504 20.1143 3.22566 20.303 3.24509 20.5295L4.75171 19.8777L4.67236 20.3313L3.21373 20.9619ZM2.72365 21.6537L2.86481 20.8468C2.94276 20.4011 2.79393 20.1455 2.41831 20.0797C2.0427 20.014 1.81591 20.204 1.73796 20.6497L1.5968 21.4566L2.72365 21.6537Z"
        fill="white"
      />
      <path
        d="M2.89858 16.3294L2.55442 17.3899L5.2516 18.2652L5.1319 18.6341L2.43472 17.7587L2.09057 18.8191L1.74477 18.7069L2.55278 16.2172L2.89858 16.3294Z"
        fill="white"
      />
      <path
        d="M5.23848 14.9565L6.36737 15.4947L6.2005 15.8447L5.07161 15.3066L2.82037 15.5007L3.01853 15.085L4.79625 14.9605L3.77362 13.501L3.97177 13.0854L5.23848 14.9565Z"
        fill="white"
      />
      <path
        d="M5.87476 10.1606C6.06675 9.90065 6.29279 9.7442 6.55288 9.69125C6.81036 9.63636 7.05867 9.69724 7.2978 9.87388C7.53694 10.0505 7.66943 10.2709 7.69528 10.5351C7.71852 10.7973 7.63414 11.0584 7.44215 11.3183L7.00151 11.9149L8.00745 12.6579L7.77705 12.9698L5.20372 11.069L5.87476 10.1606ZM7.11516 11.1491C7.263 10.949 7.33119 10.7724 7.31973 10.6193C7.30759 10.4616 7.21964 10.3224 7.05589 10.2014C6.89213 10.0804 6.73444 10.0383 6.58282 10.0749C6.43053 10.1071 6.28046 10.2232 6.13262 10.4234L5.72654 10.9731L6.70909 11.6989L7.11516 11.1491Z"
        fill="white"
      />
      <path
        d="M10.235 8.67024L9.22136 9.70619L9.6181 10.5691L9.33334 10.8601L7.91786 7.7323L8.23991 7.40315L11.3945 8.75358L11.1063 9.04808L10.235 8.67024ZM9.8768 8.51647L8.76435 8.03157L8.34051 7.8271L8.33373 7.83403L8.54378 8.23621L9.05982 9.35146L9.8768 8.51647Z"
        fill="white"
      />
      <path
        d="M11.8529 6.70109L11.247 7.1695L12.0593 8.22029L11.7525 8.45746L9.79586 5.92636L10.7546 5.1852C11.0128 4.98558 11.2713 4.89197 11.53 4.90435C11.7888 4.91674 12.0061 5.0367 12.182 5.26425C12.3203 5.44321 12.3871 5.6326 12.3822 5.8324C12.3774 6.0322 12.3058 6.22231 12.1676 6.40272L13.7199 6.93661L13.3555 7.21825L11.8529 6.70109ZM11.0247 6.88187L11.6728 6.38085C12.0307 6.10416 12.0931 5.81496 11.8598 5.51328C11.6266 5.21159 11.3311 5.19909 10.9731 5.47579L10.325 5.97681L11.0247 6.88187Z"
        fill="white"
      />
      <path
        d="M14.7247 3.06819L13.7565 3.62104L15.1627 6.08351L14.8259 6.2758L13.4198 3.81334L12.4516 4.36618L12.2713 4.05048L14.5444 2.75249L14.7247 3.06819Z"
        fill="white"
      />
      <path
        d="M17.3475 3.63151L17.8217 4.78871L17.4629 4.93575L16.9887 3.77856L15.1912 2.40927L15.6173 2.23465L17.0174 3.33724L17.241 1.56926L17.6671 1.39464L17.3475 3.63151Z"
        fill="white"
      />
      <path
        d="M21.0606 0.494921C21.3775 0.431951 21.65 0.468424 21.878 0.60434C22.1053 0.737086 22.2479 0.949259 22.3059 1.24086C22.3638 1.53246 22.3134 1.78463 22.1547 1.99737C21.9954 2.20694 21.7572 2.34321 21.4403 2.40618L20.7128 2.5507L20.9565 3.77732L20.5762 3.85289L19.9528 0.715L21.0606 0.494921ZM21.3124 2.06094C21.5564 2.01245 21.7257 1.92774 21.8203 1.80682C21.9175 1.68209 21.9462 1.51989 21.9065 1.3202C21.8668 1.12052 21.7786 0.983198 21.6418 0.90824C21.5076 0.829482 21.3184 0.814347 21.0743 0.862833L20.404 0.996014L20.642 2.19412L21.3124 2.06094Z"
        fill="white"
      />
      <path
        d="M25.2531 2.3252L23.8062 2.40904L23.5187 3.31423L23.1122 3.33779L24.1709 0.0718686L24.6306 0.0452317L26.0545 3.16731L25.6431 3.19114L25.2531 2.3252ZM25.0917 1.97039L24.5954 0.86298L24.4196 0.426468L24.4099 0.427029L24.2945 0.865847L23.9255 2.03797L25.0917 1.97039Z"
        fill="white"
      />
      <path
        d="M27.9487 1.96209L27.1856 1.89719L27.073 3.22057L26.6866 3.1877L26.9578 0L28.1652 0.102699C28.4904 0.13036 28.7441 0.23626 28.9263 0.420401C29.1085 0.604541 29.1874 0.839898 29.163 1.12647C29.1438 1.35186 29.0649 1.5365 28.9262 1.68037C28.7874 1.82425 28.6061 1.91585 28.3823 1.95519L29.1644 3.39844L28.7055 3.35942L27.9487 1.96209ZM27.2164 1.53495L28.0326 1.60437C28.4834 1.64271 28.725 1.47191 28.7573 1.09196C28.7896 0.712013 28.5804 0.502869 28.1296 0.464527L27.3134 0.395103L27.2164 1.53495Z"
        fill="white"
      />
      <path
        d="M32.6225 1.24489L31.5362 0.994387L30.899 3.75754L30.5211 3.67041L31.1583 0.907255L30.0719 0.656749L30.1536 0.302498L32.7042 0.890642L32.6225 1.24489Z"
        fill="white"
      />
      <path
        d="M34.0939 3.42437L33.659 4.5969L33.2954 4.46203L33.7304 3.2895L33.3355 1.06468L33.7673 1.22483L34.0504 2.98427L35.4124 1.83511L35.8442 1.99526L34.0939 3.42437Z"
        fill="white"
      />
      <path
        d="M38.991 3.65358C39.2668 3.82201 39.4424 4.03345 39.518 4.28788C39.5953 4.53955 39.5565 4.79224 39.4015 5.04596C39.2465 5.29968 39.0386 5.45101 38.7777 5.49994C38.5186 5.54612 38.2511 5.48499 37.9753 5.31655L37.3424 4.92998L36.6905 5.99726L36.3596 5.79514L38.0271 3.06488L38.991 3.65358ZM38.1151 4.97597C38.3275 5.10567 38.5094 5.15809 38.6609 5.13324C38.8169 5.10731 38.9479 5.00747 39.054 4.83373C39.1601 4.65999 39.1883 4.49921 39.1384 4.35139C39.0931 4.2025 38.9642 4.06321 38.7518 3.93351L38.1686 3.57727L37.5319 4.61973L38.1151 4.97597Z"
        fill="white"
      />
      <path
        d="M40.8703 7.873L39.75 6.95354L38.925 7.42398L38.6102 7.16567L41.6028 5.48297L41.9587 5.7751L40.8884 9.03534L40.5699 8.77396L40.8703 7.873ZM40.9923 7.5028L41.3784 6.35232L41.5452 5.91227L41.5377 5.90612L41.1553 6.15042L40.0893 6.76169L40.9923 7.5028Z"
        fill="white"
      />
      <path
        d="M42.9795 9.3189L42.46 8.75613L41.4841 9.65701L41.2211 9.37206L43.5718 7.20208L44.3938 8.09252C44.6152 8.33235 44.731 8.58168 44.7412 8.8405C44.7514 9.09933 44.6509 9.32628 44.4396 9.52136C44.2733 9.67479 44.0905 9.75781 43.891 9.77042C43.6916 9.78302 43.496 9.72832 43.3042 9.6063L42.9077 11.1993L42.5954 10.8609L42.9795 9.3189ZM42.7271 8.50955L43.2828 9.11149C43.5897 9.44392 43.8832 9.48081 44.1634 9.22217C44.4436 8.96352 44.4302 8.66798 44.1234 8.33555L43.5677 7.73361L42.7271 8.50955Z"
        fill="white"
      />
      <path
        d="M46.9287 11.9784L46.2961 11.0603L43.961 12.6692L43.741 12.3499L46.0761 10.741L45.4436 9.82296L45.7429 9.61669L47.228 11.7721L46.9287 11.9784Z"
        fill="white"
      />
      <path
        d="M46.5774 14.634L45.465 15.2055L45.2878 14.8605L46.4002 14.2891L47.6108 12.3812L47.8212 12.7908L46.8424 14.28L48.623 14.3517L48.8334 14.7613L46.5774 14.634Z"
        fill="white"
      />
      <path
        d="M49.9914 18.0039C50.0818 18.3141 50.0693 18.5887 49.9538 18.8277C49.8414 19.0658 49.6425 19.2264 49.3571 19.3096C49.0717 19.3928 48.8161 19.3646 48.5903 19.2251C48.3676 19.0847 48.211 18.8594 48.1206 18.5491L47.9131 17.8371L46.7125 18.187L46.604 17.8147L49.6754 16.9196L49.9914 18.0039ZM48.4534 18.3915C48.523 18.6304 48.6222 18.7917 48.7509 18.8754C48.8836 18.9612 49.0477 18.9757 49.2432 18.9187C49.4386 18.8617 49.5677 18.7619 49.6304 18.619C49.6972 18.4784 49.6957 18.2887 49.6261 18.0498L49.4349 17.3936L48.2621 17.7354L48.4534 18.3915Z"
        fill="white"
      />
      <path
        d="M48.5389 22.4284L48.333 20.9937L47.4068 20.7838L47.3489 20.3808L50.6926 21.1595L50.7581 21.6153L47.7675 23.2981L47.709 22.8902L48.5389 22.4284ZM48.8788 22.2376L49.9403 21.6494L50.3603 21.4374L50.359 21.4278L49.912 21.3499L48.7128 21.0812L48.8788 22.2376Z"
        fill="white"
      />
    </svg>
  );
}

PartyCircleText.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 52 / 50,
  svg: PartyCircleText,
  title,
};
