import React from 'react';
import { InterfaceSkeleton } from '@web-stories-wp/story-editor';
import { usePageImport, useClearImportHistory } from '@hooks';
import styled from 'styled-components';
import Header from '../header';

const LoadingOverlay = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #26292A;
  opacity: 0.5;
  display: flex;
`;

function Layout() {
  const isImporting = usePageImport();
  useClearImportHistory(isImporting);

  return (
    <>
      {isImporting && (
        <LoadingOverlay>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path
                fill="#00A5F7"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </LoadingOverlay>
      )}
      <InterfaceSkeleton header={<Header />} />
    </>
  );
}

export default Layout;
