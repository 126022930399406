import { useMemo } from 'react';
import { PROVIDERS } from './providerConfiguration';
import { useConfig } from '../../config';

function useMediaProviders3pNames() {
  const { mediaProviders } = useConfig();

  return useMemo(() => {
    if (!mediaProviders) return [];

    return mediaProviders
      .filter((provider) => {
        return !!PROVIDERS[provider.code.toLowerCase()];
      })
      .map((provider) => provider.code.toLowerCase());
  }, [mediaProviders]);
}

export default useMediaProviders3pNames;
