import { useEffect, useState } from 'react';
import useCmsApi from './api/useCmsApi';

const useFeatures = () => {
  const { featureApi } = useCmsApi();
  const [features, setFeatures] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    featureApi
      .getFeatures()
      .then(({ payload: features }) => {
        setFeatures(features);
      })
      .finally(() => setIsLoading(false));
  }, [featureApi]);

  return { features, isLoading };
};

export default useFeatures;
