/**
 * External dependencies
 */
import elementIs from '@src/web-stories-wp/story-editor/src/elements/utils/elementIs';
import type { Element } from '@src/web-stories-wp/story-editor/src/elements/types';
/**
 * Internal dependencies
 */
import { AMP_DANGER_ZONE_HEIGHT } from './constants';

function getYWithoutSafeZoneOffset(yWithSafeZone: number): number {
  return yWithSafeZone + AMP_DANGER_ZONE_HEIGHT;
}

function getYWithSafeZoneOffset(yWithoutSafeZone: number): number {
  return yWithoutSafeZone - AMP_DANGER_ZONE_HEIGHT;
}

export function getElementWithoutSafeZoneOffset(element: Element): Element {
  if (elementIs.backgroundable(element) && element.isBackground) {
    return element;
  }

  const y = getYWithoutSafeZoneOffset(element.y);

  return { ...element, y };
}

export function getElementWithSafeZoneOffset(element: Element): Element {
  if (elementIs.backgroundable(element) && element.isBackground) {
    return element;
  }

  const y = getYWithSafeZoneOffset(element.y);

  return { ...element, y };
}
