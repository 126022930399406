import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { defaultEnvConfig } from '@config';
import { initAppInsights } from './initAppInsights';

import './index.css';

function loadConfig() {
  if (process.env.NODE_ENV === 'development') {
    window.config = defaultEnvConfig;
    return Promise.resolve();
  }

  if (process.env.NODE_ENV === 'production') {
    return fetch('/config.js')
      .then((response) => response.json())
      .then((responseJson) => (window.config = responseJson));
  }
}

loadConfig().then(() => {
  initAppInsights();

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
