import { AssetTypes } from '@src/hooks/studioContext/CMSParams';
import { DeferredPromise } from '@src/data-structures/deferred';
import type { StoredToken, Tenant, Token, UrlContext } from './types';
import type { AxiosInstance } from 'axios';

const authTokenKey = 'authToken';
const authContextKey = 'context';
const tenantTemplate = '{tenant}';
const tenantKey = 'tenant';

export const refreshTokenUrl = '/api/auth/refresh';

function buildHashParams() {
  var urlHash = window.location.hash;

  if (!urlHash) return new URLSearchParams();

  return new URLSearchParams(urlHash.substr(1));
}

function getContextFromUrl(
  hashParams: URLSearchParams
): UrlContext | undefined {
  const contextJson = hashParams.get(authContextKey);
  if (!contextJson) return;
  const context = contextJson && JSON.parse(contextJson);
  if (context) {
    return context;
  }
}

export function getContextFromLocalStorage() {
  const tokenJson = localStorage.getItem(authTokenKey);
  const tenantJson = localStorage.getItem(tenantKey);

  const context = {
    token: tokenJson && JSON.parse(tokenJson),
    tenant: tenantJson && JSON.parse(tenantJson),
  };

  return context;
}

export function deleteContext() {
  localStorage.removeItem(authTokenKey);
  localStorage.removeItem(tenantKey);
}

class AuthorizationContext {
  public static sharedInstance = new AuthorizationContext();

  authPromise = new DeferredPromise<void>();
  cmsUrl: string;
  isAuthenticated: boolean = false;
  isAuthenticating: boolean = false;
  token: StoredToken | null;
  tenant: Tenant | null;

  constructor() {
    this.requestToken = this.requestToken.bind(this);
  }

  public initialize(cmsUrl: string) {
    const hashParams = buildHashParams();
    const contextFromUrl = getContextFromUrl(hashParams);
    const contextFromLocalStorage = getContextFromLocalStorage();

    let token = null;
    let tenant = null;

    if (contextFromUrl?.token.authenticated) {
      token = contextFromUrl.token;
      localStorage.setItem(authTokenKey, JSON.stringify(contextFromUrl.token));
    } else if (contextFromLocalStorage.token) {
      token = contextFromLocalStorage.token;
    }

    if (contextFromUrl?.tenant) {
      tenant = contextFromUrl.tenant;
      localStorage.setItem(tenantKey, JSON.stringify(contextFromUrl.tenant));
    } else if (contextFromLocalStorage.tenant) {
      tenant = contextFromLocalStorage.tenant;
    }

    if (contextFromUrl) {
      hashParams.delete(authContextKey);
      window.location.hash = `#${hashParams.toString()}`;
    }

    this.cmsUrl = cmsUrl;
    this.tenant = tenant;

    if (token) {
      this.isAuthenticated = true;

      if (!this.hasValidToken_() && !this.isAuthenticating) {
        this.token = token;

        /* The access_token we get from the URL doesn't include an expires_in field; so we're going to replace
        it as soon as possible (with the next request). But we still need to set isAuthenticated to true, so
        the user doesn't get redirected to the login page. */
        if ('expires_in' in token) {
          this.setToken_(token);
        }
      }
    }
  }

  private hasValidToken_() {
    if (!this.token) return false;
    if (!this.token.expires_in || !this.token.timestamp) return false;

    const currentTimestamp = Date.now();
    const tokenExpiryTimestamp = this.token.timestamp + this.token.expires_in;

    return tokenExpiryTimestamp > currentTimestamp;
  }

  private getTenantName_() {
    var urlSegments = window.location.host.split('.');

    //URL with tenant name should have at least 2 url segments
    //Also if first segment is "studio" we can assume tenant name is not in URL
    if (urlSegments.length <= 1 || urlSegments[0] === 'studio') {
      //To not introducing breaking change we still can get name from context
      return this.tenant?.name;
    }

    return urlSegments[0];
  }

  private getTenantCmsUrl_() {
    const tenantName = this.getTenantName_();
    if (!tenantName) {
      return this.cmsUrl.replace(`${tenantTemplate}.`, '');
    } else {
      return this.cmsUrl.replace(`${tenantTemplate}`, tenantName);
    }
  }

  private getLoginUrl_() {
    return `${this.getTenantCmsUrl_()}/login`;
  }

  private getEditStoryUrl_(storyId, assetType) {
    const url = this.getTenantCmsUrl_();
    let storyEditUrl = `${url}/stories/${storyId}/edit`;
    if (
      assetType === AssetTypes.StoryThumbnail ||
      assetType === AssetTypes.StoryIcon
    ) {
      storyEditUrl = `${storyEditUrl}?tabIndex=1`;
    }
    return storyEditUrl;
  }

  private getContentBankAssetEditUrl_(resourceId) {
    const url = this.getTenantCmsUrl_();
    return `${url}/content-bank/${resourceId}`;
  }

  private getContentBankListUrl_() {
    const url = this.getTenantCmsUrl_();
    return `${url}/content-bank`;
  }

  private getClipEditUrl_(resourceId) {
    const url = this.getTenantCmsUrl_();
    return `${url}/clips/${resourceId}/form`;
  }

  private getAdEditUrl(resourceId) {
    const url = this.getTenantCmsUrl_();
    return `${url}/interstitialAd/${resourceId}/edit`;
  }

  public redirectToLogin() {
    window.location.replace(this.getLoginUrl_());
  }

  public redirectToCms() {
    window.location.replace(this.getTenantCmsUrl_());
  }

  public redirectToCmsEditForm({ storyId, assetType, resourceId }) {
    let redirectUrl = this.getTenantCmsUrl_();
    const isContentBankAsset = assetType === AssetTypes.ContentBankAsset;
    const isClip =
      assetType === AssetTypes.Clip || assetType === AssetTypes.ClipThumbnail;
    const isAd = assetType === AssetTypes.Ad;

    if (isContentBankAsset) {
      redirectUrl = this.getContentBankAssetEditUrl_(resourceId);
    } else if (isClip) {
      redirectUrl = this.getClipEditUrl_(resourceId);
    } else if (isAd) {
      redirectUrl = this.getAdEditUrl(resourceId);
    } else {
      redirectUrl = this.getEditStoryUrl_(storyId || resourceId, assetType);
    }

    window.location.replace(redirectUrl);
  }

  public redirectToCmsAfterDiscardingChanges({
    storyId,
    assetType,
    resourceId,
    isNewAsset,
  }) {
    let redirectUrl = this.getTenantCmsUrl_();
    const isContentBankAsset = assetType === AssetTypes.ContentBankAsset;
    const isClip =
      assetType === AssetTypes.Clip || assetType === AssetTypes.ClipThumbnail;
    const isAd = assetType === AssetTypes.Ad;

    if (isContentBankAsset) {
      if (isNewAsset) {
        redirectUrl = this.getContentBankListUrl_();
      } else {
        redirectUrl = this.getContentBankAssetEditUrl_(resourceId);
      }
    } else if (isClip) {
      redirectUrl = this.getClipEditUrl_(resourceId);
    } else if (isAd) {
      redirectUrl = this.getAdEditUrl(resourceId);
    } else {
      redirectUrl = this.getEditStoryUrl_(storyId || resourceId, assetType);
    }

    window.location.replace(redirectUrl);
  }

  public async requestToken(axiosInstance: AxiosInstance): Promise<void> {
    // If the token doesn't have an expires_in field, we need to refresh it, as we probably got it
    // from the URL param and we have no way of knowing if it's still valid.
    if (this.hasValidToken_()) {
      return Promise.resolve();
    }

    if (this.isAuthenticating) {
      return this.authPromise.promise;
    }

    this.isAuthenticating = true;

    this.authPromise.promise = new Promise((resolve, reject) => {
      return axiosInstance
        .post(refreshTokenUrl)
        .then((response) => {
          this.isAuthenticating = false;
          this.setToken_(response.data);
          return resolve();
        })
        .catch((error) => reject(error));
    });

    return this.authPromise.promise;
  }

  private setToken_(token: Token | StoredToken) {
    const timestamp = isStoredToken(token) ? token.timestamp : Date.now();
    const storedToken: StoredToken = { ...token, timestamp };
    localStorage.setItem(authTokenKey, JSON.stringify(storedToken));

    const tokenExpiryTimestamp = timestamp + token.expires_in;
    const currentTimestamp = Date.now();
    const isTokenStillValid = tokenExpiryTimestamp > currentTimestamp;

    if (isTokenStillValid) {
      this.token = storedToken;

      const tokenExpiryTimeout = tokenExpiryTimestamp - currentTimestamp;

      setTimeout(() => {
        // We don't want to reset the access_token, as we can use it to request a new one
        // But we need to reset expires_in and timestamp so we know to request a new one
        this.token.expires_in = null;
        this.token.timestamp = null;
      }, tokenExpiryTimeout);
    } else {
      this.token.expires_in = null;
      this.token.timestamp = null;
    }
  }
}

function isStoredToken(token: Token | StoredToken): token is StoredToken {
  return 'timestamp' in token;
}

export const authContext = AuthorizationContext.sharedInstance;
