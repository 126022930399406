import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ASSET_TYPE,
  RESOURCE_ID,
  STORY_ID,
  AssetTypes,
  STUDIO_STORY_ID,
  STUDIO_DEFINITION_ID,
  CMS_PAGE_ID,
  ADD_NEW_PAGE,
  CONTENT_BANK_ASSET_TYPE,
  IS_NEW,
} from './CMSParams';

function updateUrlAfterFirstUpdate(queryStringParams) {
  let newUrl = '/';

  const query = queryStringParams.toString();

  if (query) {
    newUrl += `?${query}`;
  }

  const hash = window.location.hash;

  if (hash) {
    newUrl += `${hash}`;
  }

  window.history.replaceState(null, null, newUrl);
}

export const useStudioStoryContextLoader = () => {
  const context = useMemo(() => {
    var params = new URLSearchParams(window.location.search);
    var assetType = params.get(ASSET_TYPE);
    var contentBankAssetType = params.get(CONTENT_BANK_ASSET_TYPE);

    const storyId = params.get(STORY_ID);
    const resourceId = params.get(RESOURCE_ID);

    if (assetType === AssetTypes.Story && !storyId) {
      throw new Error('StoryId is required');
    } else if (
      (assetType === AssetTypes.StoryThumbnail ||
        assetType === AssetTypes.StoryIcon ||
        assetType === AssetTypes.Clip ||
        assetType === AssetTypes.ClipThumbnail ||
        assetType === AssetTypes.Ad) &&
      !resourceId
    ) {
      throw new Error('ResourceId is required');
    }

    let studioStoryId = params.get(STUDIO_STORY_ID);
    let studioDefinitionId = params.get(STUDIO_DEFINITION_ID);
    let isNewAsset = params.get(IS_NEW);

    if (assetType === AssetTypes.Story && !studioStoryId) {
      studioStoryId = uuidv4();
      params.append(STUDIO_STORY_ID, studioStoryId);
      updateUrlAfterFirstUpdate(params);
    } else if (
      (assetType === AssetTypes.StoryThumbnail ||
        assetType === AssetTypes.StoryIcon ||
        assetType === AssetTypes.ContentBankAsset ||
        assetType === AssetTypes.Clip ||
        assetType === AssetTypes.ClipThumbnail ||
        assetType === AssetTypes.Ad) &&
      !studioDefinitionId
    ) {
      studioDefinitionId = uuidv4();
      params.append(STUDIO_DEFINITION_ID, studioDefinitionId);
      updateUrlAfterFirstUpdate(params);
    }

    return {
      storyId,
      studioStoryId,
      isEdit: !!studioStoryId,
      cmsPageId: params.get(CMS_PAGE_ID),
      addNewPage: params.get(ADD_NEW_PAGE),
      assetType,
      contentBankAssetType,
      resourceId,
      studioDefinitionId,
      isNewAsset,
    };
  }, []);

  return context;
};
