import styled from 'styled-components';
import ChevronIcon from '../chevron.svg';

const OptionsListBackButton = styled.div`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  color: white;
  gap: 8px;

  &:before {
    content: '';
    display: block;
    background: url(${ChevronIcon});
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: center;
    height: var(--icon-button-size);
    width: var(--icon-button-size);
    transform: rotate(180deg);
  }
`;

export default OptionsListBackButton;
