/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Plant', 'sticker name', 'web-stories');

function MakerPlant({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 32 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g clipPath="url(#clip0)">
        <path
          d="M17.3777 49.817C17.9123 48.4785 18.6975 47.262 19.3665 45.9748C20.0718 44.6485 20.546 43.2182 21.0973 41.8226C22.1816 39.0508 23.3082 36.3904 23.8171 33.4151C24.0799 31.8989 24.2097 30.369 24.4151 28.8362C24.6373 27.2463 24.9734 25.6707 25.2711 24.0779C25.8861 20.9102 26.1596 17.7181 26.5849 14.539C26.8201 12.7979 27.1693 11.0713 27.4234 9.32952C27.6942 7.53062 27.6997 5.72317 27.9327 3.92574C28.0046 3.33937 27.0877 3.11137 27.0165 3.71654C26.8293 5.22982 26.812 6.73651 26.6248 8.2498C26.4397 9.81947 26.1217 11.3755 25.88 12.9474C25.3762 16.0542 25.1768 19.2058 24.6533 22.2946C24.1117 25.403 23.5316 28.494 23.123 31.616C22.7311 34.6809 21.8414 37.5957 20.627 40.4289C19.985 41.9222 19.5158 43.484 18.8163 44.9607C18.0987 46.4569 17.1493 47.8303 16.5472 49.3785C16.3228 49.9332 17.1533 50.3716 17.3777 49.817Z"
          fill="#588054"
        />
        <path
          d="M17.4395 49.9464C17.5402 48.1541 17.8887 46.4086 17.9706 44.617C18.0706 42.8059 18.1895 40.9941 18.1566 39.1693C18.1259 37.4009 18.0196 35.6355 18.1022 33.8627C18.184 32.0711 18.2681 30.3359 18.1611 28.5517C17.9448 24.9268 17.9175 21.2946 17.7579 17.6675C17.6908 15.9382 17.4167 14.2357 17.2929 12.5086C17.2238 11.7017 17.3051 10.8703 17.1982 10.0649C17.0884 9.18441 16.7722 8.34954 16.7379 7.46608C16.6957 6.86531 15.7512 6.90193 15.7934 7.50271C15.8473 8.40423 16.1439 9.22104 16.2537 10.1016C16.3606 10.9069 16.2982 11.7376 16.3484 12.5452C16.4722 14.2723 16.7274 15.9755 16.8134 17.7041C16.973 21.3312 17.0003 24.9634 17.2166 28.5883C17.3236 30.3726 17.2395 32.1077 17.1577 33.8993C17.0751 35.6721 17.1814 37.4376 17.2121 39.2059C17.2451 41.0307 17.1261 42.8426 17.0261 44.6537C16.9442 46.4452 16.5965 48.2095 16.495 49.983C16.4616 50.5867 17.4061 50.5501 17.4395 49.9464Z"
          fill="#588054"
        />
        <path
          d="M16.8874 50.3442C16.7962 47.5052 16.5372 44.7291 15.5436 42.0379C14.5719 39.4212 14.2359 36.6105 13.3383 33.9533C12.4196 31.2404 11.9542 28.51 11.4489 25.7246C11.1843 24.2665 10.9945 22.7867 10.7676 21.3271C10.5378 19.7923 10.1765 18.2815 10.0979 16.7409C10.0745 16.1394 9.13003 16.176 9.15336 16.7775C9.23203 18.3181 9.59404 19.8477 9.8231 21.3637C10.0064 22.6744 10.1903 24.0038 10.4114 25.313C10.8985 28.1179 11.3275 30.8874 12.2273 33.601C12.6554 34.8834 13.1024 36.165 13.4186 37.4893C13.7385 38.9076 13.9087 40.3694 14.416 41.7428C14.9015 43.0417 15.1988 44.3668 15.5347 45.7092C15.9359 47.275 15.9005 48.8012 15.9625 50.3989C15.9662 50.9824 16.9107 50.9457 16.8874 50.3442Z"
          fill="#588054"
        />
        <path
          d="M16.7057 50.5396C16.6432 49.9019 16.4113 49.2897 16.2362 48.6752C16.0617 48.0796 15.71 47.792 15.2958 47.3563C14.2306 46.2304 13.4552 44.7733 12.4815 43.5686C11.5078 42.3639 10.9837 41.0476 10.3246 39.6613C9.62402 38.1824 9.07602 36.7353 8.65515 35.1515C8.21393 33.5308 7.586 31.9738 7.33223 30.3082C7.07845 28.6426 6.82613 27.0146 6.55346 25.3497C6.00958 22.0576 5.36834 18.6939 5.14413 15.3517C4.88865 11.6907 4.6891 8.00863 4.54622 4.32442C4.52289 3.72292 3.5784 3.75954 3.60172 4.36105C3.66587 6.01519 3.73002 7.66932 3.79417 9.32346C3.85686 10.94 4.14551 12.529 4.17042 14.147C4.2217 17.4206 4.89781 20.7076 5.40172 23.9448C5.65549 25.6104 6.06039 27.2702 6.2575 28.938C6.43207 30.5125 6.71561 31.9699 7.20912 33.4756C7.70264 34.9813 7.99055 36.5515 8.59595 38.0152C9.15992 39.3864 9.80018 40.7735 10.4383 42.1042C11.1584 43.6011 12.3974 44.8143 13.321 46.1904C13.7221 46.7773 14.1247 47.4017 14.5992 47.9292C15.251 48.6381 15.7037 49.5807 15.7983 50.5559C15.8224 51.1762 16.7668 51.1396 16.7057 50.5396Z"
          fill="#588054"
        />
        <path
          d="M25.9567 16.3894C26.7682 16.3391 27.5209 16.2346 28.2634 15.867C28.8017 15.6014 28.2974 14.7926 27.7598 15.077C27.1844 15.3629 26.5625 15.4247 25.9399 15.4676C25.3158 15.473 25.3522 16.4129 25.9567 16.3894Z"
          fill="#588054"
        />
        <path
          d="M25.5431 20.3587C24.5041 20.399 23.5298 20.1544 22.5061 20.1C21.8995 20.067 21.9359 21.0069 22.5426 21.0398C23.5852 21.0935 24.5406 21.3389 25.5795 21.2986C26.184 21.2751 26.1476 20.3353 25.5431 20.3587Z"
          fill="#588054"
        />
        <path
          d="M24.0466 27.1374C24.8567 27.539 25.7002 27.3368 26.5684 27.2843C27.1729 27.2609 27.1364 26.321 26.532 26.3445C25.8897 26.3694 25.1043 26.6069 24.5056 26.2913C23.9469 26.0306 23.506 26.8572 24.0466 27.1374Z"
          fill="#588054"
        />
        <path
          d="M23.2526 33.4935C23.7467 34.0391 24.6635 33.7776 25.3051 33.7339C25.9088 33.6917 25.8724 32.7518 25.2686 32.794C25.0042 32.8043 24.76 32.8514 24.4956 32.8617C24.3256 32.8683 24.0248 32.9176 23.9063 32.7904C23.4922 32.3547 22.8565 33.0382 23.2526 33.4935Z"
          fill="#588054"
        />
        <path
          d="M16.3246 13.3932C15.8305 13.3371 15.3706 13.1855 14.8621 13.2429C14.259 13.3039 14.2955 14.2438 14.8985 14.1827C15.3126 14.129 15.6962 14.2647 16.0951 14.3057C16.3421 14.3338 16.603 14.2296 16.6683 13.9635C16.7162 13.7357 16.5716 13.4213 16.3246 13.3932Z"
          fill="#588054"
        />
        <path
          d="M17.551 19.1628C18.061 19.143 18.5522 19.1239 19.0622 19.1041C19.6667 19.0807 19.6302 18.1409 19.0258 18.1643C18.5157 18.1841 18.0246 18.2031 17.5146 18.2229C16.9101 18.2463 16.9465 19.1862 17.551 19.1628Z"
          fill="#588054"
        />
        <path
          d="M17.1301 22.9441C16.3745 22.9734 15.6189 23.0027 14.8633 23.032C14.2588 23.0554 14.2953 23.9953 14.8998 23.9718C15.6554 23.9425 16.411 23.9132 17.1666 23.8839C17.771 23.8605 17.7346 22.9206 17.1301 22.9441Z"
          fill="#588054"
        />
        <path
          d="M18.0248 31.3808C18.346 31.3683 18.6482 31.3566 18.9693 31.3442C19.5738 31.3207 19.5374 30.3809 18.9329 30.4043C18.6118 30.4168 18.3095 30.4285 17.9884 30.4409C17.3839 30.4644 17.4204 31.4042 18.0248 31.3808Z"
          fill="#588054"
        />
        <path
          d="M9.64295 21.5965C9.27244 21.7991 8.90193 22.0018 8.53142 22.2044C8.30912 22.326 8.26484 22.6477 8.3862 22.8501C8.52717 23.0705 8.81271 23.1159 9.03502 22.9943C9.40552 22.7917 9.77603 22.5891 10.1465 22.3865C10.3688 22.2649 10.4131 21.9432 10.2918 21.7408C10.1508 21.5204 9.88414 21.4742 9.64295 21.5965Z"
          fill="#588054"
        />
        <path
          d="M11.1055 27.1122C11.5995 27.1683 12.0594 27.3199 12.568 27.2625C12.8128 27.2342 13.0322 27.0375 13.022 26.7743C13.0125 26.5299 12.7953 26.2936 12.5315 26.3227C12.1174 26.3764 11.7338 26.2407 11.3349 26.1997C11.0879 26.1716 10.8271 26.2758 10.7617 26.5419C10.7138 26.7697 10.8584 27.0841 11.1055 27.1122Z"
          fill="#588054"
        />
        <path
          d="M12.0448 32.3091C11.6097 32.7966 10.6942 32.6062 10.1275 32.6282C9.52304 32.6516 9.55949 33.5915 10.164 33.5681C11.0525 33.5524 12.0988 33.7001 12.7511 32.9594C13.1492 32.4922 12.4436 31.8607 12.0448 32.3091Z"
          fill="#588054"
        />
        <path
          d="M3.43508 7.86905C2.82186 7.66693 2.24277 7.36936 1.58016 7.35741C0.974227 7.34326 1.01068 8.28311 1.61661 8.29726C2.16588 8.31361 2.70719 8.61264 3.20561 8.78157C3.78106 8.98515 3.99091 8.05458 3.43508 7.86905Z"
          fill="#588054"
        />
        <path
          d="M4.06916 12.0236C4.4797 12.3654 5.07185 12.5119 5.4279 12.9122C5.6052 13.0936 5.9256 13.0623 6.08905 12.8866C6.27066 12.6913 6.24083 12.41 6.06354 12.2287C5.68786 11.8103 5.13495 11.6999 4.70479 11.3401C4.25429 10.9434 3.59977 11.6277 4.06916 12.0236Z"
          fill="#588054"
        />
        <path
          d="M4.93759 19.2941C4.19293 19.6054 3.39517 19.5233 2.62069 19.5534C2.01621 19.5768 2.05266 20.5167 2.65714 20.4932C3.52607 20.4595 4.41827 20.5379 5.23704 20.1861C5.79571 19.9574 5.51516 19.0646 4.93759 19.2941Z"
          fill="#588054"
        />
        <path
          d="M6.0358 28.0995C5.69797 28.1691 5.38268 28.3319 5.0412 28.3075C4.7949 28.2982 4.5777 28.5514 4.58718 28.7957C4.59738 29.0589 4.81246 29.2388 5.07765 29.2473C5.51358 29.2681 5.9037 29.0835 6.33525 28.9915C6.57937 28.9444 6.71809 28.619 6.63378 28.3963C6.54802 28.1361 6.29881 28.0516 6.0358 28.0995Z"
          fill="#588054"
        />
        <path
          d="M9.66352 40.1765C10.2273 40.0793 10.7896 39.9445 11.3563 39.9226C11.9608 39.8991 11.9244 38.9593 11.3199 38.9827C10.7532 39.0047 10.1909 39.1394 9.62707 39.2366C9.38296 39.2837 9.16212 39.4429 9.17305 39.7248C9.1818 39.9504 9.41941 40.2236 9.66352 40.1765Z"
          fill="#588054"
        />
        <path
          d="M26.0196 2.88917C26.0459 3.56587 25.9065 4.36193 26.4006 4.90753C26.7378 5.30861 27.3931 5.62205 27.9068 5.69626C29.0482 5.85907 29.2834 4.60748 29.2877 3.74135C29.2847 3.17671 29.2069 2.63379 28.7753 2.23637C28.3829 1.87508 27.8118 1.78428 27.3069 1.93564C26.4097 2.21516 25.7174 3.39035 25.6958 4.2948C25.6632 5.40676 27.0081 5.93819 27.9366 5.48802C28.8091 5.05885 29.0472 3.393 28.3046 2.78174C27.522 2.11555 26.4345 2.85426 26.2413 3.72771C26.1288 4.24036 26.3381 4.75935 26.7842 5.04325C27.367 5.43481 27.9583 5.07302 28.2198 4.49812C28.4123 4.09533 28.6149 3.46624 28.0939 3.20406C27.6115 2.95922 27.1342 3.33541 26.8843 3.7216C26.4565 4.39708 26.1813 6.0832 27.4927 5.74997C28.0928 5.61374 27.7926 4.70294 27.2121 4.85723C27.5681 4.76813 27.3582 5.20925 27.4403 4.88604C27.4548 4.77252 27.4889 4.67707 27.5223 4.56282C27.5564 4.46737 27.8267 4.11804 27.8245 4.06165C27.7075 3.97206 27.6087 3.86294 27.4917 3.77335C27.475 3.83047 27.4576 3.8688 27.4409 3.92592C27.3501 4.02357 27.3988 4.30406 27.2048 4.17981C27.0304 4.07362 27.2439 3.72648 27.3347 3.62883C27.4256 3.53119 27.573 3.43134 27.69 3.52093C27.9225 3.66252 27.7526 4.15856 27.6843 4.34946C27.5834 4.67341 27.4155 4.73639 27.0733 4.69319C26.8074 4.66585 26.6352 4.61605 26.6243 4.3341C26.6075 3.90177 26.9816 3.30367 27.2925 3.02806C27.5308 2.83057 27.849 2.74293 28.1033 2.95897C28.3184 3.13888 28.3112 3.44036 28.3214 3.70352C28.3331 4.00427 28.4007 4.77349 28.004 4.78887C27.7585 4.79839 27.2164 4.48056 27.0762 4.27892C26.8364 3.94937 26.9802 3.26608 26.9648 2.87134C26.9401 2.23224 25.9956 2.26887 26.0196 2.88917Z"
          fill="white"
        />
        <path
          d="M2.5787 3.83602C2.52793 4.47804 3.01913 4.94845 3.64687 5.03706C4.33128 5.12347 5.00038 4.81514 5.25894 4.16505C5.50226 3.60969 5.54283 2.7045 5.31317 2.14865C5.04063 1.46268 4.21962 1.26862 3.60864 1.61234C2.47969 2.25853 1.9554 4.34965 3.50662 4.83542C4.75123 5.22013 6.14956 3.2269 5.12938 2.28755C4.65852 1.854 3.92546 1.97656 3.64436 2.53339C3.39813 3.01358 3.44253 3.67074 3.68817 4.15067C3.78991 4.33498 4.03839 4.40064 4.2251 4.33693C4.73003 4.18557 5.16224 3.62287 4.93332 3.08582C4.84974 2.88198 4.69424 2.77506 4.46684 2.76505C4.37166 2.74992 4.25832 2.75431 4.14425 2.73991C3.53759 2.70696 3.57403 3.64681 4.1807 3.67976C4.29477 3.69416 4.38922 3.6905 4.50328 3.7049C4.34779 3.59798 4.1923 3.49106 4.03681 3.38414C4.01937 3.42246 3.98232 3.44273 3.94454 3.44419C4.11747 3.51279 4.30928 3.58065 4.48148 3.63045C4.41971 3.50107 4.41096 3.2755 4.44291 3.12366C4.45742 3.01015 4.51118 2.93276 4.4734 2.93423C4.45451 2.93496 4.49593 3.02748 4.49812 3.08387C4.49089 3.38535 4.334 3.73029 4.05649 3.89166C3.61188 4.1348 3.41927 3.55869 3.5006 3.21668C3.56449 2.913 3.81364 2.508 4.11224 2.4023C4.55976 2.23434 4.4901 2.8771 4.48142 3.14099C4.47565 3.48007 4.49314 3.9312 4.12045 4.07743C3.85963 4.18167 3.51743 4.13847 3.54209 3.79866C3.57543 3.19495 2.63166 3.25038 2.5787 3.83602Z"
          fill="white"
        />
        <path
          d="M8.85956 14.5675C8.20644 14.7999 8.02854 15.5787 8.16593 16.1946C8.30551 16.8669 8.96381 17.2555 9.60971 17.3246C11.1297 17.4915 11.6438 15.6267 10.8648 14.5651C10.1054 13.5215 8.52452 13.7334 8.19629 15.0262C8.06486 15.5396 8.16374 16.1382 8.5198 16.5385C8.93471 16.9931 9.65909 17.1344 10.1742 16.7568C10.7996 16.2995 10.8918 15.2606 10.341 14.7172C9.5744 13.9751 8.85817 15.0194 8.92511 15.7698C8.98622 16.3698 9.93071 16.3332 9.8696 15.7332C9.8696 15.7332 9.87756 15.4505 9.89645 15.4498C9.94948 15.3536 9.89208 15.337 9.72571 15.4376C9.74606 15.4744 9.74679 15.4932 9.76714 15.5301C9.7163 15.6826 9.81731 15.8482 9.67056 15.9668C9.4875 16.1245 9.25426 15.9641 9.17141 15.7791C9.00644 15.4278 9.18944 14.7806 9.64499 14.8195C10.2713 14.8705 10.5155 15.8023 10.1537 16.2304C9.93727 16.5024 9.49843 16.4065 9.24557 16.228C9.08935 16.1023 8.95414 15.5428 9.14012 15.4603C9.70026 15.2691 9.41897 14.3576 8.85956 14.5675Z"
          fill="white"
        />
        <path
          d="M14.6913 7.86539C14.6406 8.50742 15.1318 8.97783 15.7595 9.06643C16.4439 9.15284 17.113 8.84451 17.3716 8.19443C17.6149 7.63906 17.6366 6.7346 17.4069 6.17875C17.1344 5.49279 16.3134 5.29872 15.7024 5.64245C14.5923 6.28791 14.068 8.37902 15.6004 8.86553C16.845 9.25024 18.2433 7.25701 17.2231 6.31766C16.7523 5.88411 16.0192 6.00667 15.7381 6.5635C15.4919 7.04368 15.5363 7.70085 15.7819 8.18078C15.8837 8.36508 16.1321 8.43075 16.3189 8.36703C16.8238 8.21567 17.256 7.65298 17.0271 7.11592C16.9435 6.91209 16.788 6.80516 16.5606 6.79516C16.4465 6.78076 16.3521 6.78442 16.238 6.77002C15.6313 6.73707 15.6678 7.67692 16.2745 7.70987C16.3885 7.72427 16.483 7.72061 16.597 7.73501C16.4415 7.62809 16.2861 7.52117 16.1306 7.41424C16.1131 7.45257 16.0761 7.47283 16.0383 7.4743C16.2112 7.54289 16.403 7.61076 16.5752 7.66055C16.4946 7.5319 16.5047 7.30561 16.5178 7.1545C16.5323 7.04098 16.586 6.9636 16.5483 6.96506C16.5294 6.9658 16.5708 7.05832 16.573 7.11471C16.5658 7.41619 16.4089 7.76113 16.1314 7.9225C15.6867 8.16564 15.4941 7.58953 15.5755 7.24752C15.6394 6.94384 15.8885 6.53884 16.1871 6.43314C16.6346 6.26518 16.565 6.90794 16.5563 7.17183C16.5505 7.51091 16.568 7.96204 16.1953 8.10827C15.9345 8.21251 15.5923 8.1693 15.617 7.82949C15.6881 7.22433 14.7436 7.26096 14.6913 7.86539Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="28.2805"
            height="50"
            fill="white"
            transform="translate(0.90155 1.56665) rotate(-2.22089)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

MakerPlant.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 32 / 52,
  svg: MakerPlant,
  title,
};
