/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useEffect } from '@web-stories-wp/react';

function useUpdateSelectionRectangle(moveableRef, dependencies = []) {
  // If deps ever update, update rect now AND in a frame's time
  useEffect(() => {
    const update = () => moveableRef.current?.updateRect?.();
    update();
    const timeout = setTimeout(update);
    return () => clearTimeout(timeout);
    // Disable reason: ref is stable, deps are the only thing that matters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export default useUpdateSelectionRectangle;
