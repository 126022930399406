/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { __, _x } from '@web-stories-wp/i18n';

export const OverlayType = {
  NONE: 'none',
  SOLID: 'solid',
  LINEAR: 'linear',
  RADIAL: 'radial',
};

export const OverlayPreset = {
  [OverlayType.NONE]: {
    label: _x('None', 'overlay', 'web-stories'),
  },
  [OverlayType.SOLID]: {
    label: __('Tint', 'web-stories'),
  },
  [OverlayType.LINEAR]: {
    label: __('Linear', 'web-stories'),
  },
  [OverlayType.RADIAL]: {
    label: __('Vignette', 'web-stories'),
  },
};
