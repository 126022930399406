import PropTypes from 'prop-types';
import Context from './context';
import { useAPI } from '../api';
import externalMediaReducer, {
  INITIAL_STATE,
  MAX_ITEMS_TO_FETCH_COUNT,
} from './externalMediaReducer';
import {
  useReducer,
  useCallback,
  useMemo,
  useRef,
} from '@web-stories-wp/react';
import * as types from './types';
import { extractFiltersSimpleValues } from './utils';
import { useSnackbar } from '@web-stories-wp/design-system';

const SKIP_NONE = '0';

function ExternalMediaProvider({ children }) {
  const { showSnackbar } = useSnackbar();
  const {
    actions: { deleteExternalAsset, getExternalAssets },
  } = useAPI();

  const [state, dispatch] = useReducer(externalMediaReducer, INITIAL_STATE);
  const controller = useRef(null);

  const { mediaType, isInitDataLoaded, skipCount, filters } = state;
  const currentMediaType = useMemo(() => mediaType.value, [mediaType]);

  const dispatchFetchStart = useCallback(
    () => dispatch({ type: types.MEDIA_TYPE_ASSETS_FETCH_START }),
    [dispatch]
  );

  const dispatchFetchEnd = useCallback(
    () => dispatch({ type: types.MEDIA_TYPE_ASSETS_FETCH_END }),
    [dispatch]
  );

  const cancelFetch = useCallback(() => {
    if (controller && controller.current) {
      controller.current.abort();
    }
  }, []);

  const dispatchFetchSuccess = useCallback(
    (assets) =>
      dispatch({
        type: types.MEDIA_TYPE_ASSETS_FETCH_SUCCESS,
        payload: {
          assets: assets,
          maxResultsCount: MAX_ITEMS_TO_FETCH_COUNT,
          skipCount: skipCount,
        },
      }),
    [dispatch, skipCount, currentMediaType]
  );

  const dispatchFetchFailed = useCallback(
    () =>
      dispatch({
        type: types.MEDIA_TYPE_ASSETS_FETCH_FAILED,
      }),
    [dispatch]
  );

  const updateMediaType = useCallback(
    (mediaType) => {
      dispatch({
        type: types.MEDIA_TYPE_CHANGED,
        payload: mediaType,
      });
    },
    [dispatch]
  );

  const updateSearchTerm = useCallback((searchTerm) => {
    dispatch({
      type: types.MEDIA_TYPE_SEARCH_TERM_CHANGED,
      payload: searchTerm,
    });
  }, []);

  const getParams = useCallback((filters, manualSkipCount) => {
    const { folderName, provider, searchText } = filters;
    const maxResultCount = MAX_ITEMS_TO_FETCH_COUNT;

    return {
      ...(searchText && {
        searchText,
      }),
      ...(!!folderName && {
        folderName,
      }),
      mediaProviderTenantConfigurationId: provider.toLowerCase(),
      //TODO: Just for backward compatibility, will be removed
      provider: 'NONE',
      maxResultCount,
      skipCount: manualSkipCount,
    };
  }, []);

  const fetchAssetsForMediaType = useCallback(
    (filters, signal, manualSkipCount) => {
      return getExternalAssets(
        getParams(extractFiltersSimpleValues(filters), manualSkipCount),
        signal
      );
    },
    [filters]
  );

  /** Used to fetch data according to selected MediaType */
  const fetchAssets = useCallback(
    (filters, manualSkipCount) => {
      cancelFetch();
      const newController = new AbortController();
      controller.current = newController;
      dispatchFetchStart();
      return fetchAssetsForMediaType(
        filters,
        newController.signal,
        manualSkipCount
      )
        .then((response) => {
          dispatchFetchSuccess(response);
        })
        .catch((error) => {
          if (error.message === 'canceled') return;
          console.error(error);
          showSnackbar({
            message: `Error loading ${mediaType.label} Assets`,
            dismissable: true,
          });
          dispatchFetchFailed();
        });
    },
    [
      dispatch,
      dispatchFetchStart,
      fetchAssetsForMediaType,
      dispatchFetchSuccess,
      dispatchFetchEnd,
      cancelFetch,
      showSnackbar,
      mediaType,
    ]
  );

  const loadAssets = useCallback(
    ({ isInitLoad }) => {
      if (isInitLoad && isInitDataLoaded) {
        return Promise.resolve();
      }
      return fetchAssets(filters, skipCount);
    },
    [fetchAssets, isInitDataLoaded, filters, skipCount]
  );

  const updateFilters = useCallback(
    (filters) => {
      dispatch({ type: types.MEDIA_TYPE_FILTERS_CHANGED, payload: filters });
      return fetchAssets(filters, SKIP_NONE);
    },
    [dispatch, fetchAssets]
  );

  const refreshMedia = useCallback(() => {
    dispatch({
      type: types.MEDIA_TYPE_ASSETS_REFRESHED,
      payload: {},
    });
    fetchAssets(filters, skipCount);
  }, [fetchAssets, filters, mediaType, skipCount]);

  const deleteMedia = useCallback(
    (assetId) => {
      // Sets `isLoading` to true
      dispatch({
        type: types.MEDIA_TYPE_ASSETS_FETCH_START,
      });

      deleteExternalAsset(assetId)
        .then(() => {
          dispatch({
            type: types.MEDIA_TYPE_ASSET_DELETED,
            payload: assetId,
          });
        })
        .catch(() => {
          showSnackbar({
            message: __('Failed to delete media item.', 'web-stories'),
            dismissable: true,
          });
        });
    },
    [
      deleteExternalAsset,
      dispatch,
      fetchAssets,
      filters,
      showSnackbar,
      skipCount,
    ]
  );

  /** Used to select video to preview */
  const setVideoAsInPreview = useCallback(
    (video) => {
      dispatch({
        type: types.MEDIA_TYPE_SET_VIDEO_AS_IN_PREVIEW,
        payload: { videoId: video?.id },
      });
    },
    [dispatch]
  );

  const setPreviewMuted = useCallback(
    (value) => {
      dispatch({
        type: types.MEDIA_TYPE_SET_PREVIEW_MUTE_STATE,
        payload: value,
      });
    },
    [dispatch]
  );

  const setPreviewPaused = useCallback(
    (value) => {
      dispatch({
        type: types.MEDIA_TYPE_SET_PREVIEW_PAUSE_STATE,
        payload: value,
      });
    },
    [dispatch]
  );

  const navigatePreviews = useCallback(
    (count) => {
      const previewIndex = state.assets.findIndex(
        (video) => video.id === state.preview.videoInPreviewId
      );
      const newIndex = previewIndex + count;

      if (newIndex < 0 || newIndex >= state.assets.length) {
        return;
      }

      setVideoAsInPreview(state.assets[newIndex]);
    },
    [dispatch, state.assets, state.preview]
  );

  const context = {
    state,
    actions: {
      updateMediaType,
      updateSearchTerm,
      setVideoAsInPreview,
      loadAssets,
      updateFilters,
      setPreviewMuted,
      setPreviewPaused,
      navigatePreviews,
      deleteMedia,
      refreshMedia,
    },
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

ExternalMediaProvider.propTypes = {
  children: PropTypes.node,
};

export default ExternalMediaProvider;
