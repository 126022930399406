/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Woman Reading 02', 'sticker name', 'web-stories');

function Woman02({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 28 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M21.2541 9.47732C21.2541 10.9739 20.0698 12.1871 18.6088 12.1871C17.1479 12.1871 15.9636 10.9739 15.9636 9.47732C15.9636 7.98077 17.1479 6.76758 18.6088 6.76758C20.0698 6.76758 21.2541 7.98077 21.2541 9.47732Z"
        fill="#573C5E"
      />
      <path
        d="M24.9914 9.67946C24.9914 11.176 23.8071 12.3892 22.3461 12.3892C20.8852 12.3892 19.7009 11.176 19.7009 9.67946C19.7009 8.18292 20.8852 6.96973 22.3461 6.96973C23.8071 6.96973 24.9914 8.18292 24.9914 9.67946Z"
        fill="#573C5E"
      />
      <path
        d="M23.5773 6.64919C23.5773 8.14574 22.393 9.35893 20.9321 9.35893C19.4712 9.35893 18.2869 8.14574 18.2869 6.64919C18.2869 5.15264 19.4712 3.93945 20.9321 3.93945C22.393 3.93945 23.5773 5.15264 23.5773 6.64919Z"
        fill="#573C5E"
      />
      <path
        d="M19.5368 5.3362C19.5368 6.83275 18.3525 8.04594 16.8916 8.04594C15.4306 8.04594 14.2463 6.83275 14.2463 5.3362C14.2463 3.83966 15.4306 2.62646 16.8916 2.62646C18.3525 2.62646 19.5368 3.83966 19.5368 5.3362Z"
        fill="#573C5E"
      />
      <path
        d="M13.7792 4.32595C13.7792 5.8225 12.5949 7.03569 11.134 7.03569C9.67308 7.03569 8.48877 5.8225 8.48877 4.32595C8.48877 2.8294 9.67308 1.61621 11.134 1.61621C12.5949 1.61621 13.7792 2.8294 13.7792 4.32595Z"
        fill="#573C5E"
      />
      <path
        d="M17.7187 2.70974C17.7187 4.20628 16.5344 5.41948 15.0734 5.41948C13.6125 5.41948 12.4282 4.20628 12.4282 2.70974C12.4282 1.21319 13.6125 0 15.0734 0C16.5344 0 17.7187 1.21319 17.7187 2.70974Z"
        fill="#573C5E"
      />
      <path
        d="M21.557 3.3157C21.557 4.81224 20.3727 6.02543 18.9118 6.02543C17.4509 6.02543 16.2666 4.81224 16.2666 3.3157C16.2666 1.81915 17.4509 0.605957 18.9118 0.605957C20.3727 0.605957 21.557 1.81915 21.557 3.3157Z"
        fill="#573C5E"
      />
      <path
        d="M24.4862 4.72976C24.4862 6.2263 23.3019 7.4395 21.841 7.4395C20.3801 7.4395 19.1958 6.2263 19.1958 4.72976C19.1958 3.23321 20.3801 2.02002 21.841 2.02002C23.3019 2.02002 24.4862 3.23321 24.4862 4.72976Z"
        fill="#573C5E"
      />
      <path
        d="M25.7995 7.65945C25.7995 9.15599 24.6152 10.3692 23.1543 10.3692C21.6933 10.3692 20.509 9.15599 20.509 7.65945C20.509 6.1629 21.6933 4.94971 23.1543 4.94971C24.6152 4.94971 25.7995 6.1629 25.7995 7.65945Z"
        fill="#573C5E"
      />
      <path
        d="M22.3651 13.4168C22.3651 14.9133 21.1808 16.1265 19.7199 16.1265C18.259 16.1265 17.0747 14.9133 17.0747 13.4168C17.0747 11.9202 18.259 10.707 19.7199 10.707C21.1808 10.707 22.3651 11.9202 22.3651 13.4168Z"
        fill="#573C5E"
      />
      <path
        d="M25.5976 13.4168C25.5976 14.9133 24.4133 16.1265 22.9523 16.1265C21.4914 16.1265 20.3071 14.9133 20.3071 13.4168C20.3071 11.9202 21.4914 10.707 22.9523 10.707C24.4133 10.707 25.5976 11.9202 25.5976 13.4168Z"
        fill="#573C5E"
      />
      <path
        d="M21.456 15.5379C21.456 17.0344 20.2717 18.2476 18.8107 18.2476C17.3498 18.2476 16.1655 17.0344 16.1655 15.5379C16.1655 14.0413 17.3498 12.8281 18.8107 12.8281C20.2717 12.8281 21.456 14.0413 21.456 15.5379Z"
        fill="#573C5E"
      />
      <path
        d="M26.0014 18.1643C26.0014 19.6609 24.8171 20.8741 23.3562 20.8741C21.8952 20.8741 20.7109 19.6609 20.7109 18.1643C20.7109 16.6678 21.8952 15.4546 23.3562 15.4546C24.8171 15.4546 26.0014 16.6678 26.0014 18.1643Z"
        fill="#573C5E"
      />
      <path
        d="M26.4054 19.8816C26.4054 21.3782 25.2211 22.5914 23.7602 22.5914C22.2993 22.5914 21.115 21.3782 21.115 19.8816C21.115 18.3851 22.2993 17.1719 23.7602 17.1719C25.2211 17.1719 26.4054 18.3851 26.4054 19.8816Z"
        fill="#573C5E"
      />
      <path
        d="M13.3752 15.4368C13.3752 16.9333 12.1909 18.1465 10.7299 18.1465C9.26902 18.1465 8.08472 16.9333 8.08472 15.4368C8.08472 13.9402 9.26902 12.7271 10.7299 12.7271C12.1909 12.7271 13.3752 13.9402 13.3752 15.4368Z"
        fill="#573C5E"
      />
      <path
        d="M16.6076 6.54812C16.6076 8.04466 15.4233 9.25785 13.9624 9.25785C12.5014 9.25785 11.3171 8.04466 11.3171 6.54812C11.3171 5.05157 12.5014 3.83838 13.9624 3.83838C15.4233 3.83838 16.6076 5.05157 16.6076 6.54812Z"
        fill="#573C5E"
      />
      <path
        d="M14.7895 9.47732C14.7895 10.9739 13.6052 12.1871 12.1442 12.1871C10.6833 12.1871 9.49902 10.9739 9.49902 9.47732C9.49902 7.98077 10.6833 6.76758 12.1442 6.76758C13.6052 6.76758 14.7895 7.98077 14.7895 9.47732Z"
        fill="#573C5E"
      />
      <path
        d="M12.163 11.8006C12.163 13.2971 10.9787 14.5103 9.51778 14.5103C8.05686 14.5103 6.87256 13.2971 6.87256 11.8006C6.87256 10.304 8.05686 9.09082 9.51778 9.09082C10.9787 9.09082 12.163 10.304 12.163 11.8006Z"
        fill="#573C5E"
      />
      <path
        d="M11.456 12.1038C11.456 13.6003 10.2717 14.8135 8.81075 14.8135C7.34983 14.8135 6.16553 13.6003 6.16553 12.1038C6.16553 10.6072 7.34983 9.39404 8.81075 9.39404C10.2717 9.39404 11.456 10.6072 11.456 12.1038Z"
        fill="#573C5E"
      />
      <path
        d="M9.53678 17.1541C9.53678 18.6506 8.35247 19.8638 6.89156 19.8638C5.43064 19.8638 4.24634 18.6506 4.24634 17.1541C4.24634 15.6575 5.43064 14.4443 6.89156 14.4443C8.35247 14.4443 9.53678 15.6575 9.53678 17.1541Z"
        fill="#573C5E"
      />
      <path
        d="M16.442 16.1058L16.2862 19.0739L12.7871 18.8958L13.0717 15.5303L16.442 16.1058Z"
        fill="#FF8B76"
      />
      <path
        opacity="0.24"
        d="M13.0717 15.3555L16.442 15.9294L16.3769 17.1805C15.7616 17.4969 15.1003 17.6225 14.4405 17.5032C13.8793 17.4015 13.3786 17.1344 12.9541 16.7402L13.0717 15.3555Z"
        fill="#CE635F"
      />
      <path
        d="M20.2565 27.9295C20.2565 27.9295 23.154 36.0622 26.8057 35.1926C28.7897 34.7201 27.4192 30.4095 26.2666 27.9295C25.1156 25.4495 24.6198 19.8625 19.4514 18.5414C14.2831 17.2203 8.16093 17.2282 6.27546 17.9325C4.39 18.6368 3.0424 24.7349 0.155495 27.9295C-0.548771 32.5653 1.22478 34.3468 3.74932 32.8858C5.8351 31.6776 8.71417 27.0546 8.71417 27.0546L8.90812 37.0653L22.2939 40.717L20.2565 27.9295Z"
        fill="#3D789B"
      />
      <path
        d="M24.6197 14.6941L14.8856 17.4699L12.9286 17.2187L4.97656 13.0996V22.2728L12.3776 26.3637L14.0443 26.6162L23.3372 23.5354L24.6197 14.6941Z"
        fill="#AC3E3B"
      />
      <path
        d="M10.04 9.82772C10.571 9.25223 11.7522 10.2458 11.7522 10.2458C12.3436 11.0614 13.2736 8.24431 13.2736 8.24431C13.2736 8.24431 15.0668 9.02807 15.3689 6.53373C15.4134 6.52737 15.4579 6.51942 15.5024 6.51465C15.593 6.79604 15.7711 7.0822 16.124 7.19348C16.9062 7.43989 17.4388 7.68631 17.2162 8.30314C16.9952 8.91996 17.3004 9.88018 17.7472 10.5924C18.1907 11.2983 19.2686 11.6178 19.2686 11.6178C19.2781 11.6146 19.2861 11.613 19.2861 11.613C19.2861 11.613 20.8361 11.2696 21.003 12.0121C21.1652 12.7306 20.4482 13.4397 19.0015 12.8562C18.2607 15.1773 16.3371 16.7273 14.4405 16.4078C12.5105 16.0835 11.2832 13.9389 11.5185 11.4699C9.8047 11.3905 9.50901 10.4032 10.04 9.82772Z"
        fill="#CE635F"
      />
      <path
        d="M23.9074 14.8978L22.6992 13.9217L14.6136 16.858L5.99075 12.1968L5.36279 13.3001L12.9285 17.2188L14.8855 17.47L23.9074 14.8978Z"
        fill="white"
      />
      <path
        d="M19.4945 12.2379H20.407C20.4292 12.2379 20.4483 12.2189 20.4483 12.1966C20.4483 12.1744 20.4308 12.1553 20.407 12.1553H19.4945C19.4722 12.1553 19.4531 12.1744 19.4531 12.1966C19.4531 12.2189 19.4722 12.2379 19.4945 12.2379Z"
        fill="#C15849"
      />
      <path
        d="M11.2165 10.7146C11.2308 10.7146 11.2451 10.7067 11.2515 10.694C11.2626 10.6749 11.2547 10.6494 11.2356 10.6383L10.3151 10.1359C10.296 10.1248 10.2706 10.1328 10.2595 10.1518C10.2484 10.1709 10.2563 10.1964 10.2754 10.2075L11.1959 10.7099C11.2038 10.713 11.2102 10.7146 11.2165 10.7146Z"
        fill="#C15849"
      />
      <path
        opacity="0.24"
        d="M15.4007 6.59769C15.4452 6.58974 15.4897 6.58338 15.5342 6.57861C15.6248 6.86 15.8029 7.14616 16.1558 7.25744C16.938 7.50386 17.4706 7.75027 17.248 8.3671C17.0254 8.98393 17.3323 9.94415 17.779 10.6564C18.0508 11.0904 18.5627 11.3781 18.9173 11.5339C18.2162 11.2414 16.779 10.5212 16.5723 9.46245C16.2957 8.05392 15.2322 7.35919 15.2322 7.35919C15.3069 7.14934 15.3641 6.89816 15.4007 6.59769Z"
        fill="#CE635F"
      />
      <path
        d="M4.9767 27.053L2.45215 25.2804C2.45215 25.2804 3.60314 23.3314 4.1373 22.7956L5.55378 21.4697C5.55378 21.4697 7.14832 19.9642 7.6793 19.6097C8.21028 19.2552 9.09578 19.4332 10.0242 19.6097C10.9526 19.7862 9.18481 21.3823 9.53932 21.5588C9.89384 21.7368 10.4248 21.8258 9.53932 22.3568C8.65382 22.8894 4.9767 27.053 4.9767 27.053Z"
        fill="#CE635F"
      />
      <path
        d="M22.3437 40.5892C22.3437 40.5892 22.9432 44.292 22.4082 46.0156C21.8732 47.7408 20.7082 49.9168 18.7009 49.9962C15.893 50.1073 14.7648 47.8742 14.7648 47.8742C14.7648 47.8742 14.2664 48.936 12.7828 48.8026C11.2991 48.6693 9.07804 47.2853 9.07804 47.2853C9.07804 47.2853 4.57773 32.5187 5.90582 28.4604C6.66093 26.1543 8.21162 24.2592 11.6014 27.0447C14.9912 29.8301 22.3437 40.5892 22.3437 40.5892Z"
        fill="#A2A4AD"
      />
      <path
        d="M5.10221 22.1151C5.11333 22.1151 5.12605 22.1103 5.134 22.0992C5.15149 22.077 6.87797 19.9483 7.88589 19.5572C8.77456 19.2106 9.99868 19.6446 10.0098 19.6494C10.0321 19.6573 10.0543 19.6462 10.0623 19.6255C10.0702 19.6049 10.0591 19.581 10.0384 19.5731C9.98756 19.554 8.77298 19.1264 7.85727 19.4825C6.83028 19.8815 5.14354 21.9609 5.072 22.0483C5.05769 22.0658 5.06087 22.0913 5.07836 22.1056C5.08313 22.1119 5.09267 22.1151 5.10221 22.1151Z"
        fill="#C15849"
      />
      <path
        d="M7.56334 23.0627C7.57447 23.0627 7.58559 23.0579 7.59354 23.05C7.60626 23.0357 8.94643 21.6017 9.53624 21.6017C9.53783 21.6017 9.53783 21.6017 9.53942 21.6017C9.72224 21.6033 9.8526 21.6526 9.92573 21.7464C10.0291 21.8799 9.98455 22.0627 9.98455 22.0643C9.97978 22.0866 9.9925 22.1088 10.0148 22.1136C10.037 22.1184 10.0593 22.1057 10.064 22.0834C10.0656 22.0739 10.1181 21.8608 9.99091 21.6955C9.90188 21.581 9.75085 21.5206 9.53942 21.519C9.53783 21.519 9.53624 21.519 9.53465 21.519C8.90987 21.519 7.58877 22.9339 7.53313 22.9943C7.51723 23.0102 7.51882 23.0373 7.53472 23.0516C7.54267 23.0595 7.55221 23.0627 7.56334 23.0627Z"
        fill="#C15849"
      />
      <path
        d="M22.3778 25.2022C22.3778 25.2022 20.4911 23.0985 19.701 22.6263C18.9109 22.1541 17.0192 20.9119 16.0971 21.0883C15.175 21.2648 15.6679 21.6193 15.6679 21.6193C15.6679 21.6193 15.0288 21.6781 14.9048 21.8562C14.7808 22.0326 15.3102 22.5064 15.3102 22.5064C15.3102 22.5064 14.8587 22.3887 14.8857 22.7433C14.9127 23.0978 17.6841 24.906 18.5394 25.9091C19.3947 26.9139 20.5091 28.0303 20.5091 28.0303C20.5091 28.0303 23.1027 27.2689 22.3778 25.2022Z"
        fill="#CE635F"
      />
      <path
        d="M18.3197 22.5348C18.334 22.5348 18.3483 22.5269 18.3547 22.5141C18.3658 22.4951 18.3594 22.4696 18.3388 22.4585C18.2418 22.4029 15.9366 21.1199 14.881 21.8226C14.8619 21.8353 14.8572 21.8608 14.8699 21.8798C14.8826 21.8989 14.908 21.9037 14.9271 21.891C15.9414 21.2153 18.2768 22.5173 18.299 22.53C18.307 22.5316 18.3133 22.5348 18.3197 22.5348Z"
        fill="#C15849"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.812 10.4937L14.8931 10.5168L14.3074 12.5666L14.9932 12.3837L15.0149 12.4651L14.1855 12.6863L14.812 10.4937Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7257 10.2816C13.5229 10.2604 13.3447 10.2439 13.2869 10.2439V10.1597C13.3502 10.1597 13.534 10.1769 13.7344 10.1978C13.9363 10.2188 14.1587 10.2441 14.3019 10.261L14.292 10.3447C14.1491 10.3278 13.927 10.3026 13.7257 10.2816Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2003 10.6859C15.9975 10.6647 15.8193 10.6482 15.7615 10.6482V10.564C15.8249 10.564 16.0086 10.5812 16.209 10.6021C16.4109 10.6231 16.6333 10.6484 16.7765 10.6653L16.7667 10.749C16.6237 10.7321 16.4016 10.7069 16.2003 10.6859Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1505 14.6455C13.8404 14.5586 13.6155 14.3114 13.5027 14.1118L13.5761 14.0703C13.6821 14.2579 13.8916 14.4854 14.1732 14.5644C14.4507 14.6421 14.8102 14.5788 15.2267 14.1623L15.2863 14.2219C14.8544 14.6538 14.4647 14.7336 14.1505 14.6455Z"
        fill="black"
      />
      <path
        d="M11.86 7.15407C11.86 8.65062 10.6757 9.86381 9.2148 9.86381C7.75389 9.86381 6.56958 8.65062 6.56958 7.15407C6.56958 5.65753 7.75389 4.44434 9.2148 4.44434C10.6757 4.44434 11.86 5.65753 11.86 7.15407Z"
        fill="#573C5E"
      />
      <path
        d="M12.3651 10.0833C12.3651 11.5798 11.1808 12.793 9.71993 12.793C8.25901 12.793 7.07471 11.5798 7.07471 10.0833C7.07471 8.58673 8.25901 7.37354 9.71993 7.37354C11.1808 7.37354 12.3651 8.58673 12.3651 10.0833Z"
        fill="#573C5E"
      />
      <path
        d="M23.2743 11.6995C23.2743 13.196 22.09 14.4092 20.6291 14.4092C19.1682 14.4092 17.9839 13.196 17.9839 11.6995C17.9839 10.2029 19.1682 8.98975 20.6291 8.98975C22.09 8.98975 23.2743 10.2029 23.2743 11.6995Z"
        fill="#573C5E"
      />
      <path
        d="M20.3449 6.14431C20.3449 7.64086 19.1606 8.85405 17.6997 8.85405C16.2387 8.85405 15.0544 7.64086 15.0544 6.14431C15.0544 4.64776 16.2387 3.43457 17.6997 3.43457C19.1606 3.43457 20.3449 4.64776 20.3449 6.14431Z"
        fill="#573C5E"
      />
    </svg>
  );
}

Woman02.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 28 / 50,
  svg: Woman02,
  title,
};
