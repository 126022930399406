import PropTypes from 'prop-types';
import styled from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import { PaneInner, SearchInputContainer, StyledPane } from '../common/styles';
import paneId from './paneId';
import { useCallback, useMemo } from 'react';
import { useExternalMedia } from '@src/web-stories-wp/story-editor/src/app/externalMedia';
import ExternalMediaContent from './externalMediaContent';
import { Media3pPane } from '../media3p';
import { useMedia } from '@src/web-stories-wp/story-editor/src/app';
import { useMediaProvidersOptions } from '@src/web-stories-wp/story-editor/src/app/media';
import { is3pMediaProvider } from '../../../../../app/media/media3p/providerConfiguration';
import OptionsList from '@src/components/optionsList/optionsList';
import OptionsListBackButton from '@src/components/optionsList/optionsListBackButton/optionsListBackButton';
import { SearchInput } from '../../../common';
import { Icons } from '@web-stories-wp/design-system';
import { PANE_PADDING } from '../../shared';

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0;
`;

const OptionsListWrapper = styled.div`
  align-items: center;
  background-color: transparent;
  border: none;
  color: white;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  text-decoration: none;
  transition: padding 0.3s ease-in-out;
  width: 100%;
  min-height: var(--icon-button-size);
  padding: 4px ${PANE_PADDING} 0;
`;

const RefreshButton = styled.button`
  background: none;
  border: none;
  color: #919899;
  cursor: pointer;
  padding: 0;
  width: var(--icon-button-size);
  height: var(--icon-button-size);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RefreshImage = styled(Icons.Refresh)`
  width: 20px;
  height: 20px;
`;

function ExternalMediaPane(props) {
  const mediaProvidersOptions = useMediaProvidersOptions();
  const areOptionsAvailable = mediaProvidersOptions.length > 0;

  const {
    actions: { updateMediaType, refreshMedia, updateFilters, updateSearchTerm },
    state: { filters, mediaType, searchTerm },
  } = useExternalMedia();

  const {
    setSearchTerm: setMedia3pSearchTerm,
    selectedProvider,
    setSelectedProvider,
    resetMedia,
  } = useMedia(
    ({
      media3p: {
        state: { selectedProvider },
        actions: { setSearchTerm, setSelectedProvider, resetMedia },
      },
    }) => ({
      selectedProvider,
      setSearchTerm,
      setSelectedProvider,
      resetMedia,
    })
  );

  const isMediaTypeSelected = useMemo(
    () => Object.keys(mediaType).length > 0,
    [mediaType]
  );

  const is3pMedia = is3pMediaProvider(mediaType.value);

  const selectMediaProvider = useCallback(
    (mediaProviderId) => {
      const selectedOption = mediaProvidersOptions.find(
        (option) => option.value.mediaProviderId === mediaProviderId
      );

      // Reset media if we select same media provider type but different instance
      // to make sure previous media is not displayed before fetch start
      if (
        selectedProvider?.providerConfigurationKey ===
          selectedOption.value.providerConfigurationKey &&
        selectedProvider?.mediaProviderId !==
          selectedOption.value.mediaProviderId
      ) {
        resetMedia({ provider: selectedProvider?.providerConfigurationKey });
      }

      updateMediaType({
        label: selectedOption.label,
        value: selectedOption.value.providerConfigurationKey,
      });
      if (is3pMediaProvider(selectedOption.value.providerConfigurationKey)) {
        setSelectedProvider(selectedOption.value);
      }
    },
    [
      mediaProvidersOptions,
      updateMediaType,
      selectedProvider,
      setSelectedProvider,
      resetMedia,
    ]
  );

  const showExternalMediaContent = isMediaTypeSelected && !is3pMedia;
  const showMedia3Pane = isMediaTypeSelected && is3pMedia;

  const deselectMediaProviderOrFolder = useCallback(() => {
    if (!!filters.folderName) {
      updateFilters({ ...filters, searchText: '', folderName: '' });

      if (showExternalMediaContent) {
        return;
      }
    }

    updateMediaType({});
  }, [
    filters.folderName,
    showExternalMediaContent,
    updateFilters,
    updateMediaType,
  ]);

  const mediaProvidersOptionsReadyForOptionsList = useMemo(
    () =>
      mediaProvidersOptions
        .map((option) => ({
          label: option.label,
          key: option.value.mediaProviderId,
        }))
        .filter((option) =>
          searchTerm
            ? option.label?.toLowerCase()?.includes(searchTerm?.toLowerCase())
            : true
        ),
    [mediaProvidersOptions, searchTerm]
  );

  const filterMediaProviders = useCallback(
    (searchTerm) => {
      updateSearchTerm(searchTerm);
    },
    [updateSearchTerm]
  );

  return (
    <StyledPane id={paneId} {...props}>
      <PaneInner>
        {!isMediaTypeSelected && (
          <>
            <FiltersContainer>
              <SearchInputContainer>
                <SearchInput
                  initialValue={searchTerm}
                  placeholder={__('Search', 'web-stories')}
                  onSearch={filterMediaProviders}
                  incremental
                  disabled={mediaProvidersOptions.length === 0}
                />
              </SearchInputContainer>
            </FiltersContainer>

            <OptionsList
              options={mediaProvidersOptionsReadyForOptionsList}
              shouldDisplayOptionsList={areOptionsAvailable}
              setChosenOption={selectMediaProvider}
              disableAllOptions
              shouldDisplayNoOptionFoundMessage={
                mediaProvidersOptionsReadyForOptionsList.length === 0
              }
            />
          </>
        )}
        {isMediaTypeSelected && (
          <>
            <OptionsListWrapper>
              <OptionsListBackButton
                className="templatesListByFolder__goBack"
                onClick={deselectMediaProviderOrFolder}
              >
                {mediaType.label + (filters.folderName ? ` | ${filters.folderName?.value}` : '')}
              </OptionsListBackButton>
              <RefreshButton
                onClick={() => {
                  if (!!showMedia3Pane) {
                    resetMedia({
                      provider: selectedProvider?.providerConfigurationKey,
                    });
                    setMedia3pSearchTerm('');
                  } else {
                    refreshMedia();
                  }
                }}
              >
                <RefreshImage />
              </RefreshButton>
            </OptionsListWrapper>
            <ExternalMediaContent
              isActive={areOptionsAvailable && showExternalMediaContent}
            />
            <Media3pPane
              isTabListEnabled={false}
              selectedTab={mediaType.value}
              isActive={areOptionsAvailable && showMedia3Pane}
            />
          </>
        )}
      </PaneInner>
    </StyledPane>
  );
}

ExternalMediaPane.propTypes = {
  isActive: PropTypes.bool,
};

export default ExternalMediaPane;
