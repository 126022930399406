import { useMemo } from '@web-stories-wp/react';
import { useStory } from '@src/web-stories-wp/story-editor/src/app';

function useAnyResourceInLoadingState() {
  const { pages } = useStory(({ state: { pages } }) => {
    return {
      pages,
    };
  });

  return useMemo(() => {
    if (!pages) {
      return false;
    }

    for (const page of pages) {
      if (!page.elements) {
        continue;
      }

      for (const element of page.elements) {
        if (!element.resource) {
          continue;
        }

        if (element.resource.loading) {
          return true;
        }
      }
    }

    return false;
  }, [pages]);
}

export default useAnyResourceInLoadingState;
