import styled from 'styled-components';

const MediaRowElement = styled.div`
  display: flex;
  padding: 8px 0 8px 8px;
  margin-right: 2px;
  border-radius: 6px;
  cursor: pointer;
  flex-direction: row;
  ${({ isPreview, theme }) =>
    isPreview &&
    `
  background:  ${theme.colors.bg.tertiary};
  flex-direction: column;`}

  &:hover {
    background: ${({ theme }) => theme.colors.bg.tertiary};
  } ;
`;

export default MediaRowElement;
