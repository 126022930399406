import stickers from '@web-stories-wp/stickers';
import SvgFromUrl from "./svgFromUrl";

const Noop = () => null;

function StickerElement({ element, style, className }) {
  const { sticker } = element;

  if (!sticker) {
    return <Noop />;
  }

  const { url, type } = sticker;

  if (url) {
    return <SvgFromUrl style={style} url={url} className={className} />;
  }

  const StickerOutput = stickers[type]?.svg || Noop;

  return <StickerOutput style={style} className={className} />;
}

export default StickerElement;
