import { __ } from '@web-stories-wp/i18n';
import { useEffect, useState } from '@web-stories-wp/react';
import parse from 'html-react-parser';
import React from 'react';
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator';
import styled from 'styled-components';
import useUnmountSignal from '@src/web-stories-wp/story-editor/src/utils/useUnmountSignal';

const ScaledLoadingIndicator = styled(LoadingIndicator)`
  transform: scale(0.5);
`;

//Cause we get SVG from URLs and parse them as components, its good idea to cache them, to prevent multiple requests and parsing
function addToSvgGlobalCache(key, svg) {
  if (!global.SVG_CACHE) {
    global.SVG_CACHE = [];
  }

  global.SVG_CACHE[key] = svg;
}

function getFromGlobalCache(key) {
  return global.SVG_CACHE?.[key];
}

function SvgFromUrl({ url, style, className, loadable }) {
  const unmountSignal = useUnmountSignal();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (getFromGlobalCache(url)) {
      setIsLoading(false);
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    fetch(url, { signal: unmountSignal })
      .then((res) => res.text())
      .then((svgHtml) => {
        addToSvgGlobalCache(url, parse(svgHtml, { trim: true }));
      })
      .then(() => setIsLoading(false))
      .catch((e) => {
        //We are cancelling fetch on unmount
        //DOMException represents case when requests is aborted
        if (!(e instanceof DOMException)) {
          throw e;
        }
      });
  }, [url, unmountSignal]);

  const svgFromCache = getFromGlobalCache(url);

  if (isLoading || !svgFromCache) {
    return loadable ? <ScaledLoadingIndicator /> : <></>;
  }

  return React.cloneElement(svgFromCache, { style, className });
}

export default SvgFromUrl;
