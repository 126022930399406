/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import PropTypes from 'prop-types';
import { useCallback } from '@web-stories-wp/react';
import styled from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import { SimplePanel } from '../../panel';
/**
 * Internal dependencies
 */
import { Row as DefaultRow, TextArea } from '../../../form';
import {
  focusStyle,
  inputContainerStyleOverride,
  getCommonValue,
} from '../../shared';

const DEFAULT_PADDING = {
  horizontal: 0,
  vertical: 0,
  locked: true,
  hasHiddenPadding: false,
};

const MIN_MAX = {
  PADDING: {
    MIN: 0,
    MAX: 300,
  },
};

const Space = styled.div`
  flex: 0 0 8px;
`;

const Row = styled(DefaultRow)`
  position: relative;
`;

const IconWrapper = styled.div`
  width: 44px;
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.divider.primary};
  ${({ reverse }) => reverse && 'transform: scaleY(-1);'}
  svg {
    width: 22px;
  }
`;

function AutomationKeyPanel({
  selectedElements,
  pushUpdate,
}) {
  const automationKey = getCommonValue(selectedElements, 'key', '');

  const firstInputProperties = {
    suffix: 'Automation Key',
    'aria-label': 'Automation Key',
    onChange: (evt) => {
      console.log(evt.target.value);
      pushUpdate({ key: evt.target.value || null }, true);
    }
  };

  return (
    <SimplePanel name="key" title="Automation Key">
      <Row>
        <TextArea
          value={automationKey}
          placeholder='Automation Key'
          {...firstInputProperties}
          containerStyleOverride={inputContainerStyleOverride}
        />
      </Row>
    </SimplePanel>
  );
}

AutomationKeyPanel.propTypes = {
  selectedElements: PropTypes.array.isRequired,
  pushUpdateForObject: PropTypes.func.isRequired,
  pushUpdate: PropTypes.func.isRequired,
};

export default AutomationKeyPanel;
