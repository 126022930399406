/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useKeyDownEffect } from '@web-stories-wp/design-system';

function useKeyMoveStop(ref, onMove) {
  // Reversed since the gradient line is reversed in terms of colors.
  useKeyDownEffect(ref, 'left', () => onMove(0.01), [onMove]);
  useKeyDownEffect(ref, 'right', () => onMove(-0.01), [onMove]);
}

export default useKeyMoveStop;
