import { useMemo } from 'react';
import { PROVIDERS } from './media3p/providerConfiguration';
import { useConfig } from '../config';

function useMediaProvidersFeatures(id) {
  const { mediaProviders } = useConfig();

  return useMemo(() => {
    if (!mediaProviders || !id) return undefined;

    const providerInfo = mediaProviders.find(
      (provider) => provider.code === id
    );

    if (!providerInfo) return undefined;
    const unifiedCode = providerInfo?.code.toLowerCase();
    const thirdPartyProvider = PROVIDERS[unifiedCode];
    const defaultLayoutType = thirdPartyProvider ? 'list' : 'list';

    return {
      id: providerInfo.code,
      layoutType: providerInfo.layoutType ?? defaultLayoutType,
      enableDeletion: providerInfo.enableDeletion ?? true,
      enableEditInAzzuu: providerInfo.enableEditInAzzuu ?? true,
    };
  }, [mediaProviders]);
}

export default useMediaProvidersFeatures;
