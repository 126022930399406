/** COMBINED MEDIA TYPES */
export const MEDIA_TYPE_CHANGED = 'MEDIA_TYPE_CHANGED';
export const MEDIA_TYPE_ASSETS_FETCH_START = 'MEDIA_TYPE_ASSETS_FETCH_START';
export const MEDIA_TYPE_ASSETS_FETCH_END = 'MEDIA_TYPE_ASSETS_FETCH_END';
export const MEDIA_TYPE_ASSETS_FETCH_SUCCESS =
  'MEDIA_TYPE_ASSETS_FETCH_SUCCESS';
export const MEDIA_TYPE_ASSETS_FETCH_FAILED = 'MEDIA_TYPE_ASSETS_FETCH_FAILED';
export const MEDIA_TYPE_FILTERS_CHANGED = 'MEDIA_TYPE__FILTERS_CHANGED';
export const MEDIA_TYPE_SEARCH_TERM_CHANGED = 'MEDIA_TYPE_SEARCH_TERM_CHANGED';
export const MEDIA_TYPE_SET_VIDEO_AS_IN_PREVIEW =
  'MEDIA_TYPE_SET_VIDEO_AS_IN_PREVIEW';
export const MEDIA_TYPE_SET_PREVIEW_MUTE_STATE =
  'MEDIA_TYPE_SET_PREVIEW_MUTE_STATE';
export const MEDIA_TYPE_SET_PREVIEW_PAUSE_STATE =
  'MEDIA_TYPE_SET_PREVIEW_PAUSE_STATE';
export const MEDIA_TYPE_ASSETS_REFRESHED = 'MEDIA_ASSETS_REFRESHED';
export const MEDIA_TYPE_ASSET_DELETED = 'MEDIA_ASSET_DELETED';
