import styled from 'styled-components';
import VideoProps from '../propsTypes/video';

const ThumbnailOverlay = styled.div.attrs((props) => ({
  style: {
    backgroundImage: `url(${props.imageUrl})`,
  },
}))`
  position: relative;
  display: inline-block;
  min-height: 0;
  width: 100%;
  padding-top: ${({ assetRatio }) => {
    if (parseInt(assetRatio) < 100) {
      return assetRatio;
    } else {
      return '56.25%';
    }
  }};
  background: ${({ theme }) => theme.colors.interactiveBg.disable};
  background-size: ${({ assetRatio }) => {
    if (parseInt(assetRatio) >= 100) {
      return 'contain';
    } else {
      return 'cover';
    }
  }};
  background-position: center;
  background-repeat: no-repeat;
  border-radius: ${({ theme }) => theme.borders.radius.small};
`;

function ExternalMediaThumbnail({ src, assetRatio, children }) {
  return (
    <ThumbnailOverlay imageUrl={src} assetRatio={assetRatio}>
      {children}
    </ThumbnailOverlay>
  );
}

ExternalMediaThumbnail.propTypes = {
  video: VideoProps,
};

export default ExternalMediaThumbnail;
