import React from 'react';
import { updateSettings } from '@web-stories-wp/date';
import { FlagsProvider } from 'flagged';
import StoryEditor from '@web-stories-wp/story-editor';
import Layout from '@components/layout';
import elementTypes from '@web-stories-wp/story-editor/src/elements/elementTypes';
import { registerElementType } from '@web-stories-wp/story-editor/src/elements';

export const StorytellerStoryEditor = ({ editorConfig }) => {
  const { flags, config } = editorConfig;
  const allFeatureFlags = { ...flags, ...config.features };

  updateSettings(config.locale);

  elementTypes.forEach(registerElementType);

  return (
    <FlagsProvider features={allFeatureFlags}>
      <StoryEditor config={config}>
        <Layout />
      </StoryEditor>
    </FlagsProvider>
  );
};
