/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useCallback } from '@web-stories-wp/react';

/**
 * Internal dependencies
 */
import cleanForSlug from '../../../utils/cleanForSlug';
import getGoogleFontURL from '../../../utils/getGoogleFontURL';
import getFontCSS from '../../../utils/getFontCSS';
import loadStylesheet from '../../../utils/loadStylesheet';
import { FontsService } from '..';

function ensureFontLoaded(fontFaceSet, content) {
  if (!document?.fonts) {
    return Promise.resolve(true);
  }

  return document.fonts
    .load(fontFaceSet, content || '')
    .then(() => document.fonts.check(fontFaceSet, content || ''));
}

function loadInlineStylesheet(id, css) {
  if (!css) {
    return;
  }

  const style = document.createElement('style');
  style.textContent = css;
  style.id = id;
  document.head.appendChild(style);
}

export async function maybeLoadFont(font) {
  const { family, service, variants, url } = font;
  const handle = cleanForSlug(family);
  const elementId = `web-stories-${handle}-font-css`;

  const hasFontLink = () => document.getElementById(elementId);

  if (hasFontLink()) {
    return;
  }

  switch (service) {
    case FontsService.google:
      await loadStylesheet(
        getGoogleFontURL([{ family, variants }], 'auto'),
        elementId
      );
      break;
    case FontsService.custom:
      await loadInlineStylesheet(elementId, getFontCSS(family, url));
      break;
    default:
      return;
    }; 
}

function useLoadFontFiles() {
  /**
   * Enqueue a list of given fonts by adding <link> or <style> elements.
   *
   * Allows dynamically enqueuing font styles when needed.
   *
   * @param {Array} fonts An array of fonts properties to create a valid FontFaceSet to inject and preload a font-face
   * @return {Promise<boolean>} Returns fonts loaded promise
   */
  const maybeEnqueueFontStyle = useCallback(
    (fonts) => {
      return Promise.allSettled(
        fonts.map(async ({ font, fontWeight, fontStyle, content }) => {
          const { family, service } = font;
          if (!family || service === FontsService.system) {
            return null;
          }

          const fontFaceSet = `
              ${fontStyle || ''} ${fontWeight || ''} 0 '${family}'
            `.trim();

          await maybeLoadFont(font);

          return ensureFontLoaded(fontFaceSet, content);
        })
      );
    },
    []
  );

  return maybeEnqueueFontStyle;
}

export default useLoadFontFiles;
