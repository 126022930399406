export class DeferredPromise<ReturnType> {
  promise: Promise<ReturnType> | undefined;
  resolve: (value: ReturnType | PromiseLike<ReturnType>) => void;
  // Promise type uses any
  reject: (reason?: any) => void;

  constructor() {
    this.promise = new Promise<ReturnType>((res, rej) => {
      this.resolve = res;
      this.reject = rej;
    });
  }
}
