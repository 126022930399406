/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from '@web-stories-wp/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { trackEvent } from '@web-stories-wp/tracking';
import {
  _n,
  sprintf,
  __,
  _x,
  translateToInclusiveList,
} from '@web-stories-wp/i18n';
import {
  THEME_CONSTANTS,
  LOCAL_STORAGE_PREFIX,
  Text,
  Toggle,
  Headline,
  useLiveRegion,
  localStore,
} from '@web-stories-wp/design-system';
import { useTextSets } from './useTextSets';

/**
 * Internal dependencies
 */
import { FullWidthWrapper } from '../../common/styles';
import { ChipGroup } from '../../shared';
import useStory from '../../../../../app/story/useStory';
import {
  getInUseFontsForPages,
  getTextSetsForFonts,
} from '../../../../../utils/getInUseFonts';
import { Container as SectionContainer } from '../../../common/section';
import { virtualPaneContainer } from '../../shared/virtualizedPanelGrid';
import EmptyContentMessage from '../../../../emptyContentMessage';
import TextSets from './textSets';
import { CATEGORIES, PANE_TEXT, CUSTOM_CATEGORY } from './constants';

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

const TextSetsToggle = styled.div`
  display: flex;

  label {
    cursor: pointer;
    margin: auto 12px;
    color: ${({ theme }) => theme.colors.fg.secondary};
  }
`;

const TextSetsWrapper = styled.div`
  ${virtualPaneContainer};
  ${({ isMarginTopZero }) =>
    isMarginTopZero &&
    `
      margin-top: 0;
    `}
`;

//Custom category always should be first
function categoriesSorter(categoryA, categoryB) {
  if (categoryA === CUSTOM_CATEGORY) return -1;
  if (categoryB === CUSTOM_CATEGORY) return 1;

  return categoryA > categoryB;
}

function TextSetsPane({ paneRef }) {
  const { textSets, areTextSetsLoading } = useTextSets();

  const [showInUse, setShowInUse] = useState(false);

  const allTextSets = useMemo(() => Object.values(textSets).flat(), [textSets]);
  const storyPages = useStory(({ state: { pages } }) => pages);

  const [selectedCat, setSelectedCat] = useState(
    localStore.getItemByKey(`${LOCAL_STORAGE_PREFIX.TEXT_SET_SETTINGS}`)
      ?.selectedCategory
  );

  const getTextSetsForInUseFonts = useCallback(
    () =>
      getTextSetsForFonts({
        fonts: getInUseFontsForPages(storyPages),
        textSets: selectedCat ? textSets[selectedCat] : allTextSets,
      }),
    [allTextSets, storyPages, selectedCat, textSets]
  );

  const addIdToTextSets = useCallback(
    (textSetsArray = []) =>
      textSetsArray.map(
        ({
          textSetFonts = [],
          textSetCategory,
          elements,
          coverImageUrl,
          isCustom,
          id,
        }) => {
          return {
            id,
            coverImageUrl,
            isCustom,
            title: sprintf(
              /* translators: 1: text set category. 2: list of fonts. */
              _n(
                'Text set %1$s with %2$s font',
                'Text set %1$s with %2$s fonts',
                textSetFonts.length,
                'web-stories'
              ),
              CATEGORIES[textSetCategory],
              translateToInclusiveList(textSetFonts)
            ),
            elements,
          };
        }
      ),
    []
  );

  const filteredTextSets = useMemo(() => {
    if (showInUse) {
      return addIdToTextSets(getTextSetsForInUseFonts());
    }

    let selectedTextSets = null;
    if (selectedCat && Object.keys(textSets).includes(selectedCat)) {
      selectedTextSets = textSets?.[selectedCat];
    }

    return addIdToTextSets(selectedTextSets ?? allTextSets);
  }, [
    selectedCat,
    textSets,
    allTextSets,
    addIdToTextSets,
    getTextSetsForInUseFonts,
    showInUse,
  ]);

  const categories = useMemo(() => {
    if (areTextSetsLoading) {
      return [];
    }

    let textSetsCategories = Object.keys(textSets);
    let result = [];

    //Dont show All category when there is only one category
    if (textSetsCategories.length > 1) {
      result = [{ id: null, label: _x('All', 'text sets', 'web-stories') }];
    }

    return [
      ...result,
      ...textSetsCategories.sort(categoriesSorter).map((category) => ({
        id: category,
        label: CATEGORIES[category] ?? category,
      })),
    ];
  }, [textSets, areTextSetsLoading]);

  const speak = useLiveRegion();

  const handleSelectedCategory = useCallback(
    (selectedCategory) => {
      setSelectedCat(selectedCategory);
      speak(
        selectedCategory === null
          ? __('Show all text sets', 'web-stories')
          : sprintf(
              /* translators: %s: filter category name */
              __('Selected text set filter %s', 'web-stories'),
              CATEGORIES[selectedCategory]
            )
      );
      localStore.setItemByKey(`${LOCAL_STORAGE_PREFIX.TEXT_SET_SETTINGS}`, {
        selectedCategory,
      });
    },
    [speak]
  );

  const onChangeShowInUse = useCallback(
    () => requestAnimationFrame(() => setShowInUse((prevVal) => !prevVal)),
    [setShowInUse]
  );

  useEffect(() => {
    trackEvent('search', {
      search_type: 'textsets',
      search_term: '',
      search_category: selectedCat,
      search_filter: showInUse ? 'show_in_use' : undefined,
    });
  }, [selectedCat, showInUse]);

  const sectionId = useMemo(() => `section-${uuidv4()}`, []);
  const toggleId = useMemo(() => `toggle_text_sets_${uuidv4()}`, []);

  const emptyContentMessage = useMemo(
    () =>
      showInUse
        ? __(
            'No matching Text Sets available. Try adding text to your story.',
            'web-stories'
          )
        : __('No Text Sets available.', 'web-stories'),
    [showInUse]
  );

  const showCategorySelector = categories.length > 1;

  return (
    <SectionContainer id={sectionId}>
      <TitleBar>
        <Headline
          as="h3"
          size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.XXX_SMALL}
        >
          {PANE_TEXT.TITLE}
        </Headline>
        <TextSetsToggle>
          <Text
            as="label"
            htmlFor={toggleId}
            size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.SMALL}
          >
            {PANE_TEXT.SWITCH_LABEL}
          </Text>
          <Toggle
            id={toggleId}
            aria-label={PANE_TEXT.SWITCH_LABEL}
            name={toggleId}
            checked={showInUse}
            onChange={onChangeShowInUse}
          />
        </TextSetsToggle>
      </TitleBar>
      {showCategorySelector && (
        <FullWidthWrapper>
          <ChipGroup
            items={categories}
            selectedItemId={selectedCat}
            selectItem={handleSelectedCategory}
            deselectItem={() => handleSelectedCategory(null)}
            ariaLabel={__('Select filter for text sets list', 'web-stories')}
          />
        </FullWidthWrapper>
      )}
      <TextSetsWrapper isMarginTopZero={!showCategorySelector}>
        {!filteredTextSets?.length && !areTextSetsLoading ? (
          <EmptyContentMessage>{emptyContentMessage}</EmptyContentMessage>
        ) : (
          <TextSets paneRef={paneRef} filteredTextSets={filteredTextSets} />
        )}
      </TextSetsWrapper>
    </SectionContainer>
  );
}

TextSetsPane.propTypes = {
  paneRef: PropTypes.object,
};

export default TextSetsPane;
