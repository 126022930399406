/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Books', 'sticker name', 'web-stories');

function Books({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M9.38432 21.5733L31.044 21.2222C31.044 21.2222 32.8889 22.5821 32.8889 25.3621C32.8877 29.7463 31.1155 29.9721 31.1155 29.9721H16.826L9.38432 21.5733Z"
        fill="#AC3F3B"
      />
      <path
        d="M3.53208 22.2158C3.53208 22.2158 4.95998 24.2116 4.95998 25.7657C4.95998 27.8619 3.53208 29.211 3.53208 29.211L20.3166 29.1204C20.3166 29.1204 22.2481 29.284 21.9459 25.3895C21.6437 21.4939 19.9277 22.2278 19.9277 22.2278L3.53208 22.2158Z"
        fill="#DDDDDD"
      />
      <path
        opacity="0.32"
        d="M21.946 25.3817C21.6438 21.486 19.9277 22.22 19.9277 22.22L3.53331 22.208C3.53331 22.208 3.79804 22.5777 4.09908 23.122L18.1543 23.7709C18.1543 23.7709 20.7781 23.9323 21.071 26.2934C21.235 27.6142 21.0171 28.4746 20.7676 29.0265C21.317 28.8084 22.1521 28.0417 21.946 25.3817Z"
        fill="#DDDDDD"
      />
      <path
        d="M3.45599 21.5787L19.9031 21.4707C22.5211 21.9451 22.7683 24.5887 22.7952 26.1101C22.8432 28.7265 21.6567 29.9709 20.2733 29.9709L3.55322 29.9382C3.1245 29.9371 2.77777 29.6132 2.77777 29.2151L20.3166 29.1202C21.1366 29.2107 21.9776 28.4299 21.9835 25.6586C21.987 23.7577 21.1366 22.401 19.9277 22.2276L2.77777 22.2156C2.7766 21.8655 3.07998 21.5819 3.45599 21.5787Z"
        fill="#EA765C"
      />
      <path
        opacity="0.29"
        d="M25.6091 21.4142C25.6091 21.4142 26.6116 23.2478 26.6356 25.0651C26.6879 29.0103 25.87 30.0002 25.87 30.0002H28.1477C28.1477 30.0002 28.9677 29.0103 28.8115 24.7958C28.733 22.6787 27.836 21.3335 27.836 21.3335L25.6091 21.4142Z"
        fill="#294756"
      />
      <path
        opacity="0.25"
        d="M21.9798 21.4163L31.0461 21.2222C31.0461 21.2222 31.9364 22.0488 32.2222 22.8755C32.221 22.8755 30.8646 21.4905 21.9798 21.4163Z"
        fill="#294756"
      />
      <path
        d="M25.2392 6.68467L3.38889 6.3335C3.38889 6.3335 0 7.69347 0 10.4734C0.00125781 14.8577 3.55555 15.0834 3.55555 15.0834H17.2484L25.2392 6.68467Z"
        fill="#CE635F"
      />
      <path
        d="M30.4445 7.37625C30.4445 7.37625 28.9909 9.36124 28.9909 10.9069C28.9909 12.9917 30.4445 14.3335 30.4445 14.3335L13.3581 14.2435C13.3581 14.2435 11.3918 14.4062 11.6994 10.5327C12.0071 6.65818 13.754 7.38818 13.754 7.38818L30.4445 7.37625Z"
        fill="#DDDDDD"
      />
      <path
        opacity="0.32"
        d="M11.6994 10.4517C12.007 6.54045 13.7541 7.27737 13.7541 7.27737L30.4445 7.26532C30.4445 7.26532 30.175 7.63652 29.8685 8.18291L15.5595 8.83441C15.5595 8.83441 12.8883 8.99647 12.5902 11.3671C12.4232 12.6931 12.645 13.557 12.899 14.1111C12.3397 13.8921 11.4895 13.1223 11.6994 10.4517Z"
        fill="#DDDDDD"
      />
      <path
        d="M30.5334 6.77425L13.8279 6.66699C11.1688 7.13829 10.9177 9.76459 10.8903 11.276C10.8416 13.8752 12.0468 15.1114 13.4519 15.1114L30.4346 15.0789C30.8701 15.0779 31.2223 14.7561 31.2223 14.3606L13.4079 14.2663C12.5751 14.3563 11.7208 13.5805 11.7149 10.8275C11.7113 8.939 12.5751 7.59118 13.8029 7.41891L31.2223 7.40699C31.2234 7.0592 30.9153 6.7775 30.5334 6.77425Z"
        fill="#C9544F"
      />
      <path
        opacity="0.29"
        d="M7.98569 6.52549C7.98569 6.52549 6.70996 8.35912 6.67938 10.1764C6.6128 14.1216 7.65371 15.1115 7.65371 15.1115H4.75499C4.75499 15.1115 3.71137 14.1216 3.9102 9.90713C4.01006 7.79007 5.15174 6.44482 5.15174 6.44482L7.98569 6.52549Z"
        fill="#294756"
      />
      <path
        d="M9.66659 15.0359L28.3888 14.8335C28.3888 14.8335 31.3333 15.7227 31.3333 17.8429C31.3322 21.1865 27.7777 21.6668 27.7777 21.6668H18.2827L9.66659 15.0359Z"
        fill="#232429"
      />
      <path
        d="M24.889 0.144451L7.77786 0C7.77786 0 4.66675 0.820028 4.66675 2.9054C4.66772 6.1942 6.70741 6.61996 6.70741 6.61996L16.8473 6.66667L24.889 0.144451Z"
        fill="#412C47"
      />
      <path
        d="M6.81116 15.6812C6.81116 15.6812 7.93667 17.064 7.93667 18.2604C7.93667 19.8745 6.47417 21.2356 6.47417 21.2356L22.409 20.9935C22.409 20.9935 24.3012 21.0404 24.0198 18.0401C23.7384 15.0399 21.7372 15.4892 21.7372 15.4892L6.81116 15.6812Z"
        fill="#DDDDDD"
      />
      <path
        d="M27.7899 0.776365C27.7899 0.776365 26.6644 2.15925 26.6644 3.35564C26.6644 4.96973 28.1269 6.33081 28.1269 6.33081L12.1921 6.08869C12.1921 6.08869 10.2999 6.13559 10.5813 3.13534C10.8626 0.13509 12.8639 0.584417 12.8639 0.584417L27.7899 0.776365Z"
        fill="#DDDDDD"
      />
      <g opacity="0.32">
        <path
          opacity="0.32"
          d="M21.7494 16.6179C21.7494 16.6179 23.7419 16.1707 24.0299 19.1502C24.0669 18.8362 24.0724 18.4708 24.032 18.0411C23.7507 15.0408 21.7494 15.4902 21.7494 15.4902L6.82232 15.6821C6.82232 15.6821 7.21275 16.162 7.53338 16.8011L21.7494 16.6179Z"
          fill="#DDDDDD"
        />
      </g>
      <g opacity="0.32">
        <path
          opacity="0.32"
          d="M12.8519 1.71356C12.8519 1.71356 10.8594 1.26642 10.5715 4.24595C10.5344 3.93186 10.5289 3.5665 10.5693 3.1368C10.8507 0.136549 12.8519 0.585883 12.8519 0.585883L27.779 0.777824C27.779 0.777824 27.3886 1.25769 27.0679 1.89678L12.8519 1.71356Z"
          fill="#DDDDDD"
        />
      </g>
      <path
        d="M5.92345 15.2108C5.92345 15.2108 20.242 14.6557 22.0873 15.0396C23.9424 15.4257 24.5368 17.0594 24.6109 18.21C24.7451 20.2952 23.9413 21.4469 22.6239 21.6181L5.92236 21.5712V21.0717C5.92236 21.0717 22.3359 21.1033 22.7395 20.9419C23.143 20.7805 24.166 20.3476 24.0198 18.0387C23.9391 16.7616 23.3535 15.8161 22.06 15.5958C21.4275 15.4878 5.92236 15.6928 5.92236 15.6928V15.2108H5.92345Z"
        fill="#525560"
      />
      <path
        d="M28.6776 0.306507C28.6776 0.306507 14.3591 -0.248611 12.5138 0.135281C10.6587 0.521355 10.0643 2.15508 9.99013 3.30567C9.85599 5.3909 10.6598 6.54258 11.9772 6.7138L28.6787 6.6669V6.16741C28.6787 6.16741 12.2651 6.19904 11.8616 6.03763C11.4581 5.87622 10.4351 5.44325 10.5812 3.13444C10.6619 1.85734 11.2476 0.911788 12.5411 0.691486C13.1736 0.583517 28.6787 0.788552 28.6787 0.788552V0.306507H28.6776Z"
        fill="#9A9FAF"
      />
      <path
        d="M23.6665 13.4443C22.8702 12.6665 21.1665 11.0998 20.7221 11.0554L20.0554 10.6665H23.3888C23.9258 11.2406 25.0332 12.5109 25.1665 12.9998C25.2999 13.4887 24.0369 12.3517 23.3888 11.7221L23.6665 13.4443Z"
        fill="#42A08C"
      />
    </svg>
  );
}

Books.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 33 / 30,
  svg: Books,
  title,
};
