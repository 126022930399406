import getStoryResponse from './getStoryResponse';

const mockFetch = (name, result) => (a, b, c, d) => {
  return Promise.resolve(result || {});
};

const apiProviderMock = {
  getStoryById: mockFetch('getStoryById', getStoryResponse),
  getDemoStoryById: mockFetch('getDemoStoryById'),
  saveStoryById: mockFetch('saveStoryById'),
  autoSaveById: mockFetch('autoSaveById'),
  getMedia: mockFetch('getMedia'),
  uploadMedia: mockFetch('uploadMedia'),
  updateMedia: mockFetch('updateMedia'),
  deleteMedia: mockFetch('deleteMedia'),
  getLinkMetadata: mockFetch('getLinkMetadata'),
  getAuthors: mockFetch('getAuthors'),
  getCurrentUser: mockFetch('getCurrentUser', { id: 1 }),
  updateCurrentUser: mockFetch('updateCurrentUser'),
  getCustomPageTemplates: mockFetch('getCustomPageTemplates', {
    templates: [],
    hasMore: false,
  }),
  addPageTemplate: mockFetch('addPageTemplate'),
  deletePageTemplate: mockFetch('deletePageTemplate'),
  getHotlinkInfo: mockFetch('getHotlinkInfo'),
  getTaxonomies: mockFetch('getTaxonomies'),
  getTaxonomyTerm: mockFetch('getTaxonomyTerm'),
  createTaxonomyTerm: mockFetch('createTaxonomyTerm'),
};

export default apiProviderMock;
