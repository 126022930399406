import { useCallback } from '@web-stories-wp/react';
import { useStory } from '@src/web-stories-wp/story-editor/src/app';

import { isBlobURL } from '@web-stories-wp/media';

function useValidatePages() {
  const { pages, setCurrentPage, setSelectedElementsById } = useStory(
    ({
      actions: { setCurrentPage, setSelectedElementsById },
      state: { pages },
    }) => ({
      pages,
      setCurrentPage,
      setSelectedElementsById,
    })
  );

  const validatePages = useCallback(() => {
    try {
      const validateResult = [];
      for (var pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const page = pages[pageIndex];
        if (!page.elements) {
          continue;
        }

        for (
          var elementIndex = 0;
          elementIndex < page.elements.length;
          elementIndex++
        ) {
          const element = page.elements[elementIndex];
          var isFailedAsset = isBlobURL(element?.resource?.src);
          if (isFailedAsset) {
            console.error(
              `page: ${page.id} - element: ${element.id} - ${
                element.resource?.title && element.resource?.alt
              } has blob url`
            );
            validateResult.push({
              page,
              element,
              pageNumber: pageIndex + 1,
            });
          }
        }
      }

      if (validateResult.length > 0) {
        setCurrentPage({ pageId: validateResult[0].page.id });
        setSelectedElementsById({ elementIds: [validateResult[0].element.id] });
      }

      return validateResult;
    } catch (ex) {
      console.error(ex);
      return [];
    }
  }, [pages, setCurrentPage, setSelectedElementsById]);

  return { validatePages };
}

export default useValidatePages;
