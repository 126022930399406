import { useEffect, useState } from 'react';
import { appConfig } from '@config';
import { useMediaProviders, useFeatures } from '@hooks';
import MediaUpload from '@components/mediaUpload';

const { flags } = appConfig;

const mapFeatureFlags = (features) => {
  let studioFeatures = {};
  for (const [key, { value }] of Object.entries(features)) {
    studioFeatures[key] = value;
  }

  return studioFeatures;
};

const createConfig = ({ mediaProviders, apiProvider, features }) => {
  const config = {
    ...appConfig.config,
    apiCallbacks: apiProvider,
    MediaUpload,
    mediaProviders: [],
    features: mapFeatureFlags(features),
  };

  if (mediaProviders && mediaProviders.length > 0) {
    config.mediaProviders = mediaProviders.filter((provider) => provider.code);
  }

  return config;
};

const useStoryEditorConfig = ({ apiProvider }) => {
  const { mediaProviders } = useMediaProviders();
  const { features } = useFeatures();

  const [config, setConfig] = useState(null);

  useEffect(() => {
    if (mediaProviders && apiProvider && features) {
      setConfig(createConfig({ mediaProviders, apiProvider, features }));
    }
  }, [mediaProviders, features, apiProvider]);

  if (!config) return null;

  return { editorConfig: { flags, config }, mediaProviders };
};

export default useStoryEditorConfig;
