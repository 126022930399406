import styled from 'styled-components';
import { Icons } from '@web-stories-wp/design-system';
import VideoProps from '../propsTypes/video';
import { useCallback } from 'react';
import ExternalMediaThumbnail from './externalMediaThumbnail';

const { PlayFilled } = Icons;

const ThumbnailPlayButton = styled.button`
  width: 34px;
  height: 34px;
  position: absolute;
  background: 0;
  color: ${({ theme }) => theme.colors.fg.primary};
  border: none;
  padding: 0;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    color: ${({ theme }) => theme.colors.standard.white};
    border: none;
    outline: none;
  }
`;

const OverlayContainer = styled.div`
  line-height: 12px;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.opacity.black64};
  padding: 0 2px;
  bottom: 1px;
`;

const RatingOverlay = styled(OverlayContainer)`
  position: absolute;
  left: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const DurationOverlay = styled(OverlayContainer)`
  position: absolute;
  right: 1px;
  font-size: 10px;
  background-color: rgba(23, 26, 37, 0.65);
  padding: 0 4px;
`;

function ExternalVideoThumbnail({ video, onPlayClick }) {
  const assetRatio = `${video.ratio * 100}%`;

  const onPlayButtonClicked = useCallback(
    (event) => {
      event.stopPropagation();
      if (onPlayClick) {
        onPlayClick(event);
      }
    },
    [onPlayClick]
  );

  /** !IMPORTANT Below elements are commented out & disabled until the second phase of implementation */

  const isValidDisplayDuration = video.displayDuration !== 'NaN:NaN';

  return (
    <ExternalMediaThumbnail
      src={video.playcardSmallUrl ?? video.thumbnailUrl}
      assetRatio={assetRatio}
    >
      <ThumbnailPlayButton onClick={onPlayButtonClicked}>
        <PlayFilled />
      </ThumbnailPlayButton>
      {/* {video.rating && (
        <RatingOverlay>{[...Array(video.rating)].map(() => '★')}</RatingOverlay>
      )} */}
      {isValidDisplayDuration && video.displayDuration && (
        <DurationOverlay>
          {video.displayDuration.replaceAll(' ', '')}
        </DurationOverlay>
      )}
    </ExternalMediaThumbnail>
  );
}

ExternalVideoThumbnail.propTypes = {
  video: VideoProps,
};

export default ExternalVideoThumbnail;
