import { useCallback, useState } from '@web-stories-wp/react';
import RetrySaveDialog from '../../dialog/retrySaveDialog';
import StoryNotFoundDialog from '../../dialog/storyNotFoundDialog';
import PagesValidationDialog from '../../dialog/pagesValidationDialog';
import StoryWasModifiedDialog from '../../dialog/storyWasModifiedDialog';
import { useStory } from '@src/web-stories-wp/story-editor/src/app';
import { useAuthorizationManager } from '@src/hooks/authorization';
import { useValidatePages } from '@hooks';
import { useStudioContext } from '@hooks/studioContext';

import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_VARIANTS,
} from '@web-stories-wp/design-system';

function SavePagesButton({ onSave, onSaveFinish, disabled, ...rest }) {
  const { generatePages } = useStory(({ actions: { generatePages } }) => ({
    generatePages,
  }));

  const { validatePages } = useValidatePages();

  const [showRetryDialog, setShowRetryDialog] = useState(false);
  const [showEntityNotFoundDialog, setShowEntityNotFoundDialog] =
    useState(false);

  const [validationResult, setValidationResult] = useState([]);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [showStoryWasModified, setShowStoryWasModified] = useState(false);
  const studioContext = useStudioContext();
  const { authContext } = useAuthorizationManager();

  const save = useCallback(
    (addNewPagesAtTheEnd) => {
      onSave?.();

      const validation = validatePages();
      if (validation.length > 0) {
        setValidationResult(validation);
        setShowValidationModal(true);
        onSaveFinish?.();
        return;
      }

      return generatePages({ addNewPagesAtTheEnd })
        .then((saveResult) => {
          let storyChangedByOtherUser = false;
          if (saveResult) {
            storyChangedByOtherUser = saveResult.storyChangedByOtherUser;
          }

          if (storyChangedByOtherUser) {
            setShowStoryWasModified(true);
            onSaveFinish?.();
            return;
          }

          onSaveFinish?.();
          authContext.redirectToCmsEditForm(studioContext);
          return;
        })
        .catch((e) => {
          onSaveFinish?.();

          const response = e?.response;

          console.error(response?.data?.error?.details || e);

          if (response?.status === 404) {
            setShowEntityNotFoundDialog(true);
            return;
          }

          setShowRetryDialog(true);
        });
    },
    [
      authContext,
      onSave,
      validatePages,
      generatePages,
      onSaveFinish,
      studioContext,
    ]
  );

  const retrySave = useCallback(() => {
    setShowRetryDialog(false);
    save();
  }, [save]);

  const redirectToCms = useCallback(() => {
    authContext.redirectToCms();
  }, [authContext]);

  const closeValidationModal = useCallback(() => {
    setShowValidationModal(false);
  }, []);

  const saveModifiedStory = useCallback(() => {
    save(true);
    setShowStoryWasModified(false);
  }, [save]);

  const showVlidationDialog =
    showValidationModal && validationResult.length > 0;
  return (
    <>
      <Button
        {...rest}
        variant={BUTTON_VARIANTS.RECTANGLE}
        type={BUTTON_TYPES.PRIMARY}
        size={BUTTON_SIZES.SMALL}
        onClick={() => save(false)}
        disabled={disabled}
      >
        Save
      </Button>
      <RetrySaveDialog
        isOpen={showRetryDialog}
        onClose={() => setShowRetryDialog(false)}
        onRetryClick={retrySave}
      />
      <StoryNotFoundDialog
        isOpen={showEntityNotFoundDialog}
        onClose={() => setShowEntityNotFoundDialog(false)}
        onRedirectClick={redirectToCms}
      />
      <StoryWasModifiedDialog
        isOpen={showStoryWasModified}
        onClose={() => setShowStoryWasModified(false)}
        onClick={saveModifiedStory}
      />

      {showVlidationDialog && (
        <PagesValidationDialog
          isOpen={showVlidationDialog}
          onClose={closeValidationModal}
          onClick={closeValidationModal}
          validationResult={validationResult}
        />
      )}
    </>
  );
}

export default SavePagesButton;
