import { useEffect, useState } from 'react';
import useCmsApi from './api/useCmsApi';

const useMediaProviders = () => {
  const { mediaProviderApi } = useCmsApi();
  const [mediaProviders, setMediaProviders] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    mediaProviderApi
      .getList()
      .then(({ payload: providers }) => {
        setMediaProviders(providers);
      })
      .finally(() => setIsLoading(false));
  }, [mediaProviderApi]);

  return { mediaProviders, isLoading };
};

export default useMediaProviders;
