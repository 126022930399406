/*
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import type { Solid, Pattern } from '@web-stories-wp/patterns';
import type {
  Resource,
  SequenceResource,
  GifResource,
  VideoResource,
} from '@web-stories-wp/media';
import type stickers from '@web-stories-wp/stickers';

/**
 * Internal dependencies
 */
import type { ElementType } from './elementType';
import type { Track } from './media';
import type { GoogleFontData, SystemFontData, CustomFontData } from './data';

// For some reason importing ElementBox from @web-stories-wp/units doesn't work in some files
// (`duplicateElement` throws an error because it thinks the element doesn't have a x or y property)
// If updating, please also update web-stories-wp/units/src/types.ts
interface ElementBox {
  x: number;
  y: number;
  width: number;
  height: number;
  rotationAngle: number;
}

export enum LinkType {
  Regular = 'regular',
  Branching = 'branching',
}

export interface Link {
  type?: LinkType;
  pageId?: ElementId;
  url: string;
  desc?: string;
  needsProxy?: boolean;
  icon?: string | null;
  rel?: string[];
}

export interface Flip {
  vertical: boolean;
  horizontal: boolean;
}

export interface Mask {
  type: string; // 'rectangle', etc
  // TODO(#12259): Remove these from the type & from templates.
  showInLibrary?: boolean;
  name?: string;
  path?: string;
  ratio?: number;
  iconPath?: string;
  iconRatio?: number;
  supportsBorder?: boolean;
}

export interface Border {
  top: number;
  right: number;
  bottom: number;
  left: number;
  locked?: boolean;
  lockedWidth?: boolean;
  color?: Solid;
}

export interface BorderRadius {
  topLeft: number;
  topRight: number;
  bottomLeft: number;
  bottomRight: number;
  locked: boolean;
}

export type ElementId = string;

export interface BaseElement extends ElementBox {
  id: ElementId;
  type: ElementType;
  mask?: Mask;
  link?: Link;
  opacity?: number;
  lockAspectRatio?: boolean;
  flip?: Flip;
  groupId?: string;
  border?: Border;
  borderRadius?: BorderRadius;
  layerName?: string;
  isLocked?: boolean;
  isHidden?: boolean;
}

export interface LinkableElement extends BaseElement {
  link: Link;
}

export interface DefaultBackgroundElement extends BaseElement {
  isDefaultBackground: boolean;
  backgroundColor: Solid;
}

export interface BackgroundableElement extends BaseElement {
  isBackground?: boolean;
}

export interface MediaElement extends BackgroundableElement {
  resource: Resource;
  scale?: number;
  focalX?: number;
  focalY?: number;
  alt?: string;
}

export interface SequenceMediaElement extends MediaElement {
  resource: SequenceResource;
}

export interface VideoElement extends SequenceMediaElement {
  type: ElementType.Video;
  tracks: Track[];
  resource: VideoResource;
  poster?: string;
  loop?: boolean;
  volume: number;
}

export interface GifElement extends SequenceMediaElement {
  type: ElementType.Gif;
  poster?: string;
  resource: GifResource;
}

export interface ImageElement extends MediaElement {
  type: ElementType.Image;
  alt?: string;
}

export interface OverlayableElement extends BaseElement {
  overlay?: Pattern | null;
}

export interface StickerElement extends BaseElement {
  type: ElementType.Sticker;
  sticker: {
    type: keyof typeof stickers;
  };
}

export type TextElementFont = GoogleFontData | SystemFontData | CustomFontData;

export interface Padding {
  horizontal: number;
  vertical: number;
  locked: boolean;
  hasHiddenPadding?: boolean;
}

export type TextAlign = 'center' | 'justify' | 'left' | 'right';
export interface TextElement extends BaseElement {
  backgroundColor: Solid;
  content: string;
  font: TextElementFont;
  fontSize: number;

  backgroundTextMode?: string;
  tagName?: 'h1' | 'h2' | 'h3' | 'p' | 'auto';
  padding?: Padding;
  marginOffset: number;
  lineHeight: number;
  textAlign: TextAlign;

  type: ElementType.Text;
  fontStyle?: 'italic' | 'normal';
  fontWeight?: number;
}

export interface ShapeElement extends BaseElement {
  type: ElementType.Shape;
  backgroundColor?: Pattern;
  isBackground?: boolean;
  isDefaultBackground?: boolean;
}

export type Element =
  | BackgroundableElement
  | DefaultBackgroundElement
  | GifElement
  | LinkableElement
  | MediaElement
  | OverlayableElement
  | SequenceMediaElement
  | StickerElement
  | TextElement
  | VideoElement;
