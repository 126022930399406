/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { v4 as uuidv4 } from 'uuid';

function singleAnimationTarget({ pages, ...rest }) {
  return {
    pages: pages.map(reducePage),
    ...rest,
  };
}

function reducePage({ animations, ...rest }) {
  return {
    animations: (animations || []).reduce(updateAnimation, []),
    ...rest,
  };
}

function updateAnimation(animations, animation) {
  const { targets, id, ...rest } = animation;
  targets.map((target, i) => {
    animations.push({
      id: i === 0 ? id : uuidv4(),
      targets: [target],
      ...rest,
    });
  });
  return animations;
}

export default singleAnimationTarget;
