import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { sprintf } from '@web-stories-wp/i18n';

export function initAppInsights() {
  try {
    initAppInsightsInternal();
  } catch (e) {
    console.error('AppInsights initialization failed.');
  }
}

function initAppInsightsInternal() {
  if (!window?.config?.appInsightsConnectionString) return;

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: window?.config?.appInsightsConnectionString,
    },
  });

  window.appInsights = appInsights;

  appInsights.loadAppInsights();
  appInsights.trackPageView();

  configureInsightsForLogs();

  console.log('AppInsights initialized.');
}

function configureInsightsForLogs() {
  var nativeConsole = window.console;

  window.console = {
    ...nativeConsole,
    error: function (error, ...optionalParams) {
      if (!error) {
        return;
      }

      const ERROR_NAME = 'Studio: Console Error';

      try {
        if (error instanceof Error) {
          trackAppInsightsError(ERROR_NAME, error);
        } else if (typeof error === 'string' || error instanceof String) {
          const formattedMessage = sprintf(error, ...optionalParams);

          if (!error.startsWith('Warning: ')) {
            throw new Error(formattedMessage);
          }

          trackAppInsightsError(ERROR_NAME, new Error(formattedMessage));
          // Errors created by trackError have a description but no message
        } else if (
          'description' in error &&
          typeof error.description === 'string'
        ) {
          trackAppInsightsError(ERROR_NAME, error.description);
        } else {
          trackAppInsightsError(
            'Studio: Unsupported Error Format',
            new Error('Unsupported Error Format'),
            JSON.stringify({ error, optionalParams })
          );
        }
      } catch (formattedError) {
        nativeConsole.error(formattedError.message);
      }
    },
  };
}

export function trackAppInsightsError(name, exception, payload = {}) {
  const queryString = window?.location?.search;
  const urlParams = new URLSearchParams(queryString);

  const tenantName = window?.location?.host?.split('.')?.[0];
  const storyId = urlParams.get('storyId');
  const assetType = urlParams.get('assetType');
  const resourceId = urlParams.get('resourceId');

  const properties = {
    ...(assetType ? { AssetType: assetType } : {}),
    ...(payload ? { Payload: payload } : {}),
    ...(resourceId ? { ResourceId: resourceId } : {}),
    ...(storyId ? { StoryId: storyId } : {}),
    Tenant: tenantName,
  };

  if (!window.appInsights) {
    throw new Error(
      `AppInsights is not initialized. Error: ${name}: ${exception.message}`
    );
  }

  window.appInsights.trackException({
    exception,
    properties: {
      ...properties,
    },
  });

  window.appInsights.trackEvent({
    name,
    properties: {
      ...properties,
      Error: exception.message,
    },
  });
}
