import { Text, THEME_CONSTANTS } from '@web-stories-wp/design-system';
import Dialog from '@web-stories-wp/story-editor/src/components/dialog';

function RetrySaveDialog({ isOpen, onClose, onRetryClick }) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      ariaHideApp={false}
      primaryText="Try again"
      onPrimary={onRetryClick}
      secondaryText="Cancel"
    >
      <Text size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.SMALL}>
        There was a problem saving your changes. Don't worry, they aren't lost.
        Please try again.
      </Text>
    </Dialog>
  );
}

export default RetrySaveDialog;
