/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Helper function to get create a js error.
 *
 * @param {string} name Error name.
 * @param {string} fileName File name.
 * @param {string} message Message in error.
 * @return {Error} Error Object.
 */
function createError(name, fileName, message) {
  const validError = new Error();

  validError.name = name;
  validError.file = fileName;
  validError.isUserError = true;
  validError.message = message;

  return validError;
}

export default createError;
