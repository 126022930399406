import React, { useRef } from 'react';
import './TemplatesListByFolder.css';
import TemplateList from '@src/web-stories-wp/story-editor/src/components/library/panes/pageTemplates/templateList';
import styled from 'styled-components';
import { virtualPaneContainer } from '@src/web-stories-wp/story-editor/src/components/library/panes/shared/virtualizedPanelGrid';
import { SearchInput } from '@src/web-stories-wp/story-editor/src/components/library/common';
import { SearchInputContainer } from '@src/web-stories-wp/story-editor/src/components/library/panes/media/common/styles';
import PropTypes from 'prop-types';
import OptionsListBackButton from '../optionsList/optionsListBackButton/optionsListBackButton';
import { useStudioContext } from '@src/hooks/studioContext';
import { AssetTypes } from '@src/hooks/studioContext/CMSParams';

const PageTemplatesParentContainer = styled.div`
  ${virtualPaneContainer};
  margin-top: 18px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const TemplatesListByFolder = ({
  goBack,
  templates,
  loadTemplates,
  onSearch,
  chosenFolder,
  displayOneFolderOnly,
  shouldDisplayNoTemplateFoundMessage,
}) => {
  const listContainerRef = useRef();
  const { assetType, contentBankAssetType } = useStudioContext();
  let finalAssetType = contentBankAssetType || assetType;

  let containerHeight = 280;
  let height = 280;

  if (
    finalAssetType === AssetTypes.StoryIcon ||
    finalAssetType === AssetTypes.Square
  ) {
    containerHeight = height = 158;
  } else if (
    finalAssetType === AssetTypes.StoryThumbnail ||
    finalAssetType === AssetTypes.ClipThumbnail ||
    finalAssetType === AssetTypes.Print
  ) {
    containerHeight = height = 237;
  } else if (finalAssetType === AssetTypes.Landscape) {
    containerHeight = height = 89;
  } else if (finalAssetType === AssetTypes.Portrait) {
    containerHeight = height = 198;
  }

  return (
    <div className="templatesListByFolder">
      <SearchInputContainer>
        <SearchInput
          initialValue={''}
          placeholder={'Filter Templates'}
          onSearch={onSearch}
          incremental
        />
      </SearchInputContainer>

      {!displayOneFolderOnly && templates.length ? (
        <OptionsListBackButton onClick={goBack}>
          {chosenFolder || 'All Templates'}
        </OptionsListBackButton>
      ) : null}

      {shouldDisplayNoTemplateFoundMessage && (
        <div className="templatesListByFolder__noResults">No results found</div>
      )}

      <PageTemplatesParentContainer ref={listContainerRef}>
        {listContainerRef.current && (
          <TemplateList
            pageSize={{
              width: 158,
              containerHeight: containerHeight,
              height: height,
            }}
            parentRef={listContainerRef}
            pages={templates}
            fetchTemplates={loadTemplates}
          />
        )}
      </PageTemplatesParentContainer>
    </div>
  );
};

TemplatesListByFolder.propTyes = {
  goBack: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadTemplates: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  shouldDisplayNoTemplateFoundMessage: PropTypes.bool.isRequired,
  chosenFolder: PropTypes.string,
};

export default TemplatesListByFolder;
