import {
  getElementWithoutSafeZoneOffset,
  USE_SAFE_AREA,
} from '@src/web-stories-wp/units';
import type { Page } from '@src/web-stories-wp/story-editor/src/elements/types';

export function offsetPageWithSafeZone(page: Page): Page {
  if (page.withAbsolutePositioning || USE_SAFE_AREA) {
    // If we bring back safe areas, we might need to convert pages the other way round
    return page;
  }

  return {
    ...page,
    elements: page.elements.map((element) =>
      getElementWithoutSafeZoneOffset(element)
    ),
    withAbsolutePositioning: true,
  };
}
