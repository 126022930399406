/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from '@web-stories-wp/react';
import { useConfig } from '@src/web-stories-wp/story-editor/src/app';
import { __ } from '@web-stories-wp/i18n';
import { trackEvent } from '@web-stories-wp/tracking';
import { DropDown } from '@web-stories-wp/design-system';

/**
 * Internal dependencies
 */
import useMedia from '../../../../../app/media/useMedia';
import { PaneInner, SearchInputContainer, StyledPane } from '../common/styles';
import { SearchInput } from '../../../common';
import { PROVIDERS } from '../../../../../app/media/media3p/providerConfiguration';
import { ContentType } from '../../../../../app/media/media3p/constants';
import {
  useMediaProviders3pNames,
  ProviderType,
} from '../../../../../app/media';
import { focusStyle } from '../../../../panels/shared';

import paneId from './paneId';
import ProviderTabList from './providerTabList';
import ProviderPanel from './providerPanel';
import { parseSearchTextWithoutType } from './utils/parseSearchTextWithoutType';
import useMediaProvidersFeatures from '@src/web-stories-wp/story-editor/src/app/media/useMediaProvidersFeatures';

const PaneBottom = styled.div`
  position: relative;
  height: 100%;
  flex: 1 1 auto;
  min-height: 0;
  margin-top: 8px;
`;

const DEFAULT_MIMIR_CONTENT_TYPE = 'image,video';
const MIMIR_CONTENT_TYPES = [
  {
    label: __('All Assets', 'web-stories'),
    value: DEFAULT_MIMIR_CONTENT_TYPE,
  },
  {
    label: __('Images', 'web-stories'),
    value: ContentType.IMAGE,
  },
  {
    label: __('Videos', 'web-stories'),
    value: ContentType.VIDEO,
  },
];

/**
 * Pane that contains the media 3P integrations.
 *
 * @param {Object} props Component props
 * @return {*} The media pane element for 3P integrations.
 */
function Media3pPane(props) {
  const { isActive, isTabListEnabled } = props;

  const {
    searchTerm,
    selectedProvider,
    setSelectedProvider,
    setSearchTerm,
    media3p,
  } = useMedia(
    ({
      media3p: {
        state: { selectedProvider, searchTerm },
        actions: { setSelectedProvider, setSearchTerm },
      },
      media3p,
    }) => ({
      searchTerm,
      selectedProvider,
      setSelectedProvider,
      setSearchTerm,
      media3p,
    })
  );

  const { mediaProviders } = useConfig();

  const [selectedMediaType, setSelectedMediaType] = useState(
    DEFAULT_MIMIR_CONTENT_TYPE
  );

  const mediaFeatures = useMediaProvidersFeatures(
    isActive && selectedProvider?.code
  );

  useEffect(() => {
    if (isActive && !selectedProvider) {
      const mediaProvider = mediaProviders.length > 0 ? mediaProviders[0] : {};
      setSelectedProvider({
        ...mediaProvider,
        providerConfigurationKey: mediaProvider?.code.toLowerCase() ?? '',
      });
    }
  }, [isActive, selectedProvider, setSelectedProvider]);

  const selectedCategoryId =
    media3p[selectedProvider?.providerConfigurationKey]?.state?.categories
      ?.selectedCategoryId;
  useEffect(() => {
    trackEvent('search', {
      search_type: 'media3p',
      search_term: searchTerm,
      search_filter: selectedProvider?.providerConfigurationKey,
      search_category: selectedCategoryId,
    });
  }, [selectedProvider, searchTerm, selectedCategoryId]);

  const onSearch = useCallback(
    (value) => {
      const trimText = value.trim();
      if (trimText !== searchTerm) {
        const isSpecificMimirTypeSelected =
          selectedProvider?.providerConfigurationKey === ProviderType.MIMIR &&
          selectedMediaType !== DEFAULT_MIMIR_CONTENT_TYPE;
        // add the selected media type in case of Mimir to the search term
        if (isSpecificMimirTypeSelected) {
          setSearchTerm({
            searchTerm: `${trimText} type:${selectedMediaType}`,
          });
        } else {
          setSearchTerm({ searchTerm: trimText });
        }
      }
    },
    [searchTerm, setSearchTerm, selectedMediaType, selectedProvider]
  );

  const handleInitialValue = useCallback(() => {
    const searchTextWithoutType = parseSearchTextWithoutType(searchTerm);
    setSearchTerm({ searchTerm: searchTextWithoutType });
    return searchTextWithoutType;
  }, [searchTerm, setSearchTerm]);

  const handleMediaTypeSelection = useCallback(
    (_event, value) => {
      if (value !== selectedMediaType) {
        setSelectedMediaType(value);

        const searchTextWithoutType = parseSearchTextWithoutType(searchTerm);
        if (value !== DEFAULT_MIMIR_CONTENT_TYPE) {
          setSearchTerm({
            searchTerm: `${searchTextWithoutType} type:${value}`,
          });
        } else {
          setSearchTerm({ searchTerm: searchTextWithoutType });
        }
      }
    },
    [searchTerm, setSearchTerm, setSelectedMediaType, selectedMediaType]
  );

  const paneBottomRef = useRef();
  const providers = useMediaProviders3pNames();
  const searchRef = useRef();

  useEffect(() => {
    if (!searchTerm) {
      searchRef.current?.setValue('');
    }
  }, [searchTerm]);

  useEffect(() => {
    return () => {
      setSearchTerm({ searchTerm: '' });
    };
  }, []);

  // TODO(#2368): handle pagination / infinite scrolling
  return (
    <>
      <StyledPane id={paneId} {...props}>
        <PaneInner>
          <SearchInputContainer>
            <SearchInput
              initialValue={handleInitialValue}
              placeholder={__('Search', 'web-stories')}
              onSearch={onSearch}
              incremental
              disabled={Boolean(
                selectedProvider?.providerConfigurationKey &&
                  PROVIDERS[selectedProvider?.providerConfigurationKey]
                    .supportsCategories &&
                  media3p[selectedProvider?.providerConfigurationKey]?.state
                    .categories.selectedCategoryId
              )}
              ref={searchRef}
            />
          </SearchInputContainer>
          {selectedProvider?.providerConfigurationKey ===
            ProviderType.MIMIR && (
            <SearchInputContainer>
              <DropDown
                ariaLabel={__('Media type', 'web-stories')}
                options={MIMIR_CONTENT_TYPES}
                selectedValue={selectedMediaType}
                onMenuItemClick={handleMediaTypeSelection}
                selectButtonStylesOverride={focusStyle}
              />
            </SearchInputContainer>
          )}
          {isTabListEnabled && <ProviderTabList providers={providers} />}
          <PaneBottom ref={paneBottomRef}>
            {providers.map((providerType) => (
              <ProviderPanel
                key={providerType}
                providerType={providerType}
                isActive={
                  providerType === selectedProvider?.providerConfigurationKey
                }
                searchTerm={searchTerm}
                role="tabpanel"
                aria-labelledby={`provider-tab-${providerType}`}
                id={`provider-tabpanel-${providerType}`}
                layoutType={mediaFeatures?.layoutType}
                enableDeletion={mediaFeatures?.enableDeletion}
                enableEditInAzzuu={mediaFeatures?.enableEditInAzzuu}
              />
            ))}
          </PaneBottom>
        </PaneInner>
      </StyledPane>
    </>
  );
}

Media3pPane.propTypes = {
  isActive: PropTypes.bool,
};

export default Media3pPane;
