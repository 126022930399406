/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

function getOpaquePattern(pattern) {
  if (!pattern.type || pattern.type === 'solid') {
    const { color } = pattern;
    return {
      color: {
        ...color,
        a: 1,
      },
    };
  }
  return objectWithout(pattern, ['alpha']);
}

export default getOpaquePattern;

function objectWithout(obj, propertiesToRemove) {
  return Object.keys(obj)
    .filter((key) => !propertiesToRemove.includes(key))
    .reduce((newObj, key) => ({ ...newObj, [key]: obj[key] }), {});
}
