/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Floral Separator', 'sticker name', 'web-stories');

function FloralSeparator({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 60 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M25.9499 4.99974C25.9499 5.52058 25.2079 5.62945 25.0518 5.13651H5.13657C6.3072 6.85991 5.30206 7.16193 6.05621 7.7922H5.30152C5.07644 8.46515 5.07533 9.19281 5.30152 9.86849C5.17189 10.0447 4.9104 10.043 4.78185 9.86849C4.98377 9.18898 4.98321 8.46898 4.78185 7.7922H4.02717C4.83978 7.11159 3.81533 6.61154 4.93411 5.13651C4.91095 5.13651 4.88943 5.13213 4.86571 5.11955C3.16107 6.24058 2.85709 5.26398 2.22599 6.00642V5.25742C1.54743 5.03419 0.814269 5.03309 0.132406 5.25742C-0.0452432 5.12885 -0.043024 4.87007 0.132406 4.74258C0.816465 4.94229 1.54246 4.94229 2.22599 4.74258V3.99358C2.9084 4.7951 3.41098 3.79608 4.87785 4.87388C4.90432 4.86294 4.92087 4.86294 4.9468 4.86294C3.77617 3.13954 4.78131 2.83807 4.02717 2.2078H4.78185C5.00693 1.5343 5.00804 0.807194 4.78185 0.131511C4.91148 -0.0451985 5.17297 -0.0424703 5.30152 0.131511C5.0996 0.811022 5.10016 1.53102 5.30152 2.2078H6.05621C5.2436 2.88787 6.26804 3.38847 5.14926 4.86294H25.0518C25.2079 4.37055 25.9499 4.47889 25.9499 4.99974Z"
        fill="#C89D4F"
      />
      <path
        d="M7.32322 3.23333C16.658 5.01604 21.4186 4.47562 24.2949 4.47562C24.6909 4.47562 25.012 4.15718 25.012 3.76438C25.012 3.37157 24.6909 3.05314 24.2949 3.05314C23.8096 3.05314 23.4627 3.52268 23.6125 3.98295C18.4776 4.44662 13.1367 4.10097 7.93729 2.83503C8.21357 2.36331 7.87071 1.76743 7.31728 1.76743C6.71489 1.76743 6.33241 2.49419 6.82573 2.98069C6.98223 3.13512 7.11185 3.19296 7.32322 3.23333Z"
        fill="#C89D4F"
      />
      <path
        d="M24.0078 5.51979C22.9953 5.53882 16.0447 5.00986 7.86057 6.6626C7.40872 6.75385 6.82159 6.7618 6.64033 7.27983C6.49322 7.70026 6.77669 8.23247 7.31725 8.23247C7.86961 8.23247 8.21416 7.63765 7.93725 7.16488C12.858 5.96677 18.1879 5.52713 23.6125 6.01695C23.4625 6.47766 23.81 6.94676 24.2948 6.94676C24.6909 6.94676 25.012 6.62833 25.012 6.23552C25.012 5.99552 24.8861 5.767 24.6826 5.63705C24.483 5.50952 24.2357 5.5155 24.0078 5.51979Z"
        fill="#C89D4F"
      />
      <path
        d="M33.228 4.99974C33.228 5.52058 33.97 5.62945 34.1261 5.13651H54.0413C52.8707 6.85991 53.8758 7.16193 53.1217 7.7922H53.8764C54.1015 8.46515 54.1026 9.19281 53.8764 9.86849C54.006 10.0447 54.2675 10.043 54.3961 9.86849C54.1941 9.18898 54.1947 8.46898 54.3961 7.7922H55.1507C54.3381 7.11159 55.3626 6.61154 54.2438 5.13651C54.2669 5.13651 54.2885 5.13213 54.3122 5.11955C56.0168 6.24058 56.3208 5.26398 56.9519 6.00642V5.25742C57.6305 5.03419 58.3636 5.03309 59.0455 5.25742C59.2231 5.12885 59.2209 4.87007 59.0455 4.74258C58.3614 4.94229 57.6354 4.94229 56.9519 4.74258V3.99358C56.2695 4.7951 55.7669 3.79608 54.3001 4.87388C54.2736 4.86294 54.257 4.86294 54.2311 4.86294C55.4017 3.13954 54.3966 2.83807 55.1507 2.2078H54.3961C54.171 1.5343 54.1699 0.807194 54.3961 0.131511C54.2664 -0.0451985 54.0049 -0.0424703 53.8764 0.131511C54.0783 0.811022 54.0777 1.53102 53.8764 2.2078H53.1217C53.9343 2.88787 52.9099 3.38847 54.0286 4.86294H34.1261C33.97 4.37055 33.228 4.47889 33.228 4.99974Z"
        fill="#C89D4F"
      />
      <path
        d="M51.8546 3.23333C42.5198 5.01604 37.7592 4.47562 34.883 4.47562C34.4869 4.47562 34.1658 4.15718 34.1658 3.76438C34.1658 3.37157 34.4869 3.05314 34.883 3.05314C35.3682 3.05314 35.7151 3.52268 35.5653 3.98295C40.7002 4.44662 46.0412 4.10097 51.2406 2.83503C50.9643 2.36331 51.3071 1.76743 51.8606 1.76743C52.4629 1.76743 52.8455 2.49412 52.3521 2.98069C52.1956 3.13507 52.0661 3.19294 51.8546 3.23333Z"
        fill="#C89D4F"
      />
      <path
        d="M35.17 5.51979C36.1825 5.53882 43.1331 5.00986 51.3172 6.6626C51.7691 6.75385 52.3562 6.7618 52.5375 7.27983C52.6846 7.70028 52.4011 8.23247 51.8606 8.23247C51.3082 8.23247 50.9636 7.63765 51.2406 7.16488C46.3198 5.96677 40.9899 5.52713 35.5653 6.01695C35.7153 6.47766 35.3678 6.94676 34.883 6.94676C34.4869 6.94676 34.1658 6.62833 34.1658 6.23552C34.1658 5.99552 34.2917 5.767 34.4952 5.63705C34.6948 5.50954 34.9421 5.5155 35.17 5.51979Z"
        fill="#C89D4F"
      />
      <path
        d="M30.1368 5.15769L31.818 5.09109L30.1368 5.02449C30.9442 4.78561 31.6944 4.65943 32.3788 4.66307L32.8224 4.04915L32.0997 3.81107C31.5482 4.21295 30.8664 4.54821 30.0716 4.82564L31.3923 3.79167L29.9927 4.71789C30.5043 4.05396 31.0365 3.51453 31.5923 3.11851L31.5874 2.36323L30.8615 2.59196C30.6535 3.2386 30.3007 3.90724 29.8221 4.59498L30.2778 2.98859L29.6944 4.55383C29.7148 3.71845 29.8257 2.97181 30.0406 2.32742L29.589 1.71928L29.1373 2.32742C29.3523 2.97179 29.4631 3.71843 29.4836 4.55383L28.9001 2.98859L29.3558 4.59498C28.8773 3.90722 28.5244 3.23857 28.3164 2.59196L27.5906 2.36323L27.5856 3.11851C28.1414 3.5145 28.6736 4.05394 29.1853 4.71789L27.7856 3.79167L29.1063 4.82564C28.3115 4.54821 27.6298 4.21295 27.0783 3.81107L26.3555 4.04915L26.7992 4.66307C27.4835 4.65943 28.2338 4.78561 29.0412 5.02449L27.3599 5.09109L29.0412 5.15769C28.2337 5.39657 27.4835 5.52275 26.7992 5.51911L26.3555 6.13303L27.0783 6.37111C27.6298 5.96923 28.3115 5.63397 29.1063 5.35654L27.7856 6.39051L29.1853 5.46429C28.6736 6.12822 28.1414 6.66765 27.5856 7.06367L27.5906 7.81895L28.3164 7.59022C28.5244 6.94361 28.8773 6.27496 29.3558 5.5872L28.9001 7.19359L29.4835 5.62837C29.4631 6.46375 29.3523 7.21039 29.1373 7.85478L29.5889 8.46292L30.0406 7.85478C29.8256 7.21039 29.7148 6.46375 29.6943 5.62837L30.2777 7.19361L29.822 5.58722C30.3006 6.27496 30.6535 6.94363 30.8615 7.59024L31.5873 7.81897L31.5923 7.06369C31.0365 6.6677 30.5043 6.12826 29.9926 5.46432L31.3923 6.39053L30.0716 5.35657C30.8664 5.63399 31.5481 5.96925 32.0996 6.37113L32.8224 6.13305L32.3788 5.51913C31.6944 5.52273 30.9442 5.39655 30.1368 5.15769ZM29.5889 5.43321C29.3984 5.43321 29.2439 5.28002 29.2439 5.09107C29.2439 4.90211 29.3984 4.74893 29.5889 4.74893C29.7794 4.74893 29.9339 4.90211 29.9339 5.09107C29.9339 5.28002 29.7794 5.43321 29.5889 5.43321Z"
        fill="#C89D4F"
      />
    </svg>
  );
}

FloralSeparator.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 60 / 10,
  svg: FloralSeparator,
  title,
};
