/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const LOCAL_MEDIA_RESET_FILTERS = 'LOCAL_MEDIA_RESET_FILTERS';
export const LOCAL_MEDIA_SET_SEARCH_TERM = 'LOCAL_MEDIA_SET_SEARCH_TERM';
export const LOCAL_MEDIA_SET_MEDIA_TYPE = 'LOCAL_MEDIA_SET_MEDIA_TYPE';
export const LOCAL_MEDIA_SET_MEDIA = 'LOCAL_MEDIA_SET_MEDIA';
export const LOCAL_MEDIA_PREPEND_MEDIA = 'LOCAL_MEDIA_PREPEND_MEDIA';
export const LOCAL_MEDIA_REMOVE_AUDIO_PROCESSING =
  'LOCAL_MEDIA_REMOVE_AUDIO_PROCESSING';
export const LOCAL_MEDIA_ADD_AUDIO_PROCESSING =
  'LOCAL_MEDIA_ADD_AUDIO_PROCESSING';
export const LOCAL_MEDIA_REMOVE_POSTER_PROCESSING =
  'LOCAL_MEDIA_REMOVE_POSTER_PROCESSING';
export const LOCAL_MEDIA_ADD_POSTER_PROCESSING =
  'LOCAL_MEDIA_ADD_POSTER_PROCESSING';

export const LOCAL_MEDIA_TYPE_ALL = 'LOCAL_MEDIA_TYPE_ALL';
