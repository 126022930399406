/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { __ } from '@web-stories-wp/i18n';
import { useRef, useEffect } from '@web-stories-wp/react';
import STICKERS from '@web-stories-wp/stickers';

/**
 * Internal dependencies
 */
import { useFeatures } from 'flagged';
import { MASKS } from '../../../../masks/constants';
import { Section, SearchInput } from '../../common';
import { Pane } from '../shared';
import useRovingTabIndex from '../../../../utils/useRovingTabIndex';
import ShapePreview from './shapePreview';
import StickerPreview from './stickerPreview';
import paneId from './paneId';
import LoadingPill from '../common/loadingPill';
import { useShapesAndStickers } from '@src/web-stories-wp/story-editor/src/app/shapesAndStickers';
import { CustomStickersSection } from './customStickersSection';
import { CustomShapesSection } from './customShapesSection';
import { SectionContent } from './sectionContent';

const STICKER_TYPES = Object.keys(STICKERS);

function ShapesPane(props) {
  const { showTextAndShapesSearchInput } = useFeatures();
  const {
    actions: { load },
    state: { customShapes, customStickers, isLoading },
  } = useShapesAndStickers();

  const ref = useRef();
  useRovingTabIndex({ ref });

  const stickersRef = useRef(null);
  useRovingTabIndex({ ref: stickersRef });

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Pane id={paneId} {...props} isOverflowScrollable>
      {showTextAndShapesSearchInput && (
        <SearchInput
          initialValue={''}
          placeholder={__('Search', 'web-stories')}
          onSearch={() => {}}
          disabled
        />
      )}

      {isLoading ? (
        <LoadingPill />
      ) : (
        <>
          <CustomShapesSection customShapes={customShapes} />
          <Section
            data-testid="shapes-library-pane"
            title={__('Shapes', 'web-stories')}
          >
            <SectionContent ref={ref}>
              {MASKS.filter((mask) => mask.showInLibrary).map((mask, i) => (
                <ShapePreview mask={mask} key={mask.type} index={i} isPreview />
              ))}
            </SectionContent>
          </Section>
          <CustomStickersSection customStickers={customStickers} />
          <Section
            data-testid="stickers-library-pane"
            title={__('Stickers', 'web-stories')}
          >
            <SectionContent ref={stickersRef}>
              {STICKER_TYPES.map((stickerType, i) => (
                <StickerPreview
                  key={stickerType}
                  index={i}
                  stickerType={stickerType}
                />
              ))}
            </SectionContent>
          </Section>
        </>
      )}
    </Pane>
  );
}

export default ShapesPane;
