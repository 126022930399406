/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Heart', 'sticker name', 'web-stories');

function BeautyHeart({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 62 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M27.9885 51C27.3612 51.7788 27.3611 51.7787 27.3609 51.7785L27.36 51.7778L27.3567 51.7752L27.3439 51.7649L27.2943 51.7248C27.2506 51.6894 27.1861 51.6372 27.102 51.5688C26.9339 51.4321 26.6874 51.231 26.3731 50.9728C25.7445 50.4563 24.8445 49.711 23.7569 48.7942C21.5824 46.9611 18.6549 44.4396 15.6467 41.6889C12.6412 38.9407 9.54187 35.9517 7.03007 33.1851C5.77429 31.8019 4.65477 30.4627 3.76408 29.2281C2.8818 28.0052 2.1864 26.834 1.82516 25.7916C-0.867927 18.0208 3.24427 9.51561 11.0319 6.82644C17.4039 4.61654 24.2738 6.98478 28.0292 12.1671C31.7503 6.9824 38.6214 4.61781 44.9901 6.82621C52.7769 9.51469 56.8923 18.0204 54.1508 25.7943C53.7893 26.8359 53.0944 28.0063 52.2128 29.2281C51.3222 30.4627 50.2026 31.8019 48.9469 33.1851C46.4351 35.9517 43.3358 38.9407 40.3302 41.6889C37.322 44.4396 34.3945 46.9611 32.22 48.7942C31.1324 49.711 30.2324 50.4563 29.6038 50.9728C29.2895 51.231 29.0431 51.4321 28.8749 51.5688C28.7908 51.6372 28.7263 51.6894 28.6826 51.7248L28.633 51.7649L28.6202 51.7752L28.6169 51.7778L28.616 51.7785C28.6158 51.7787 28.6157 51.7788 27.9885 51ZM27.9885 51L27.3612 51.7788L27.9885 52.284L28.6157 51.7788L27.9885 51Z"
        fill="#28292B"
        stroke="#28292B"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M33.9885 46C33.3612 46.7788 33.3611 46.7787 33.3609 46.7785L33.36 46.7778L33.3567 46.7752L33.3439 46.7649L33.2943 46.7248C33.2506 46.6894 33.1861 46.6372 33.102 46.5688C32.9339 46.4321 32.6874 46.231 32.3731 45.9728C31.7445 45.4563 30.8445 44.711 29.7569 43.7942C27.5824 41.9611 24.6549 39.4396 21.6467 36.6889C18.6412 33.9407 15.5419 30.9517 13.0301 28.1851C11.7743 26.8019 10.6548 25.4627 9.76408 24.2281C8.8818 23.0052 8.1864 21.834 7.82516 20.7916C5.13207 13.0208 9.24427 4.51561 17.0319 1.82644C23.4039 -0.383456 30.2738 1.98478 34.0292 7.16706C37.7503 1.9824 44.6214 -0.382192 50.9901 1.82621C58.7769 4.51469 62.8923 13.0204 60.1508 20.7943C59.7893 21.8359 59.0944 23.0063 58.2128 24.2281C57.3222 25.4627 56.2026 26.8019 54.9469 28.1851C52.4351 30.9517 49.3358 33.9407 46.3302 36.6889C43.322 39.4396 40.3945 41.9611 38.22 43.7942C37.1324 44.711 36.2324 45.4563 35.6038 45.9728C35.2895 46.231 35.0431 46.4321 34.8749 46.5688C34.7908 46.6372 34.7263 46.6894 34.6826 46.7248L34.633 46.7649L34.6202 46.7752L34.6169 46.7778L34.616 46.7785C34.6158 46.7787 34.6157 46.7788 33.9885 46ZM33.9885 46L33.3612 46.7788L33.9885 47.284L34.6157 46.7788L33.9885 46Z"
        fill="#28292B"
        stroke="#28292B"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M59.2069 20.4641C56.6942 27.7145 33.9885 46 33.9885 46C33.9885 46 11.2827 27.7145 8.77002 20.4641C6.25732 13.2138 10.0949 5.27944 17.3589 2.77145C23.8006 0.537066 30.7905 3.31865 34.0341 9.01862C37.2321 3.31865 44.222 0.537066 50.6637 2.77145C57.9277 5.27944 61.7653 13.2138 59.2069 20.4641Z"
        fill="#F3D9E1"
      />
    </svg>
  );
}

BeautyHeart.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 62 / 54,
  svg: BeautyHeart,
  title,
};
