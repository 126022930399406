/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import mime from 'mime-types';
import { __ } from '@web-stories-wp/i18n';
import { Icons } from '@web-stories-wp/design-system';
import { memo, useMemo, useCallback } from '@web-stories-wp/react';

/**
 * Internal dependencies
 */
import { getDefinitionForType } from '../../../../elements';
import { useStory } from '../../../../app';
import LayerAction from './layerAction';
import type { Element } from '@web-stories-wp/story-editor/src/elements';

function ElementLayerActions({
  element,
  isBackground,
  isDownloading,
  setIsDownloading,
}: {
  element: Element;
  isBackground: boolean;
  isDownloading: boolean;
  setIsDownloading: (isDownloading: boolean) => void;
}) {
  const { hasDownloadMenu, hasDuplicateMenu } = getDefinitionForType(
    element.type
  );

  const { duplicateElementsById, updateElementById, deleteElementById } =
    useStory(({ actions }) => ({
      duplicateElementsById: actions.duplicateElementsById,
      deleteElementById: actions.deleteElementById,
      updateElementById: actions.updateElementById,
    }));

  const { isLocked, isHidden } = element;

  const downloadTitle = isDownloading
    ? __('Downloading Asset', 'web-stories')
    : __('Download Asset', 'web-stories');

  const lockTitle = useMemo(
    () =>
      isBackground
        ? undefined
        : isLocked
        ? __('Unlock Layer', 'web-stories')
        : __('Lock Layer', 'web-stories'),
    [isBackground, isLocked]
  );
  const visibilityTitle = isHidden
    ? __('Show Layer', 'web-stories')
    : __('Hide Layer', 'web-stories');

  const handleDeleteElement = useCallback(
    () => deleteElementById({ elementId: element.id }),
    [deleteElementById, element.id]
  );

  const handleDuplicateElement = useCallback(
    () => duplicateElementsById({ elementIds: [element.id] }),
    [duplicateElementsById, element.id]
  );

  const handleLockElement = useCallback(() => {
    if (isBackground) {
      return;
    }

    updateElementById({
      elementId: element.id,
      properties: { isLocked: !isLocked },
    });
  }, [element.id, isBackground, isLocked, updateElementById]);

  const handleHideElement = useCallback(
    () =>
      updateElementById({
        elementId: element.id,
        properties: { isHidden: !isHidden },
      }),
    [element.id, isHidden, updateElementById]
  );

  const elementSource = useMemo(
    () => element.resource?.sizes?.full?.source_url || element.resource?.src,
    [element.resource]
  );

  const handleDownloadElement = useCallback(async () => {
    setIsDownloading(true);

    const filename = element.resource?.title || element.type;
    const extension = mime.extension(element.resource?.mimeType);

    // We can't use an anchor tag to download because the files are not on the same origin
    fetch(elementSource).then(function (el) {
      return el.blob().then((blob) => {
        let a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.setAttribute(
          'download',
          `${filename}${filename.endsWith(extension) ? '' : `.${extension}`}`
        );
        a.click();

        setIsDownloading(false);
      });
    });
  }, [element, elementSource]);

  const LockIcon = isLocked || isBackground ? Icons.LockClosed : Icons.LockOpen;
  const VisibilityIcon = isHidden ? Icons.VisibilityOff : Icons.Visibility;

  return (
    <>
      <LayerAction
        isActive={hasDownloadMenu && !!elementSource}
        isLoading={isDownloading}
        label={downloadTitle}
        handleClick={handleDownloadElement}
      >
        <Icons.Download />
      </LayerAction>
      <LayerAction
        isActive={!isBackground}
        label={__('Delete Layer', 'web-stories')}
        handleClick={handleDeleteElement}
      >
        <Icons.Trash />
      </LayerAction>
      <LayerAction
        isActive={!isBackground && hasDuplicateMenu}
        label={__('Duplicate Layer', 'web-stories')}
        handleClick={handleDuplicateElement}
      >
        <Icons.PagePlus />
      </LayerAction>
      <LayerAction
        isActive={!isBackground}
        label={visibilityTitle}
        handleClick={handleHideElement}
      >
        <VisibilityIcon />
      </LayerAction>
      <LayerAction
        disabled={isBackground}
        label={lockTitle}
        handleClick={handleLockElement}
      >
        <LockIcon />
      </LayerAction>
    </>
  );
}

export default memo(ElementLayerActions);
