/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Man Reading', 'sticker name', 'web-stories');

function ManReading({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 58 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M57.1429 40.251C57.1429 45.6352 44.351 50 28.5714 50C12.7919 50 0 45.6352 0 40.251C0 34.8667 12.7919 30.502 28.5714 30.502C44.351 30.502 57.1429 34.8667 57.1429 40.251Z"
        fill="#C9625B"
      />
      <path
        d="M8.76143 32.9323L27.3626 32.6333C27.3626 32.6333 30.2476 33.791 30.2476 36.1576C30.2465 39.8899 27.2207 40.0821 27.2207 40.0821H15.564L8.76143 32.9323Z"
        fill="#4A7F9B"
      />
      <path
        d="M4.33018 33.5208C4.33018 33.5208 5.56761 35.2106 5.56761 36.5264C5.56761 38.3012 4.33018 39.4435 4.33018 39.4435L18.8758 39.3668C18.8758 39.3668 20.5498 39.5053 20.2879 36.2079C20.026 32.9095 18.5388 33.5309 18.5388 33.5309L4.33018 33.5208Z"
        fill="#DDDDDD"
      />
      <path
        opacity="0.32"
        d="M20.2879 36.1394C20.026 32.8098 18.5387 33.4371 18.5387 33.4371L4.33018 33.4269C4.33018 33.4269 4.5596 33.7429 4.82051 34.208L17.0017 34.7626C17.0017 34.7626 19.2758 34.9006 19.5296 36.9187C19.6717 38.0475 19.4829 38.783 19.2666 39.2547C19.7428 39.0682 20.4666 38.4129 20.2879 36.1394Z"
        fill="#DDDDDD"
      />
      <path
        d="M4.25448 33.0083L18.4758 32.917C20.7396 33.3182 20.9533 35.554 20.9766 36.8406C21.0181 39.0533 19.9921 40.1057 18.7959 40.1057L4.33855 40.0781C3.96785 40.0771 3.66805 39.8032 3.66805 39.4665L18.8334 39.3863C19.5424 39.4628 20.2696 38.8025 20.2747 36.4588C20.2777 34.8511 19.5424 33.7037 18.4971 33.5571L3.66805 33.547C3.66704 33.2509 3.92936 33.0111 4.25448 33.0083Z"
        fill="#578CA8"
      />
      <path
        opacity="0.29"
        d="M23.4495 32.7967C23.4495 32.7967 24.5356 34.3577 24.5616 35.9047C24.6183 39.2633 23.7321 40.1059 23.7321 40.1059H26.1998C26.1998 40.1059 27.0882 39.2633 26.919 35.6755C26.834 33.8732 25.8621 32.728 25.8621 32.728L23.4495 32.7967Z"
        fill="#294756"
      />
      <path
        d="M9.05952 27.3642L23.6262 27.2412C23.6262 27.2412 26.2747 27.9393 26.2747 29.7146C26.2738 32.5143 24.5374 32.8768 24.5374 32.8768L15.9054 32.9165L9.05952 27.3642Z"
        fill="#412C47"
      />
      <path
        d="M6.58993 27.9025C6.58993 27.9025 7.54807 29.0797 7.54807 30.0982C7.54807 31.4723 6.30305 32.631 6.30305 32.631L19.8683 32.4248C19.8683 32.4248 21.4792 32.4648 21.2396 29.9107C21.0001 27.3566 19.2964 27.7391 19.2964 27.7391L6.58993 27.9025Z"
        fill="#DDDDDD"
      />
      <g opacity="0.32">
        <path
          opacity="0.32"
          d="M19.3066 28.7005C19.3066 28.7005 21.0029 28.3199 21.248 30.8563C21.2795 30.5889 21.2842 30.2779 21.2498 29.9121C21.0103 27.358 19.3066 27.7405 19.3066 27.7405L6.59922 27.9039C6.59922 27.9039 6.93159 28.3124 7.20455 28.8565L19.3066 28.7005Z"
          fill="#DDDDDD"
        />
      </g>
      <path
        d="M5.83435 27.5021C5.83435 27.5021 18.0237 27.0296 19.5946 27.3564C21.1739 27.685 21.6799 29.0758 21.743 30.0553C21.8572 31.8305 21.1729 32.8109 20.0514 32.9567L5.83342 32.9167V32.4915C5.83342 32.4915 19.8063 32.5185 20.1498 32.381C20.4933 32.2436 21.3642 31.875 21.2398 29.9096C21.1711 28.8224 20.6725 28.0174 19.5714 27.8299C19.0329 27.738 5.83342 27.9125 5.83342 27.9125V27.5021H5.83435Z"
        fill="#9A9FAF"
      />
      <path
        d="M10.0999 38.687C10.7778 38.0248 12.2282 36.6912 12.6065 36.6533L13.1741 36.3223H10.3364C9.87923 36.811 8.9365 37.8924 8.82299 38.3086C8.70949 38.7248 9.78464 37.7568 10.3364 37.2209L10.0999 38.687Z"
        fill="#42A08C"
      />
      <path
        d="M27.1237 39.3944C26.2766 38.6488 26.8066 37.505 27.1775 37.0263C27.8433 37.0248 29.1279 37.1132 28.9402 37.4788C28.7057 37.9357 28.8863 38.9555 29.0188 39.4062C29.1512 39.8569 30.8178 40.9539 31.2089 41.6779C31.5219 42.2571 31.1429 42.1953 30.9142 42.092C30.0037 41.5034 27.9709 40.1399 27.1237 39.3944Z"
        fill="#068A9C"
      />
      <path
        d="M28.5761 3.23926L28.4647 4.54007L29.6665 5.14216L28.5761 3.23926Z"
        fill="#EA2832"
      />
      <path
        d="M27.7133 3.53955L29.4272 5.04731L26.251 4.31396L26.2953 3.90305L27.7133 3.53955Z"
        fill="#DDDDDD"
      />
      <path
        d="M29.2219 5.00628C29.2189 5.00628 29.216 5.0053 29.213 5.00431L26.317 3.90232L26.2767 4.31618C26.2757 4.32995 26.263 4.34174 26.2492 4.33879C26.2354 4.33781 26.2256 4.32503 26.2266 4.31127L26.2738 3.83154L29.2307 4.95614C29.2435 4.96106 29.2504 4.97581 29.2455 4.98859C29.2415 4.9994 29.2317 5.00628 29.2219 5.00628Z"
        fill="#1E1E1E"
      />
      <path
        d="M25.9829 4.25049L29.6899 5.12146L28.2213 10.1635L25.2097 9.49883L25.9829 4.25049Z"
        fill="#FF3031"
      />
      <path
        d="M27.6423 7.3021C27.2058 7.35026 26.2955 11.9666 26.2955 11.9666L28.0827 12.7442C28.0827 12.7442 28.2644 11.6023 28.3646 11.0014L28.5457 9.80394C28.5457 9.80394 29.6595 6.97474 29.1267 6.69654C28.5948 6.41736 27.4093 9.04601 27.4093 9.04601C27.4093 9.04601 28.0778 7.25393 27.6423 7.3021Z"
        fill="#D780A0"
      />
      <path
        d="M28.1476 9.0682C28.1446 9.0682 28.1407 9.06721 28.1377 9.06623C28.1249 9.06131 28.119 9.04657 28.124 9.03379C28.1436 8.98562 28.6086 7.8443 28.8809 7.44715C28.9487 7.34885 28.9674 7.26333 28.9369 7.19255C28.8721 7.04313 28.6056 6.99987 28.6037 6.99987C28.5899 6.99791 28.5801 6.98513 28.583 6.97137C28.585 6.9576 28.5988 6.94777 28.6115 6.95072C28.6233 6.95269 28.9074 6.99791 28.9841 7.17289C29.0215 7.26038 29.0018 7.36163 28.9242 7.47567C28.6558 7.8679 28.177 9.04067 28.1721 9.05246C28.1672 9.06229 28.1584 9.0682 28.1476 9.0682Z"
        fill="#1E1E1E"
      />
      <path
        d="M11.2386 27.5631C11.9997 27.483 12.1566 26.6283 12.14 26.211C11.7727 25.944 11.0283 25.4799 10.9882 25.7604C10.9381 26.1109 10.4374 26.6117 10.187 26.812C9.93659 27.0123 8.58452 26.9622 8.08376 27.2126C7.68315 27.4129 7.91684 27.5297 8.08376 27.5631C8.81821 27.5965 10.4774 27.6432 11.2386 27.5631Z"
        fill="#D780A0"
      />
      <path
        d="M15.2827 15.2032L12.19 26.4745L10.6878 26.0238C11.3572 23.3519 12.5085 14.0747 13.0826 11.965C13.8061 9.30591 14.9455 8.61877 16.9784 8.18328C19.0113 7.7478 21.2733 9.59591 21.8061 10.3224C22.3379 11.0488 24.7533 14.632 24.7533 14.632L26.3617 11.051L28.4148 11.9023C27.8967 13.4663 26.4736 18.9673 25.4473 19.1727C23.9954 19.4637 21.6233 15.2042 21.6233 15.2042C21.8169 16.4143 21.7206 16.8202 21.8169 17.1102C21.8169 17.611 21.8435 20.14 21.8169 21.1575C21.7688 22.9967 16.6855 25.5153 16.1536 24.6905C15.0919 23.0429 15.2827 15.2032 15.2827 15.2032Z"
        fill="#558BBA"
      />
      <path
        d="M24.891 22.3667C24.891 22.3667 31.7664 22.0413 32.5371 22.1878C33.2665 22.3264 34.5464 23.2908 33.8937 25.5144C33.2399 27.739 29.0568 38.3926 29.0568 38.3926L26.8034 37.5914L30.0677 26.5442L21.1888 27.45L24.891 22.3667Z"
        fill="#3F2B45"
      />
      <path
        d="M16.1546 24.6898L21.758 20.5994C21.758 20.5994 27.6415 17.0801 28.9332 18.1555C29.5564 18.6746 30.7725 22.1732 30.7725 22.1732C30.7725 22.1732 27.6872 22.197 27.2684 22.3936C26.3316 22.834 26.5445 25.2411 21.1888 27.4502C20.0268 27.6665 17.1957 27.2644 16.1546 24.6898Z"
        fill="#573C5F"
      />
      <path
        d="M17.8326 6.16534C17.8326 6.16534 16.7011 5.09973 16.6864 3.68316C16.6677 1.84389 17.2487 1.34352 17.9447 0.681935C18.913 -0.238193 22.5315 -0.624531 22.5188 2.15453C22.507 4.93556 20.2371 6.66375 17.8326 6.16534Z"
        fill="#F4A637"
      />
      <path
        d="M16.9772 8.18474L18.1874 5.329L18.4774 4.65168C18.4774 4.65168 17.9445 3.68339 18.5737 3.48973C19.2028 3.29607 18.9128 4.89762 19.3965 5.04213C19.8802 5.18762 21.2671 3.74167 21.6544 2.33789C22.0417 0.934103 21.7676 4.16704 22.1067 4.6507C22.2719 4.88761 22.4036 5.13534 22.4941 5.32703C22.5707 5.49022 22.4606 5.67896 22.2807 5.69076L21.9131 5.71533C21.9131 5.71533 21.3321 7.65193 20.8003 7.74826C20.2675 7.8446 19.8084 7.60277 19.8084 7.60277L19.3965 8.66741C19.3975 8.6684 18.1874 9.78218 16.9772 8.18474Z"
        fill="#D780A0"
      />
      <path
        d="M21.585 4.62003C21.5575 4.74685 21.4867 4.84023 21.4277 4.82745C21.3687 4.81467 21.3441 4.70064 21.3717 4.57383C21.3992 4.44701 21.47 4.35362 21.529 4.3664C21.5879 4.37918 21.6135 4.49322 21.585 4.62003Z"
        fill="#1E1E1E"
      />
      <path
        opacity="0.35"
        d="M18.9445 6.44336C18.9445 6.44336 19.0123 7.30155 19.8086 7.60335L19.5933 8.15975C19.5933 8.15975 18.8167 7.6702 18.9445 6.44336Z"
        fill="#C46255"
      />
    </svg>
  );
}

ManReading.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 58 / 50,
  svg: ManReading,
  title,
};
