import styled from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import { MediaGalleryLoadingPill } from './styles';
import { Text, THEME_CONSTANTS } from '@web-stories-wp/design-system';

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.fg.secondary};
`;

export default function LoadingPill() {
  return (
    <MediaGalleryLoadingPill>
      <StyledText
        forwardedAs="span"
        size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.SMALL}
      >
        {__('Loading…', 'web-stories')}
      </StyledText>
    </MediaGalleryLoadingPill>
  );
}
