import { Text, THEME_CONSTANTS } from '@web-stories-wp/design-system';
import Dialog from '@web-stories-wp/story-editor/src/components/dialog';

function StoryNotFoundDialog({ isOpen, onClose, onRedirectClick }) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      ariaHideApp={false}
      primaryText="Return to the CMS"
      onPrimary={onRedirectClick}
      secondaryText="Cancel"
    >
      <Text size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.SMALL}>
        This Story has been deleted by another user.
      </Text>
    </Dialog>
  );
}

export default StoryNotFoundDialog;
