/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Rounded Banner With Dots', 'sticker name', 'web-stories');

function RoundedBannerWithDots({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 58 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M35.3985 38.5215C35.3971 37.9658 35.3459 37.4102 35.3445 36.8544C35.3443 36.7618 35.3435 36.4376 35.3934 36.4375L35.3437 36.5303C35.1442 36.5771 35.0943 36.5772 34.9444 36.5312C34.8446 36.5315 34.8944 36.485 34.9443 36.4849C34.9942 36.4848 34.8449 36.6704 34.795 36.6705C34.8449 36.6704 34.6455 36.7635 34.6455 36.7635C34.5956 36.7636 34.5457 36.8101 34.4958 36.8102C34.7455 36.8559 35.045 36.8552 35.2947 36.9009C35.3447 36.9471 35.445 37.1321 35.3453 37.1786C35.8434 36.7606 35.7916 35.9734 35.3414 35.5577C34.8411 35.0958 34.0925 35.1439 33.5943 35.562C32.3987 36.5374 32.8032 38.6668 34.4014 39.0334C35.9496 39.3538 37.4432 37.7756 37.39 36.4327C37.3364 34.9045 35.4867 33.7512 33.9913 34.5884C32.4959 35.4257 32.8496 37.231 32.9028 38.5739C32.9063 40.0096 35.4021 40.0035 35.3985 38.5215Z"
        fill="#A7AA2D"
      />
      <path
        d="M30.8894 16.8761C30.2913 17.248 29.5935 17.6665 29.3955 18.3617C29.1476 19.057 29.4489 19.7972 29.9493 20.3055C30.7499 21.0908 32.2485 21.5503 33.3963 21.4549C34.8935 21.3586 35.9885 20.0592 35.5359 18.671C35.0835 17.3753 33.5349 16.8697 32.1875 17.0119C31.5387 17.0598 30.9409 17.5243 30.9425 18.1727C30.9438 18.7284 31.4944 19.3755 32.1931 19.3275C32.3928 19.327 32.6423 19.2801 32.842 19.3259L33.1917 19.4177C33.092 19.4642 33.1912 19.2324 33.1912 19.2324C33.1409 19.0473 33.391 19.2782 33.0912 19.14C32.8913 19.0479 32.3421 19.0029 32.0423 18.8647C31.7924 18.7264 31.742 18.5413 31.7429 18.9117C31.7438 19.2822 31.8431 19.0504 32.1422 18.9108C33.4878 18.0276 32.2351 16.0392 30.8894 16.8761Z"
        fill="#A7AA2D"
      />
      <path
        d="M15.651 23.4323C14.3004 22.2314 14.9803 19.889 16.1401 19.3093C17.3 18.7296 18.6973 18.9903 19.6848 19.5786C21.2343 20.4547 21.1889 22.3073 19.8938 23.422C18.7481 24.3973 16.8016 24.4947 15.651 23.4323Z"
        fill="#A7AA2D"
      />
      <path
        d="M9.16563 12.5292C9.36765 13.5012 9.17221 15.2393 8.43938 15.6963C7.70655 16.1532 7.67912 16.0521 7.29899 16.2301C6.91886 16.408 4.98266 16.6148 5.02898 15.1327C4.97568 13.7435 4.72037 11.3822 6.56599 10.8683C7.81308 10.5411 8.96327 11.4182 9.16563 12.5292Z"
        fill="#A7AA2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9279 29.1922C16.9334 29.1738 16.9384 29.1552 16.9431 29.1363C17.1904 28.2095 16.4397 27.424 15.4413 27.3801C15.0849 27.381 14.7288 27.4648 14.3993 27.6069C13.7993 27.4198 13.2161 27.6546 12.8475 28.1273C12.723 28.2665 12.5611 28.3827 12.3991 28.4988C12.2372 28.615 12.0752 28.7312 11.9508 28.8704C11.4529 29.3811 11.3046 29.9835 11.3562 30.678C11.5091 31.9744 12.4604 33.1762 13.9581 33.2652C14.0649 33.2725 14.1702 33.2624 14.2718 33.2375C14.7057 33.6206 15.2598 33.8172 15.9557 33.631C17.8498 33.1304 17.9407 30.4831 16.9279 29.1922Z"
        fill="#A7AA2D"
      />
      <path
        d="M27.6566 36.3637C27.6572 36.5952 27.7072 36.6414 27.7069 36.5025C27.6575 36.7342 27.6576 36.7805 27.7072 36.6414C27.6078 36.8269 27.608 36.8732 27.6576 36.7805C27.6575 36.7342 27.7075 36.734 27.7073 36.6877C27.5579 36.827 27.558 36.8733 27.6077 36.7806L27.6575 36.7342C27.7073 36.6877 27.7073 36.6877 27.508 36.8272C27.5579 36.827 27.5578 36.7807 27.6077 36.7806C27.8071 36.6412 27.3584 36.8738 27.5579 36.827C27.7076 36.7804 27.6576 36.7805 27.4082 36.8274C27.4581 36.8273 27.508 36.8272 27.508 36.8272C27.4581 36.8273 27.3583 36.8275 27.3082 36.7813C27.4082 36.8274 27.3582 36.7812 27.1584 36.7354C27.0083 36.5968 26.9584 36.5969 27.0584 36.6893C26.9583 36.5506 26.9082 36.5044 26.9584 36.5969C27.0085 36.6894 27.0084 36.6431 26.9081 36.4581C26.8579 36.3193 26.8575 36.1804 26.907 35.995C26.8575 36.1804 27.0561 35.7168 26.9566 35.8559C27.1059 35.6703 27.1557 35.6239 27.0561 35.7168C26.9565 35.8096 27.0063 35.7632 27.2056 35.6238C27.1558 35.6702 27.1059 35.6703 27.0561 35.7168C26.8566 35.8099 27.0561 35.7168 27.1559 35.7165C27.0561 35.7168 27.0063 35.7632 26.9565 35.8096C26.9066 35.8098 26.8566 35.8099 26.8068 35.8563C26.6073 35.9031 27.1562 35.8555 26.9067 35.8561C26.2578 35.8576 25.6101 36.3686 25.6616 37.0169C25.713 37.6188 26.2135 38.1733 26.9123 38.1716C27.6111 38.1699 28.3092 37.8904 28.8074 37.4724C29.6046 36.8684 29.602 35.8032 28.9015 35.1102C28.251 34.4634 27.2027 34.4197 26.405 34.8384C25.6075 35.3035 25.1602 36.0919 25.3121 36.9714C25.4633 37.5731 26.2129 37.9418 26.8614 37.755C27.5099 37.6145 27.808 37.0117 27.6566 36.3637Z"
        fill="#A7AA2D"
      />
      <path
        d="M26.4487 17.5815C26.4479 17.2574 26.4472 16.9795 26.4464 16.6553C26.445 16.0533 25.8945 15.4525 25.1958 15.5005C24.4971 15.5486 23.9491 16.013 23.9507 16.6614C23.9515 16.9856 23.9522 17.2634 23.9529 17.5876C23.9544 18.1897 24.5049 18.7904 25.2036 18.7424C25.9023 18.6944 26.4503 18.2299 26.4487 17.5815Z"
        fill="#A7AA2D"
      />
      <path
        d="M41.3307 20.6019C41.0831 21.3898 41.335 22.3618 42.2343 22.6838C42.5341 22.822 42.8837 22.9137 43.183 22.8204C43.4823 22.727 43.7314 22.5412 43.9305 22.3091C44.03 22.17 44.1793 21.9843 44.2788 21.8452C44.6269 21.3349 44.3755 20.5482 43.8257 20.2717C43.176 19.9491 42.5276 20.1359 42.1296 20.6926C42.0301 20.8318 41.8808 21.0174 41.7813 21.1566C42.3301 21.0163 42.9287 20.8759 43.4774 20.7356C43.3276 20.6897 43.3273 20.5971 43.5275 20.7818C43.4774 20.7356 43.4774 20.7356 43.5775 20.828C43.6775 20.9204 43.7276 20.9666 43.7279 21.1055C43.6775 20.9204 43.7287 21.4297 43.7281 21.1981C43.7282 21.2445 43.7285 21.3371 43.7286 21.3834C43.7285 21.3371 43.7284 21.2908 43.7782 21.2443C43.9765 20.6881 43.5254 19.9482 42.9261 19.8107C42.177 19.6736 41.5289 19.9994 41.3307 20.6019Z"
        fill="#A7AA2D"
      />
      <path
        d="M39.8021 28.2935C40.3028 28.9407 41.0027 29.3558 41.9013 29.3999C42.5503 29.4446 43.2485 29.2114 43.7467 28.7934C44.3445 28.3288 44.5421 27.4947 44.1413 26.8473C43.6902 26.1074 42.7415 25.9708 41.8927 25.8802C41.2437 25.8355 40.5963 26.4391 40.6476 27.0411C40.6992 27.7356 41.1994 28.1512 41.8983 28.1958C41.9982 28.1956 42.198 28.2877 41.8484 28.1959C41.8983 28.1958 41.9482 28.1957 41.9983 28.2419C42.0981 28.2416 42.1981 28.2877 42.298 28.3338C42.4978 28.3796 41.9982 28.1956 42.1481 28.2878C42.1481 28.2878 42.1982 28.334 42.2481 28.3339C42.348 28.38 41.9979 28.1029 42.0981 28.2416C42.1982 28.334 41.8978 27.9643 41.9979 28.1029C42.0981 28.2416 41.8973 27.779 41.8977 27.9179C41.9479 28.0568 41.8468 27.5476 41.8472 27.6865C41.8475 27.8254 41.8962 27.3159 41.8965 27.4548C41.847 27.5939 42.0455 27.1303 41.9959 27.2693C41.8965 27.4548 42.2449 27.0372 42.0955 27.1765C41.8963 27.3622 42.3448 27.0369 42.1952 27.1299C42.1453 27.13 42.0456 27.1766 42.0457 27.2229C42.0457 27.2229 42.3949 27.1294 42.2452 27.1298C42.1952 27.1299 42.1952 27.1299 42.1454 27.1763C42.0955 27.1765 42.0456 27.1766 42.0457 27.2229C41.8961 27.2696 42.395 27.1757 42.2453 27.1761C42.1454 27.1763 42.0955 27.1765 41.9957 27.1767C41.796 27.1772 42.2953 27.2223 42.1955 27.2225C42.1455 27.2227 42.1455 27.2227 42.0956 27.2228C42.0457 27.2229 41.9957 27.1767 41.9957 27.1767C42.2953 27.2223 42.2456 27.315 42.1455 27.2227C42.0956 27.2228 42.0955 27.1765 42.0456 27.1766C41.9457 27.1305 42.2956 27.3612 42.1457 27.269C42.0956 27.2228 42.0956 27.2228 42.0456 27.1766C41.8955 27.038 42.2458 27.4077 42.0957 27.2691C41.8955 27.038 41.6953 26.807 41.3458 26.7615C41.0461 26.6696 40.6469 26.7169 40.3977 26.9027C39.6992 27.0434 39.4016 27.7851 39.8021 28.2935Z"
        fill="#A7AA2D"
      />
      <path
        d="M54.7208 25.8489C54.7703 25.6636 54.7702 25.6173 54.7208 25.8489C54.5714 25.9882 54.4218 26.0349 54.2222 26.0817C53.9225 25.9898 53.6227 25.8516 53.323 25.7597C53.1728 25.5748 53.1728 25.5748 53.2229 25.6673C53.2229 25.6673 53.223 25.7136 53.273 25.7135C53.3231 25.806 53.3231 25.806 53.273 25.7135C53.2228 25.621 53.2228 25.621 53.273 25.7135C53.2731 25.7598 53.3233 25.8987 53.3233 25.8987C53.2232 25.76 53.3228 25.6671 53.3232 25.8523C53.2738 26.084 53.3724 25.5743 53.3231 25.806C53.3233 25.8987 53.174 26.0843 53.3729 25.7596C53.2734 25.8988 53.4725 25.6667 53.5223 25.6203C53.5721 25.5739 53.6718 25.5273 53.7715 25.4808C53.6219 25.5274 53.6718 25.5273 53.8714 25.4805C54.2212 25.6649 54.5711 25.8493 54.9709 26.0336C54.8707 25.8949 54.7205 25.71 54.6203 25.5713C54.2697 25.0627 53.5203 24.7867 52.9222 25.1586C52.3739 25.4841 52.0762 26.2259 52.4768 26.7343C52.577 26.873 52.7271 27.0579 52.8273 27.1966C53.0777 27.5202 53.4777 27.7971 53.9268 27.7497C54.7751 27.655 55.4231 27.2829 55.7208 26.5412C55.9194 26.0776 55.8682 25.5683 55.7671 25.0591C55.5658 24.3649 55.0649 23.6714 54.1666 23.7199C53.2682 23.7684 52.521 24.3723 52.3732 25.2063C52.225 25.8087 52.5761 26.5025 53.2253 26.6399C53.7747 26.7775 54.5725 26.4514 54.7208 25.8489Z"
        fill="#A7AA2D"
      />
      <path
        d="M15.064 15.942C15.8658 17.2368 15.134 18.3816 15.1202 18.5354C15.1064 18.6892 14.5739 19.6945 13.925 19.6961C13.2262 19.6978 12.6759 19.1897 12.6744 18.5413C12.6226 17.8005 12.4709 16.9672 12.7686 16.2255C12.9671 15.7619 13.3158 15.4832 13.8147 15.3893C14.3138 15.3418 14.8134 15.5258 15.064 15.942Z"
        fill="#A7AA2D"
      />
      <path
        d="M8.10198 6.18699C8.05251 6.37236 8.10232 6.32592 8.10198 6.18699C8.10187 6.14068 8.15167 6.09424 8.15156 6.04793C8.20125 5.95519 8.25094 5.86244 8.15144 6.00162C8.20113 5.90888 8.25094 5.86244 8.25071 5.76982C8.3502 5.63064 8.39978 5.49159 8.49927 5.35241C8.54896 5.25966 8.54896 5.25966 8.49927 5.35241C8.54908 5.30598 8.59877 5.21323 8.69848 5.16668C8.39922 5.26003 8.10007 5.39969 7.8008 5.49304C7.95055 5.49268 7.95055 5.49268 7.75089 5.49316C7.90074 5.53911 7.85072 5.49292 7.65094 5.44709C7.40103 5.30877 7.20058 4.98507 7.19991 4.7072C7.24926 4.47552 7.24915 4.42921 7.19946 4.52195C7.29884 4.33646 7.29872 4.29015 7.24903 4.3829C7.19923 4.42933 7.04994 4.61494 7.19934 4.47564C7.34875 4.33634 7.14954 4.52207 7.09974 4.56851C7.14988 4.66101 7.10008 4.70744 7.00036 4.754C6.95056 4.80043 6.90075 4.84686 6.85095 4.8933C6.60182 5.07915 7.05027 4.75388 6.90075 4.84686C6.4025 5.21857 6.05467 5.86778 6.45534 6.42255C6.80587 6.88482 7.6053 7.20706 8.15347 6.83523C9.15008 6.13813 10.5442 4.69907 9.29375 3.63694C8.94356 3.31361 8.44407 3.17588 7.94492 3.1771C7.24611 3.17879 6.84769 3.55026 6.49952 4.06053C6.00183 4.6638 5.70425 5.45182 5.60622 6.19305C5.55777 6.79523 6.20813 7.3957 6.85691 7.34781C7.60553 7.29968 8.05364 6.83547 8.10198 6.18699Z"
        fill="#A7AA2D"
      />
      <path
        d="M16.2094 4.30792C16.2588 4.12255 16.1099 4.4471 16.0601 4.49353C15.8607 4.63295 16.2094 4.30792 16.0601 4.49353C15.8107 4.58676 16.2594 4.35411 16.0601 4.49353C16.2596 4.44673 15.8105 4.49414 16.11 4.49341C15.8602 4.40139 16.2098 4.49317 16.11 4.49341C16.01 4.44734 16.3598 4.58542 16.11 4.49341C16.06 4.44722 16.3598 4.58542 16.11 4.49341C16.16 4.5396 16.16 4.5396 16.0099 4.40103C15.9596 4.2159 15.9096 4.21602 15.9599 4.35484C16.0588 3.9841 16.1578 3.61337 16.2567 3.24263C16.5555 2.96403 16.8052 3.00974 17.0551 3.14807C17.1051 3.19426 17.1051 3.19426 17.0051 3.10188C17.1051 3.19426 17.1052 3.24057 17.055 3.10175C16.7542 2.54674 15.9051 2.36356 15.3569 2.68907C14.7587 3.01471 14.6106 3.70975 14.9115 4.26476C15.513 5.32847 17.0613 5.6952 18.0576 4.85917C18.4063 4.58045 18.505 4.11709 18.4043 3.74684C17.8005 1.7569 14.406 1.67252 13.812 3.71169C13.3662 5.14844 15.8135 5.74455 16.2094 4.30792Z"
        fill="#A7AA2D"
      />
      <path
        d="M26.1805 5.79236C25.8821 6.2562 25.6338 6.76624 25.9846 7.32113C26.1851 7.69114 26.5352 7.96816 26.9349 8.10612C27.7341 8.33574 28.5817 7.96318 28.9295 7.31397C29.1283 6.94299 29.1771 6.47976 28.9267 6.15618C28.476 5.55522 27.9263 5.27869 27.1775 5.23419C26.778 5.18885 26.3789 5.28245 25.9297 5.28354C25.2808 5.28512 24.6331 5.79612 24.6846 6.44436C24.736 7.04629 25.2365 7.60082 25.9353 7.59912C26.2348 7.59839 26.4843 7.55147 26.7838 7.55075C26.8337 7.55062 26.9335 7.55038 26.9834 7.55026C27.1333 7.59621 27.1831 7.54978 26.9834 7.55026C27.0333 7.55014 27.1332 7.5499 27.1333 7.59621C27.0334 7.59645 26.9834 7.55026 26.9334 7.50407C26.8834 7.45788 26.8333 7.41169 26.7833 7.3655C26.7824 6.99501 26.7814 6.5782 26.7805 6.20771C26.7806 6.25402 26.7307 6.25414 26.7308 6.30045C26.88 6.06853 27.0794 5.97542 27.3291 6.02113C27.5785 5.9279 27.7782 5.97372 27.9284 6.15861C28.1786 6.38956 28.2285 6.38944 28.1283 6.25075C28.1292 6.62124 28.1302 7.03804 28.1311 7.40854C28.1808 7.31579 28.2803 7.17661 28.33 7.08387C28.6782 6.57359 28.4766 5.78678 27.8769 5.51037C27.2769 5.09502 26.5286 5.23577 26.1805 5.79236Z"
        fill="#A7AA2D"
      />
      <path
        d="M35.1394 5.04995C35.2391 5.0034 35.3388 4.95684 35.4385 4.91029C35.5882 4.86361 35.5881 4.8173 35.4885 4.91017C35.239 4.95708 35.0392 4.91126 34.8392 4.77281C34.7392 4.68043 34.7391 4.63412 34.7388 4.54149C34.6887 4.44899 34.938 4.35576 34.6889 4.54161C34.7387 4.49518 34.7885 4.44875 34.8882 4.40219C34.9882 4.44826 34.9881 4.40195 34.8383 4.40232C34.9383 4.44839 34.9383 4.44838 34.7883 4.35613C34.9882 4.44826 34.7382 4.26362 34.6881 4.21743C34.438 3.98648 34.2877 3.75529 34.0375 3.52434C33.5872 3.06231 32.7387 3.11069 32.2905 3.52858C31.7925 3.99291 31.8441 4.68746 32.2945 5.14949C32.8449 5.70389 33.2457 6.30497 34.045 6.5809C35.0441 6.90266 36.1415 6.62212 36.7385 5.78706C37.3853 4.95187 37.3327 3.84051 36.5323 3.10147C35.7317 2.31611 34.784 2.59628 33.8866 3.01527C33.2883 3.2946 33.0905 4.08238 33.4411 4.59096C33.792 5.19216 34.5411 5.32928 35.1394 5.04995Z"
        fill="#A7AA2D"
      />
      <path
        d="M43.1315 8.35476C43.7308 8.49224 44.3797 8.49066 44.928 8.16515C45.4264 7.88607 45.7745 7.32948 45.8231 6.77362C45.8219 6.3105 45.6211 5.84787 45.2711 5.57085C44.7709 5.15526 44.1218 5.06421 43.4732 5.20472C42.8247 5.34523 42.427 6.04088 42.6282 6.64244C42.8293 7.24401 43.4791 7.61293 44.1774 7.42598C44.377 7.37918 43.828 7.42683 44.0277 7.42635C44.2273 7.42586 43.7281 7.38076 43.8279 7.38052C44.0277 7.42634 43.5281 7.24231 43.6781 7.33457C43.778 7.38064 43.8281 7.47314 43.5281 7.24231C43.6281 7.33469 43.6284 7.42732 43.4279 7.10362C43.4781 7.19612 43.4783 7.28874 43.3776 6.91849C43.3777 6.96481 43.3777 6.96481 43.3778 7.01112C43.3272 6.73337 43.3271 6.68706 43.3273 6.77968C43.3275 6.8723 43.3274 6.82599 43.3271 6.68706C43.3764 6.45538 43.3267 6.54812 43.327 6.64075C43.2773 6.73349 43.5258 6.31608 43.3765 6.50169C43.3765 6.50169 43.6254 6.22321 43.476 6.36251C43.476 6.36251 43.7751 6.17654 43.6256 6.26953C43.476 6.36251 43.9746 6.12974 43.7752 6.22285C43.5758 6.31596 43.8749 6.1763 43.9249 6.22249L43.7752 6.22285C43.5755 6.22333 44.1247 6.26831 43.8251 6.22273C43.1759 6.08537 42.4279 6.41137 42.2796 7.01379C42.0812 7.5237 42.4323 8.21752 43.1315 8.35476Z"
        fill="#A7AA2D"
      />
      <path
        d="M53.6261 6.02084C53.7258 5.97428 53.8255 5.92773 53.9752 5.88105L54.075 5.88081C54.2246 5.83413 54.1747 5.83426 53.9752 5.88105C53.8255 5.92773 53.7256 5.88166 53.6256 5.83559C53.5754 5.69678 53.4759 5.83595 53.6256 5.83559C53.6254 5.74297 53.8261 6.15929 53.8262 6.2056C53.7761 6.1131 53.7757 5.97416 53.8753 5.88129C53.9251 5.83486 53.9749 5.78843 54.0247 5.742C54.1243 5.64913 53.8254 5.88142 54.0247 5.742C53.9749 5.78843 54.2244 5.74151 53.9748 5.74212C53.9249 5.74224 53.6253 5.69666 53.8251 5.74248C53.9748 5.74212 53.6253 5.69666 53.6752 5.69653C53.4754 5.65071 53.7252 5.74272 53.5754 5.69678C53.4754 5.65071 53.4254 5.60452 53.3255 5.55845C53.4754 5.65071 53.3754 5.55833 53.2754 5.51226C53.3257 5.65107 53.1754 5.41988 53.2754 5.51226C53.3255 5.55845 53.3263 5.88263 53.3757 5.69726C53.4241 5.04878 52.7739 4.54093 52.125 4.5425C51.4262 4.5442 50.9283 5.05484 50.88 5.70333C50.785 7.69497 53.9821 8.75237 55.5268 7.63713C56.7225 6.70799 56.4195 5.27306 55.4687 4.25651C54.5679 3.33246 53.5204 3.61288 52.4733 4.07854C51.875 4.35786 51.7272 5.14552 52.0279 5.65422C52.2787 6.16305 52.9778 6.25397 53.6261 6.02084Z"
        fill="#A7AA2D"
      />
      <path
        d="M53.2553 18.4426C53.2068 18.9985 53.2582 19.6004 53.559 20.1554C53.7596 20.5254 54.0596 20.7563 54.4094 20.8943C54.9589 21.0783 55.4579 21.0307 55.9565 20.798C56.7543 20.4718 57.3017 19.7758 57.3496 18.9421C57.3971 17.9231 56.497 17.2769 55.4987 17.2794C54.8498 17.2809 54.2021 17.7919 54.2536 18.4402C54.305 19.0421 54.8055 19.5966 55.5043 19.5949C55.5542 19.5948 55.7539 19.5943 55.5542 19.5948C55.3046 19.5491 55.6542 19.6409 55.4044 19.5489C55.3044 19.5028 55.2545 19.5029 55.3044 19.5028C55.4044 19.5489 55.3544 19.5027 55.1544 19.3642C55.1544 19.3642 54.954 19.0869 55.0542 19.2255C55.0542 19.2255 54.9037 18.9017 54.9539 19.0405C54.8533 18.7166 54.9532 18.7627 54.9035 18.8554C54.9038 18.948 55.0027 18.5773 54.953 18.67C54.953 18.67 54.9532 18.7627 54.9033 18.7628C54.9033 18.7628 55.0524 18.4846 54.9529 18.6237C54.953 18.67 54.9031 18.6702 54.9032 18.7165C54.8535 18.8092 55.0525 18.5309 55.0027 18.5773C54.9528 18.5774 54.9529 18.6237 54.9031 18.6702C54.8036 18.8093 55.0526 18.5772 55.0526 18.5772C55.0527 18.6235 54.953 18.67 54.9031 18.6702C54.7037 18.7633 54.8532 18.6703 54.9031 18.6702C54.9031 18.6702 55.2524 18.623 55.1026 18.6234C54.9529 18.6237 55.3523 18.6691 55.3024 18.6692C55.5522 18.7612 55.6022 18.8074 55.5023 18.7613C55.4523 18.7151 55.5023 18.7613 55.6022 18.8074C55.8022 18.9459 55.7023 18.8998 55.6523 18.8536C55.5522 18.7612 55.8526 19.131 55.7524 18.9923C55.7022 18.8535 55.8527 19.1773 55.8028 19.1774L55.7524 18.9923C55.7524 18.9923 55.7023 18.8998 55.7522 18.8997C55.7522 18.8997 55.8029 19.2237 55.8026 19.0848C55.8024 18.9922 55.8022 18.9459 55.802 18.8532C55.8017 18.7143 55.8013 18.5754 55.801 18.4364C55.8495 17.8343 55.1991 17.2338 54.5503 17.2817C53.8016 17.2835 53.2538 17.7943 53.2553 18.4426Z"
        fill="#A7AA2D"
      />
      <path
        d="M46.6598 36.2425C47.3101 36.843 48.2091 37.0261 49.0568 36.6998C49.5056 36.5135 49.7543 36.1424 50.003 35.7713C50.1026 35.6784 50.1521 35.5394 50.2516 35.4002C50.4505 35.0755 50.4497 34.7513 50.3989 34.381C50.0957 32.8534 47.9987 32.6269 47.0029 33.6482C46.5548 34.1124 46.5066 34.8535 47.0068 35.2691C47.507 35.6847 48.3057 35.7291 48.7538 35.2649L48.8534 35.172C48.8534 35.172 48.6042 35.3116 48.8036 35.2185C48.7537 35.2186 48.5543 35.3117 48.7039 35.265C48.654 35.2651 48.5542 35.2654 48.5043 35.2655C48.6041 35.2653 48.6041 35.2653 48.4543 35.2656C48.2546 35.2198 48.5044 35.3118 48.3045 35.2197C48.2545 35.1735 48.0546 35.0813 48.1045 35.0812C48.1545 35.1274 48.0043 34.9425 47.9543 34.8963C47.9042 34.8038 47.9042 34.8038 47.9043 34.8501C47.9045 34.9428 47.9044 34.8965 47.9039 34.7112C47.9534 34.5258 48.0029 34.3405 48.0522 34.1088C47.9527 34.248 47.9031 34.387 47.8037 34.5262C47.7538 34.5726 47.704 34.6191 47.7043 34.7117C47.6545 34.7581 47.5052 34.9437 47.6545 34.7581C47.8539 34.665 48.0034 34.572 48.2029 34.4789C48.1531 34.5254 48.1031 34.5255 48.0033 34.5257C48.103 34.4792 48.2029 34.4789 48.3028 34.525C48.1529 34.479 48.2029 34.4789 48.3527 34.5249C48.4528 34.6173 48.4528 34.6173 48.3527 34.5249C47.9025 34.1092 47.0538 34.0649 46.6057 34.5291C46.2077 35.0858 46.1595 35.7806 46.6598 36.2425Z"
        fill="#A7AA2D"
      />
      <path
        d="M4.65044 23.655C4.40289 24.4892 2.93826 25.1792 2.75422 25.2686C2.57019 25.358 2.10595 25.3866 1.85924 25.329C1.61254 25.2714 0.899146 24.4255 0.848227 24.0341C0.797309 23.6427 0.685828 22.5968 1.034 22.0865C1.58104 21.2516 2.59795 21.2054 3.54724 21.5736C4.4465 21.8956 4.94779 22.7743 4.65044 23.655Z"
        fill="#A7AA2D"
      />
      <path
        d="M19.8092 6.81142C17.7314 7.42931 16.0902 9.1172 15.1566 11.0613C14.2231 13.0054 13.937 15.2358 13.922 17.4059C13.9069 20.1487 14.3436 22.9368 15.5331 25.4083C17.5356 29.5677 21.5106 32.4914 25.7567 34.3149C30.2888 36.259 35.5587 37.0728 40.1209 35.2191C43.0118 34.0436 45.436 31.8283 47.1525 29.206C49.3809 25.7851 50.4348 21.5653 49.8025 17.5416C49.1701 13.5178 46.7309 9.73508 43.1925 7.73072C41.2953 6.66072 39.1573 6.08805 37.0041 5.72636C31.0717 4.6865 25.5459 5.03312 19.8092 6.81142Z"
        fill="#4A6747"
      />
    </svg>
  );
}

RoundedBannerWithDots.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 58 / 40,
  svg: RoundedBannerWithDots,
  title,
};
