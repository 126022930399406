import { useEffect, useState } from 'react';
import useCmsApi from './api/useCmsApi';

const useFetchUser = () => {
  const { userApi } = useCmsApi();
  const [user, setUser] = useState(null);

  useEffect(() => {
    userApi.get().then(({ payload: user }) => {
      setUser(user);
    });
  }, [userApi]);

  return { user };
};

export default useFetchUser;
