/**
 * External dependencies
 */
import { Page } from '@web-stories-wp/story-editor/src/elements/types';

const TYPES_WITH_STRICT_COMPARISON = [
  'string',
  'number',
  'boolean',
  'bigint',
  'undefined',
];

export function wasPageChanged(originalPage: Page, newPage: Page) {
  return !isEqual(originalPage, newPage);
}

function isEqual(oldValue: unknown, newValue: unknown) {
  const newValueType = typeof newValue;
  const oldValueType = typeof oldValue;

  if (newValueType !== oldValueType) {
    return false;
  }

  if (TYPES_WITH_STRICT_COMPARISON.includes(newValueType)) {
    return oldValue === newValue;
  }

  if (Array.isArray(oldValue) && Array.isArray(newValue)) {
    const newValueArrayType = typeof newValue[0];
    const oldValueArrayType = typeof oldValue[0];

    if (
      oldValueArrayType !== newValueArrayType ||
      oldValue.length !== newValue.length
    ) {
      return false;
    }

    let areArrayValuesTheSame = true;

    for (
      let newValueIndex = 0;
      newValueIndex < newValue.length;
      newValueIndex++
    ) {
      const newValueToCompare = newValue[newValueIndex];
      const oldValueToCompare = oldValue[newValueIndex];

      if (!isEqual(oldValueToCompare, newValueToCompare)) {
        areArrayValuesTheSame = false;
        break;
      }
    }

    return areArrayValuesTheSame;
  }

  if (oldValue === null && newValue === null) {
    return true;
  }

  if (oldValue === null || newValue === null) {
    return false;
  }

  if (typeof oldValue === 'object' && typeof newValue === 'object') {
    const newValueKeys = Object.keys(newValue);
    const oldValueKeys = Object.keys(oldValue);

    if (newValueKeys.length !== oldValueKeys.length) {
      return false;
    }

    let areNestedValuesTheSame = true;

    for (
      let newValueKeyIndex = 0;
      newValueKeyIndex < newValueKeys.length;
      newValueKeyIndex++
    ) {
      const key = newValueKeys[newValueKeyIndex];
      const newValueToCompare = newValue[key];
      const oldValueToCompare = oldValue[key];

      if (key === 'isChanged') {
        continue;
      }

      if (!isEqual(oldValueToCompare, newValueToCompare)) {
        areNestedValuesTheSame = false;
        break;
      }
    }

    return areNestedValuesTheSame;
  }

  return false;
}
