/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useCallback, useState } from '@web-stories-wp/react';
import { useConfig } from '../../config';
import getStoryPropsToSave from '../utils/getStoryPropsToSave';

/**
 * Internal dependencies
 */
import { useAPI } from '../../api';

function useGeneratePages({
  storyId,
  story,
  pages,
  saveStory,
  deletedPagesIds,
}) {
  const {
    actions: { savePages },
  } = useAPI();
  const [isGeneratingPages, setIsGeneratingPages] = useState(false);
  const { metadata } = useConfig();

  const generatePages = useCallback(
    ({ addNewPagesAtTheEnd }) => {
      setIsGeneratingPages(true);
      const storyToSave = {
        storyId,
        ...getStoryPropsToSave({ story, pages, metadata }),
      };

      return saveStory()
        .then(() =>
          savePages({
            storyToSave,
            story,
            deletedPagesIds,
            addNewPagesAtTheEnd,
          })
        )
        .finally(() => setIsGeneratingPages(false));
    },
    [pages, storyId, story, metadata, deletedPagesIds]
  );

  return { generatePages, isGeneratingPages };
}

export default useGeneratePages;
