/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import styled from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import { Icons, PLACEMENT, Popup } from '@web-stories-wp/design-system';
import { useState, useCallback, useRef } from '@web-stories-wp/react';

/**
 * Internal dependencies
 */
import { Z_INDEX } from '../../../../canvas/layout';
import { ToggleButton } from '../../../../toggleButton';
import { useEffect } from 'react';

const StyledToggleButton = styled(ToggleButton).attrs(
  ({ theme, isTransparent }) => ({
    style: {
      backgroundColor: isTransparent
        ? `transparent`
        : `${theme.colors.bg.tertiary}`,
    },
  })
)`
  backgroundcolor: transparent;
  padding: 4px;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  border: none;
  opacity: 0.65;
`;

const Wrapper = styled.div`
  /**
    * sibling inherits parent z-index of Z_INDEX.EDIT
    * so this needs to be placed above that while still
    * retaining its position in the DOM for focus purposes
    */
  z-index: ${Z_INDEX.EDIT + 1};
`;

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borders.radius.small};
  background-color: ${({ theme }) => theme.colors.bg.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.defaultNormal};
  margin-top: 4px;
`;

const EditButton = styled.button`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.fg.primary};
  font-size: 14px;
  cursor: pointer;
  padding: 4px 14px 4px 6px;
  width: 100%;
  text-align: left;
  border-radius: ${({ theme }) => theme.borders.radius.small};

  &:hover {
    background-color: ${({ theme }) => theme.colors.bg.secondary};
  }
`;

const EllipsisIcon = styled(Icons.Ellipsis)`
  width: auto;
  height: 10px;
  display: block;
`;

const AzzuuIcon = styled(Icons.Azzuu)`
  width: 32px;
  height: 32px;
`;

const TrashIcon = styled(Icons.Trash)`
  width: 32px;
  height: 32px;
`;

const PencilIcon = styled(Icons.Pencil)`
  width: 32px;
  height: 32px;
`;

function MediaEditMenu({
  className,
  isOpen,
  isTransparent,
  handleClose,
  handleOpen,
  handleOnDelete,
  handleOnEdit,
  handleOnEditInAzzuu,
}) {
  const anchorRef = useRef();
  const wrapperRef = useRef();

  const handleAzzuuEdit = useCallback(
    (event) => {
      event.stopPropagation();
      handleOnEditInAzzuu();
      handleClose();
    },
    [handleClose, handleOnEditInAzzuu]
  );

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();
      handleOnDelete();
      handleClose();
    },
    [handleClose, handleOnDelete]
  );

  const handleEdit = useCallback(
    (event) => {
      event.stopPropagation();
      handleOnEdit();
      handleClose();
    },
    [handleClose, handleOnEdit]
  );

  // Close the menu when clicking outside of it
  useEffect(() => {
    const closeOnOutsideClick = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('click', closeOnOutsideClick);
    return () => {
      document.removeEventListener('click', closeOnOutsideClick);
    };
  }, [handleClose]);

  const toggle = useCallback(() => {
    if (isOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  }, [isOpen, handleOpen, handleClose]);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <Popup
        anchor={anchorRef}
        placement={PLACEMENT.BOTTOM_END}
        isOpen={isOpen}
      >
        <Container>
          {handleOnEditInAzzuu && (
            <EditButton onClick={handleAzzuuEdit}>
              <AzzuuIcon /> Edit In Azzuu
            </EditButton>
          )}
          {handleOnEdit && (
            <EditButton onClick={handleEdit}>
              <PencilIcon /> Edit
            </EditButton>
          )}
          {handleOnDelete && (
            <EditButton onClick={handleDelete}>
              <TrashIcon /> Delete
            </EditButton>
          )}
        </Container>
      </Popup>

      <StyledToggleButton
        ref={anchorRef}
        isOpen={isOpen}
        isTransparent={isTransparent}
        aria-owns="media_edit_menu"
        onClick={(event) => {
          event.stopPropagation();
          toggle();
        }}
        aria-label={__('More', 'web-stories')}
        label={isOpen ? undefined : __('More', 'web-stories')}
        MainIcon={EllipsisIcon}
      />
    </Wrapper>
  );
}

export default MediaEditMenu;
