import { useMemo } from '@web-stories-wp/react';
import { useFeature } from 'flagged';
import useLibrary from '../../../useLibrary';
import useIsStory from '@src/hooks/useIsStory';

export function useTextSets() {
  const { areTextSetsLoading, textSets } = useLibrary(
    ({ state: { areTextSetsLoading, textSets } }) => ({
      areTextSetsLoading,
      textSets,
    })
  );

  const isStory = useIsStory();

  const useDefaultStudioTextSets = useFeature('useDefaultStudioTextSets');

  const filteredTextSets = useMemo(() => {
    if (useDefaultStudioTextSets && isStory) {
      return textSets;
    }

    if (textSets?.Custom) {
      return { Custom: textSets.Custom };
    }

    return [];
  }, [useDefaultStudioTextSets, textSets]);

  return { textSets: filteredTextSets, areTextSetsLoading };
}
