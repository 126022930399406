export default function createResourceFromExternalMedia(asset) {
  const isVideo = asset.metadata?.filetype?.includes('video');
  return {
    id: asset.id,
    type: isVideo ? 'video' : 'image',
    src: asset.url,
    poster: asset.thumbnailUrl,
    posterId: 1,//Just to prevent poster upload cause we have posters for external assets
    //TODO: probably should be returned from server
    width: parseInt(asset.metadata.width),
    height: parseInt(asset.metadata.height),
    alt: asset.title,
    mimeType: asset.metadata?.filetype,
    length: asset.metadata?.duration,
    local: false,
    isMuted: false,
    isTranscoding: false,
    isMuting: false,
    isTrimming: false,
    isExternal: true,
    isOptimized: true,
  };
}
