/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary',
  PLAIN: 'plain',
};

export const BUTTON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

export const BUTTON_VARIANTS = {
  CIRCLE: 'circle',
  RECTANGLE: 'rectangle',
  SQUARE: 'square',
  ICON: 'icon',
  LINK: 'link',
};

export const BUTTON_TRANSITION_TIMING = '0.3s ease 0s';
