import PropTypes from 'prop-types';
import Context from './context';
import { useAPI } from '../api';
import { useCallback, useState, useMemo } from 'react';

function ShapesAndStickersProvider({ children }) {
  const {
    actions: { getShapes, getStickers },
  } = useAPI();

  const [state, setState] = useState({
    customShapes: [],
    customStickers: [],
    isInitDataLoaded: false,
    isLoading: false,
  });

  const load = useCallback(async () => {
    if (state.isInitDataLoaded || state.isLoading) {
      return;
    }

    setState((currentState) => ({ ...currentState, isLoading: true }));

    const [shapes, stickers] = await Promise.all([getShapes(), getStickers()])

    setState((currentState) => ({
      ...currentState,
      customShapes: shapes.map((shape) => ({ ...shape, isCustom: true })),
      customStickers: stickers,
      isLoading: false,
      isInitDataLoaded: true,
    }));
  }, [getShapes, getStickers, state.isLoading, state.isInitDataLoaded]);

  const context = useMemo(
    () => ({
      state,
      actions: { load },
    }),
    [load, state]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

ShapesAndStickersProvider.propTypes = {
  children: PropTypes.node,
};

export default ShapesAndStickersProvider;
