/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import PropTypes from 'prop-types';
import { useCallback } from '@web-stories-wp/react';
import { __ } from '@web-stories-wp/i18n';
import { trackError } from '@web-stories-wp/tracking';
import {
  Text,
  THEME_CONSTANTS,
  useSnackbar,
} from '@web-stories-wp/design-system';

/**
 * Internal dependencies
 */
import { useAPI } from '../../../../../app/api';
import { useLocalMedia } from '../../../../../app/media';
import { useStory } from '../../../../../app/story';
import Dialog from '../../../../dialog';

/**
 * Display a confirmation dialog for when a user wants to delete a media element.
 *
 * @param {Object} props Component props.
 * @param {boolean} props.isOpen
 * @param {number} props.mediaId Selected media element's ID.
 * @param {string} props.type Selected media element's type.
 * @param {Function} props.onClose Callback to toggle dialog display on close.
 * @param {Function|undefined} props.handleOnDelete Function to delete the media element.
 * @return {null|*} The dialog element.
 */
function DeleteDialog({
  isOpen = true,
  mediaId,
  type,
  onClose,
  handleOnDelete,
}) {
  const {
    actions: { deleteMedia },
  } = useAPI();
  const { showSnackbar } = useSnackbar();
  const { deleteMediaElement } = useLocalMedia((state) => ({
    deleteMediaElement: state.actions.deleteMediaElement,
  }));
  const { deleteElementsByResourceId } = useStory((state) => ({
    deleteElementsByResourceId: state.actions.deleteElementsByResourceId,
  }));

  const onDelete = useCallback(async () => {
    if (handleOnDelete) {
      handleOnDelete();
      onClose();
      return;
    }

    try {
      await deleteMedia(mediaId);
      deleteMediaElement({ id: mediaId });
      deleteElementsByResourceId({ id: mediaId });
      onClose();
    } catch (err) {
      trackError('local_media_deletion', err.message);
      showSnackbar({
        message: __('Failed to delete media item.', 'web-stories'),
        dismissable: true,
      });
    }
  }, [
    deleteMedia,
    deleteMediaElement,
    deleteElementsByResourceId,
    handleOnDelete,
    mediaId,
    onClose,
    showSnackbar,
  ]);

  const imageDialogTitle = __('Delete Image?', 'web-stories');
  const videoDialogTitle = __('Delete Video?', 'web-stories');
  const imageDialogDescription = __(
    'Are you sure you want to delete this image?',
    'web-stories'
  );
  const videoDialogDescription = __(
    'Are you sure you want to delete this video?',
    'web-stories'
  );

  // Keep icon and menu displayed if menu is open (even if user's mouse leaves the area).
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={type === 'image' ? imageDialogTitle : videoDialogTitle}
      secondaryText={__('Cancel', 'web-stories')}
      onPrimary={onDelete}
      primaryText={__('Yes, Delete', 'web-stories')}
      maxWidth={512}
    >
      <Text size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.SMALL}>
        {type === 'image' ? imageDialogDescription : videoDialogDescription}
      </Text>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  isOpen: PropTypes.bool,
  mediaId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteDialog;
