import { useEffect, useRef } from 'react';
import { setAppElement } from '@web-stories-wp/design-system';
import { LoadingIndicator } from '@src/components/loading';
import { useStoryEditor } from '@hooks';

import './Studio.css';

export const Studio = () => {
  const containerRef = useRef(null);
  const { isLoading, storyEditor } = useStoryEditor();

  useEffect(() => {
    setAppElement(containerRef.current);
  }, []);

  return (
    <div
      ref={containerRef}
      id="web-stories-editor"
      className="studio-container web-stories-editor-app-container"
    >
      {isLoading ? <LoadingIndicator /> : storyEditor}
    </div>
  );
};
