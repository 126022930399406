import convertMsToReadableFormat from '../helpers/convertMsToReadableFormat';
import * as types from './types';

export const MAX_ITEMS_TO_FETCH_COUNT = 30;

const createGreenflyTitle = (item) => {
  if (item.metadata.capturedDate || item.creationTime) {
    return `Imported from Greenfly - ${
      item.metadata.capturedDate
        ? item.metadata.capturedDate
        : item.creationTime
    }`;
  } else return 'Imported from Greenfly';
};

const getUpdatedItems = (items) =>
  items.map((item) => {
    return {
      ...item,
      title: item.title ?? item.metadata?.title,
      thumbnailUrl: item.playcardUrl ?? item.url,
      videoUrl: item.url,
      ratio: item.metadata?.height / item.metadata?.width,
      displayDuration: convertMsToReadableFormat(item.metadata?.duration ?? 0),
    };
  });

export const INITIAL_STATE = {
  mediaType: {},
  assets: [],
  isLoading: false,
  isInitDataLoaded: false,
  preview: {
    videoInPreviewId: null,
    muted: true,
    paused: false,
  },
  searchTerm: '',
  skipCount: 0,
  maxResultsCount: MAX_ITEMS_TO_FETCH_COUNT,
  hasMore: null,
  filters: {},
  hasError: false,
};

export default function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case types.MEDIA_TYPE_CHANGED: {
      return {
        ...state,
        mediaType: payload,
        searchTerm: '',
        skipCount: 0,
        hasMore: null,
        assets: [],
        filters: { provider: payload },
        isInitDataLoaded: false,
      };
    }
    case types.MEDIA_TYPE_ASSETS_REFRESHED: {
      return {
        ...INITIAL_STATE,
        isLoading: true,
        mediaType: state.mediaType,
        filters: { provider: state.mediaType },
      };
    }
    case types.MEDIA_TYPE_ASSET_DELETED: {
      return {
        ...state,
        isLoading: false,
        assets: state.assets.filter((asset) => asset.id !== payload),
      };
    }
    case types.MEDIA_TYPE_ASSETS_FETCH_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.MEDIA_TYPE_ASSETS_FETCH_SUCCESS: {
      const {
        assets: { items, totalCount },
      } = payload;
      const loadedAssetsCount = state.assets.length + items.length;
      const newAssets = [...state.assets, ...getUpdatedItems(items)];
      return {
        ...state,
        isLoading: false,
        assets: newAssets,
        skipCount: state.skipCount + state.maxResultsCount,
        hasMore: loadedAssetsCount < totalCount,
        hasError: false,
        isInitDataLoaded: true,
      };
    }
    case types.MEDIA_TYPE_ASSETS_FETCH_FAILED: {
      return {
        ...state,
        hasError: true,
        isInitDataLoaded:
          state.assets.length === 0 ? false : true,
      };
    }
    case types.MEDIA_TYPE_ASSETS_FETCH_END: {
      return {
        ...state,
        isLoading: false,
        isInitDataLoaded:
          state.hasError && state.assets.length === 0 ? false : true,
      };
    }
    case types.MEDIA_TYPE_SEARCH_TERM_CHANGED: {
      return {
        ...state,
        searchTerm: payload,
        assets: [],
        skipCount: 0,
      };
    }
    case types.MEDIA_TYPE_FILTERS_CHANGED: {
      return {
        ...state,
        filters: payload,
        assets: [],
        skipCount: 0,
      };
    }
    case types.MEDIA_TYPE_SET_VIDEO_AS_IN_PREVIEW: {
      const { videoId } = payload;
      return {
        ...state,
        preview: {
          ...state.preview,
          videoInPreviewId: videoId,
          paused: false,
        },
      };
    }
    case types.MEDIA_TYPE_SET_PREVIEW_MUTE_STATE: {
      return {
        ...state,
        preview: {
          ...state.preview,
          muted: payload,
        },
      };
    }
    case types.MEDIA_TYPE_SET_PREVIEW_PAUSE_STATE: {
      return {
        ...state,
        preview: {
          ...state.preview,
          paused: payload,
        },
      };
    }
    default:
      return state;
  }
}
