/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Brown Rice', 'sticker name', 'web-stories');

function BrownRice({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 56 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1749 23.394C22.0866 23.4786 21.9909 23.5652 21.8885 23.6515C21.2136 24.2207 20.471 24.5937 19.8021 24.5937C18.8456 24.5937 18.2002 24.3466 17.8383 24.0821C17.4936 23.8301 17.4125 23.5824 17.4125 23.394C17.4125 23.2056 17.4936 22.9579 17.8383 22.7059C18.2002 22.4414 18.8456 22.1943 19.8021 22.1943C20.471 22.1943 21.2136 22.5673 21.8885 23.1365C21.9909 23.2228 22.0866 23.3094 22.1749 23.394ZM19.8021 26.1937C22.2374 26.1937 24.2116 23.6094 24.2116 23.394C24.2116 23.1786 22.2374 20.5943 19.8021 20.5943C17.3667 20.5943 15.8125 21.8478 15.8125 23.394C15.8125 24.9402 17.3667 26.1937 19.8021 26.1937Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9733 12.1953C38.885 12.2799 38.7893 12.3665 38.687 12.4528C38.0121 13.022 37.2695 13.395 36.6005 13.395C35.6441 13.395 34.9986 13.1479 34.6368 12.8833C34.2921 12.6313 34.2109 12.3837 34.2109 12.1953C34.2109 12.0069 34.2921 11.7592 34.6368 11.5072C34.9986 11.2427 35.6441 10.9956 36.6005 10.9956C37.2695 10.9956 38.0121 11.3685 38.687 11.9377C38.7893 12.024 38.885 12.1107 38.9733 12.1953ZM36.6005 14.995C39.0358 14.995 41.0101 12.4106 41.0101 12.1953C41.0101 11.9799 39.0358 9.39556 36.6005 9.39556C34.1652 9.39556 32.6109 10.649 32.6109 12.1953C32.6109 13.7415 34.1652 14.995 36.6005 14.995Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.4247 27.0784C44.3833 27.1935 44.3362 27.3137 44.2831 27.4365C43.9324 28.2468 43.4337 28.9116 42.8348 29.2096C41.9786 29.6357 41.2906 29.702 40.8488 29.6264C40.4279 29.5543 40.2449 29.3687 40.161 29.2C40.0771 29.0314 40.0394 28.7735 40.2357 28.3943C40.4418 27.9963 40.9096 27.4875 41.7659 27.0614C42.3648 26.7634 43.1958 26.7665 44.0536 26.9754C44.1837 27.0071 44.308 27.042 44.4247 27.0784ZM43.5476 30.642C45.7279 29.5571 46.3441 26.3639 46.2482 26.1711C46.1522 25.9783 43.2334 24.544 41.0531 25.629C38.8728 26.7139 38.0397 28.5285 38.7286 29.9128C39.4174 31.2972 41.3673 31.727 43.5476 30.642Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7278 8.88017C15.6864 8.99524 15.6393 9.11547 15.5862 9.23831C15.2355 10.0486 14.7368 10.7133 14.1379 11.0113C13.2817 11.4374 12.5937 11.5037 12.1519 11.4281C11.731 11.356 11.548 11.1705 11.4641 11.0018C11.3802 10.8331 11.3425 10.5753 11.5388 10.1961C11.7449 9.79804 12.2127 9.28926 13.069 8.86317C13.6679 8.56516 14.4989 8.56823 15.3567 8.77717C15.4868 8.80884 15.6111 8.84378 15.7278 8.88017ZM14.8507 12.4438C17.031 11.3589 17.6472 8.16563 17.5513 7.97282C17.4553 7.78001 14.5365 6.34579 12.3562 7.43072C10.1759 8.51564 9.34282 10.3303 10.0317 11.7146C10.7205 13.0989 12.6704 13.5287 14.8507 12.4438Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8567 38.0071C17.8154 38.1222 17.7683 38.2424 17.7151 38.3653C17.3645 39.1755 16.8657 39.8403 16.2668 40.1383C15.4106 40.5644 14.7226 40.6307 14.2808 40.5551C13.8599 40.483 13.677 40.2974 13.593 40.1288C13.5091 39.9601 13.4714 39.7022 13.6678 39.323C13.8738 38.925 14.3416 38.4162 15.1979 37.9901C15.7968 37.6921 16.6278 37.6952 17.4856 37.9041C17.6157 37.9358 17.74 37.9707 17.8567 38.0071ZM16.9796 41.5707C19.1599 40.4858 19.7761 37.2926 19.6802 37.0998C19.5842 36.907 16.6654 35.4727 14.4851 36.5577C12.3048 37.6426 11.4717 39.4572 12.1606 40.8415C12.8494 42.2259 14.7993 42.6557 16.9796 41.5707Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1275 17.3695C24.2427 17.4106 24.363 17.4574 24.486 17.5102C25.2971 17.8588 25.9632 18.3558 26.2627 18.9539C26.691 19.8091 26.7591 20.4969 26.6846 20.9388C26.6136 21.3599 26.4285 21.5434 26.2601 21.6277C26.0916 21.7121 25.8338 21.7504 25.4541 21.5551C25.0556 21.35 24.5456 20.8835 24.1173 20.0284C23.8178 19.4302 23.8187 18.5992 24.0254 17.7409C24.0568 17.6107 24.0914 17.4863 24.1275 17.3695ZM27.6933 18.2374C26.6028 16.0599 23.408 15.452 23.2154 15.5484C23.0229 15.6448 21.5962 18.5673 22.6867 20.7448C23.7772 22.9223 25.594 23.7508 26.9766 23.0584C28.3591 22.366 28.7839 20.4149 27.6933 18.2374Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.9258 6.17078C41.041 6.21185 41.1613 6.25865 41.2843 6.31149C42.0955 6.66005 42.7615 7.15706 43.0611 7.75517C43.4894 8.61035 43.5575 9.29814 43.4829 9.74011C43.412 10.1612 43.2269 10.3446 43.0584 10.429C42.89 10.5134 42.6322 10.5517 42.2525 10.3564C41.8539 10.1513 41.344 9.68481 40.9157 8.82963C40.6161 8.23151 40.617 7.40048 40.8238 6.54214C40.8551 6.41201 40.8897 6.28761 40.9258 6.17078ZM44.4917 7.03869C43.4011 4.86119 40.2063 4.25324 40.0138 4.34968C39.8212 4.44612 38.3945 7.3686 39.485 9.54611C40.5756 11.7236 42.3924 12.552 43.7749 11.8596C45.1574 11.1672 45.5822 9.2162 44.4917 7.03869Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.489 20.8145C43.6104 20.8 43.739 20.7882 43.8727 20.7808C44.7542 20.7315 45.5719 20.8797 46.1065 21.2817C46.8709 21.8566 47.2383 22.442 47.3685 22.8709C47.4925 23.2795 47.4086 23.5262 47.2953 23.6768C47.1821 23.8274 46.9684 23.9765 46.5414 23.9708C46.0933 23.9647 45.4289 23.7743 44.6644 23.1995C44.1298 22.7974 43.7604 22.053 43.5631 21.1924C43.5332 21.062 43.5088 20.9352 43.489 20.8145ZM47.0681 20.003C45.1217 18.5393 41.9906 19.4183 41.8612 19.5904C41.7318 19.7625 41.7564 23.0146 43.7028 24.4782C45.6492 25.9419 47.6448 25.8742 48.5741 24.6384C49.5034 23.4026 49.0145 21.4666 47.0681 20.003Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7919 2.61626C14.9133 2.60173 15.0419 2.59001 15.1755 2.58253C16.057 2.53322 16.8747 2.68147 17.4094 3.0835C18.1738 3.65833 18.5412 4.24376 18.6714 4.67264C18.7954 5.08124 18.7114 5.32795 18.5982 5.47853C18.4849 5.62911 18.2713 5.77827 17.8443 5.77252C17.3961 5.76649 16.7317 5.57604 15.9673 5.00122C15.4327 4.59918 15.0633 3.85475 14.866 2.9942C14.836 2.86374 14.8116 2.73694 14.7919 2.61626ZM18.371 1.80472C16.4246 0.341062 13.2935 1.22005 13.164 1.39218C13.0346 1.5643 13.0593 4.81634 15.0057 6.28C16.9521 7.74366 18.9477 7.67596 19.877 6.44015C20.8063 5.20433 20.3174 3.26837 18.371 1.80472Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0234 31.7544C17.1449 31.7399 17.2734 31.7282 17.4071 31.7207C18.2886 31.6714 19.1063 31.8197 19.6409 32.2217C20.4054 32.7965 20.7727 33.3819 20.9029 33.8108C21.027 34.2194 20.943 34.4661 20.8298 34.6167C20.7165 34.7673 20.5028 34.9165 20.0758 34.9107C19.6277 34.9047 18.9633 34.7142 18.1989 34.1394C17.6642 33.7374 17.2948 32.9929 17.0975 32.1324C17.0676 32.0019 17.0432 31.8751 17.0234 31.7544ZM20.6026 30.9429C18.6561 29.4792 15.525 30.3582 15.3956 30.5304C15.2662 30.7025 15.2908 33.9545 17.2372 35.4182C19.1836 36.8818 21.1792 36.8141 22.1085 35.5783C23.0378 34.3425 22.549 32.4066 20.6026 30.9429Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7702 28.0844C15.6514 28.1135 15.5252 28.1407 15.3934 28.1643C14.5243 28.3197 13.6947 28.2714 13.1154 27.937C12.2871 27.4587 11.8516 26.922 11.6706 26.512C11.498 26.1214 11.5516 25.8664 11.6458 25.7032C11.74 25.54 11.9341 25.3661 12.3586 25.3202C12.8042 25.272 13.4868 25.3808 14.3151 25.859C14.8944 26.1935 15.3511 26.8878 15.6509 27.7182C15.6964 27.8441 15.736 27.967 15.7702 28.0844ZM12.3154 29.3226C14.4244 30.5403 17.4263 29.2893 17.534 29.1028C17.6417 28.9162 17.2241 25.691 15.1151 24.4734C13.006 23.2557 11.0333 23.5641 10.2602 24.9032C9.48704 26.2423 10.2063 28.1049 12.3154 29.3226Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5684 16.8852C32.4496 16.9143 32.3234 16.9415 32.1916 16.965C31.3225 17.1205 30.4929 17.0722 29.9136 16.7377C29.0853 16.2595 28.6499 15.7228 28.4688 15.3128C28.2963 14.9222 28.3498 14.6671 28.444 14.504C28.5382 14.3408 28.7323 14.1669 29.1569 14.121C29.6025 14.0728 30.285 14.1816 31.1133 14.6598C31.6926 14.9942 32.1493 15.6886 32.4492 16.519C32.4946 16.6449 32.5342 16.7678 32.5684 16.8852ZM29.1136 18.1234C31.2227 19.341 34.2245 18.09 34.3322 17.9035C34.4399 17.717 34.0224 14.4918 31.9133 13.2741C29.8043 12.0565 27.8315 12.3649 27.0584 13.704C26.2853 15.043 27.0045 16.9057 29.1136 18.1234Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.7799 34.1302C40.6865 34.2091 40.5856 34.2897 40.4782 34.3695C39.7694 34.8959 39.0051 35.2222 38.3374 35.1809C37.3828 35.1217 36.7539 34.8352 36.4091 34.5488C36.0806 34.276 36.0149 34.0238 36.0266 33.8357C36.0382 33.6477 36.1345 33.4055 36.4942 33.1753C36.8717 32.9336 37.5312 32.7269 38.4858 32.7861C39.1534 32.8274 39.8716 33.2456 40.51 33.8554C40.6068 33.9479 40.697 34.0403 40.7799 34.1302ZM38.2385 36.7778C40.6692 36.9284 42.7994 34.4711 42.8127 34.2561C42.826 34.0412 41.0154 31.3397 38.5847 31.1891C36.154 31.0385 34.5253 32.1935 34.4296 33.7368C34.334 35.2801 35.8078 36.6272 38.2385 36.7778Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.083 15.9319C11.9896 16.0109 11.8887 16.0915 11.7813 16.1713C11.0725 16.6977 10.3082 17.024 9.64052 16.9826C8.68592 16.9235 8.05696 16.637 7.71219 16.3506C7.38372 16.0777 7.31804 15.8255 7.32969 15.6375C7.34134 15.4494 7.43764 15.2073 7.79728 14.977C8.17476 14.7354 8.83428 14.5287 9.78888 14.5878C10.4565 14.6292 11.1747 15.0473 11.8131 15.6572C11.9099 15.7496 12.0001 15.8421 12.083 15.9319ZM9.54159 18.5796C11.9723 18.7302 14.1025 16.2728 14.1158 16.0579C14.1291 15.8429 12.3185 13.1415 9.88781 12.9909C7.45715 12.8403 5.82835 13.9953 5.73275 15.5385C5.63714 17.0818 7.11093 18.429 9.54159 18.5796Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2634 16.967C19.2151 17.0793 19.1607 17.1964 19.1001 17.3158C18.7006 18.103 18.1621 18.736 17.5461 18.9968C16.6653 19.3697 15.9746 19.3938 15.5383 19.2912C15.1226 19.1935 14.9513 18.9971 14.8778 18.8236C14.8044 18.6501 14.7826 18.3904 15.0018 18.024C15.2318 17.6393 15.7299 17.1601 16.6106 16.7872C17.2267 16.5264 18.0559 16.5804 18.8993 16.8414C19.0272 16.881 19.1491 16.9235 19.2634 16.967ZM18.1698 20.4702C20.4125 19.5208 21.223 16.3713 21.139 16.1729C21.055 15.9746 18.2295 14.3644 15.9869 15.3138C13.7442 16.2633 12.8016 18.0235 13.4044 19.4474C14.0073 20.8713 15.9272 21.4196 18.1698 20.4702Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0617 5.76774C36.0133 5.88006 35.9589 5.99718 35.8984 6.11654C35.4988 6.90382 34.9603 7.5368 34.3443 7.79758C33.4635 8.17045 32.7728 8.19455 32.3365 8.092C31.9208 7.99431 31.7495 7.79789 31.6761 7.62439C31.6026 7.45089 31.5808 7.1912 31.8 6.82473C32.03 6.44008 32.5281 5.96089 33.4089 5.58802C34.0249 5.32723 34.8542 5.38116 35.6976 5.64222C35.8254 5.68179 35.9474 5.72427 36.0617 5.76774ZM34.9681 9.27099C37.2107 8.32156 38.0212 5.17204 37.9372 4.97372C37.8533 4.7754 35.0277 3.16519 32.7851 4.11461C30.5425 5.06404 29.5999 6.82427 30.2027 8.24816C30.8055 9.67205 32.7254 10.2204 34.9681 9.27099Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9549 22.6215C38.9617 22.7436 38.9652 22.8727 38.9641 23.0066C38.9571 23.8894 38.757 24.696 38.3217 25.2039C37.6992 25.9301 37.0916 26.2594 36.6553 26.362C36.2396 26.4597 35.9987 26.3601 35.8557 26.2375C35.7126 26.1149 35.5774 25.8922 35.6104 25.4664C35.645 25.0195 35.8774 24.3686 36.4998 23.6424C36.9351 23.1345 37.7016 22.8134 38.573 22.6714C38.7051 22.6498 38.8332 22.6335 38.9549 22.6215ZM39.5365 26.2451C41.1213 24.3961 40.4438 21.2153 40.2803 21.0751C40.1168 20.935 36.8698 20.7521 35.285 22.6012C33.7002 24.4503 33.6404 26.4461 34.8144 27.4524C35.9885 28.4586 37.9517 28.0942 39.5365 26.2451Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.258 4.4233C10.2648 4.5454 10.2683 4.67448 10.2672 4.80832C10.2602 5.69118 10.0601 6.49776 9.62475 7.00567C9.00234 7.73187 8.39467 8.06115 7.95836 8.16373C7.54267 8.26145 7.30183 8.1619 7.15878 8.0393C7.01573 7.91669 6.8805 7.69391 6.91347 7.26817C6.94808 6.8213 7.18052 6.1704 7.80293 5.44421C8.23825 4.9363 9.00472 4.61514 9.87611 4.47313C10.0082 4.4516 10.1363 4.43531 10.258 4.4233ZM10.8396 8.0469C12.4244 6.19781 11.7469 3.01702 11.5834 2.87687C11.4199 2.73672 8.17292 2.55389 6.58809 4.40298C5.00326 6.25206 4.94353 8.2479 6.11755 9.25414C7.29157 10.2604 9.25477 9.89598 10.8396 8.0469Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9798 32.3618C12.9866 32.4839 12.9901 32.613 12.989 32.7468C12.982 33.6297 12.7819 34.4362 12.3466 34.9441C11.7241 35.6703 11.1165 35.9996 10.6802 36.1022C10.2645 36.1999 10.0236 36.1004 9.88058 35.9778C9.73753 35.8552 9.6023 35.6324 9.63528 35.2066C9.66988 34.7598 9.90232 34.1089 10.5247 33.3827C10.9601 32.8748 11.7265 32.5536 12.5979 32.4116C12.73 32.3901 12.8581 32.3738 12.9798 32.3618ZM13.5614 35.9854C15.1462 34.1363 14.4688 30.9555 14.3052 30.8153C14.1417 30.6752 10.8947 30.4924 9.30989 32.3415C7.72506 34.1905 7.66533 36.1864 8.83935 37.1926C10.0134 38.1989 11.9766 37.8345 13.5614 35.9854Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5516 29.2263C22.5789 29.1071 22.6114 28.9821 22.6496 28.8538C22.9013 28.0076 23.3173 27.2882 23.8765 26.921C24.6759 26.3961 25.3511 26.2483 25.7988 26.2708C26.2252 26.2923 26.429 26.4548 26.5324 26.6122C26.6358 26.7697 26.7039 27.0213 26.5541 27.4212C26.3969 27.8409 25.993 28.4017 25.1935 28.9267C24.6343 29.2939 23.8088 29.3897 22.9323 29.2844C22.7994 29.2684 22.6718 29.2485 22.5516 29.2263ZM22.9982 25.5836C20.9626 26.9203 20.7309 30.1642 20.8491 30.3442C20.9673 30.5242 24.0361 31.6009 26.0717 30.2641C28.1074 28.9274 28.7186 27.0265 27.8699 25.734C27.0211 24.4415 25.0339 24.2469 22.9982 25.5836Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3498 18.0276C39.3772 17.9084 39.4096 17.7834 39.4478 17.6551C39.6995 16.8089 40.1155 16.0895 40.6747 15.7223C41.4742 15.1973 42.1493 15.0496 42.597 15.0721C43.0235 15.0936 43.2272 15.256 43.3306 15.4135C43.4341 15.571 43.5022 15.8225 43.3523 16.2224C43.1951 16.6421 42.7912 17.203 41.9917 17.728C41.4326 18.0951 40.6071 18.191 39.7305 18.0857C39.5976 18.0697 39.47 18.0498 39.3498 18.0276ZM39.7965 14.3849C37.7608 15.7216 37.5291 18.9655 37.6473 19.1455C37.7655 19.3255 40.8343 20.4021 42.8699 19.0654C44.9056 17.7287 45.5168 15.8278 44.6681 14.5353C43.8194 13.2428 41.8321 13.0481 39.7965 14.3849Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.3601 32.1327C47.3315 32.0138 47.3049 31.8874 47.2819 31.7556C47.1303 30.8858 47.1823 30.0564 47.5193 29.4786C48.0012 28.6524 48.5399 28.2194 48.9507 28.0401C49.342 27.8693 49.5968 27.924 49.7596 28.0189C49.9223 28.1138 50.0954 28.3087 50.1394 28.7334C50.1856 29.1793 50.0738 29.8613 49.5919 30.6875C49.2549 31.2653 48.5586 31.7189 47.7269 32.0151C47.6008 32.06 47.4777 32.099 47.3601 32.1327ZM46.1372 28.6725C44.9102 30.7761 46.1479 33.7835 46.334 33.892C46.52 34.0005 49.747 33.5973 50.974 31.4936C52.201 29.39 51.9014 27.4159 50.5657 26.6368C49.2301 25.8578 47.3642 26.5688 46.1372 28.6725Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6631 13.934C18.6345 13.8151 18.6079 13.6887 18.5849 13.5569C18.4333 12.6871 18.4853 11.8577 18.8223 11.2799C19.3042 10.4537 19.8428 10.0206 20.2536 9.84137C20.645 9.67058 20.8998 9.72526 21.0626 9.82018C21.2253 9.91511 21.3983 10.11 21.4424 10.5347C21.4886 10.9805 21.3768 11.6626 20.8949 12.4888C20.5579 13.0666 19.8616 13.5202 19.0298 13.8164C18.9037 13.8613 18.7807 13.9003 18.6631 13.934ZM17.4402 10.4737C16.2132 12.5774 17.4509 15.5848 17.6369 15.6933C17.823 15.8018 21.05 15.3985 22.277 13.2949C23.504 11.1913 23.2043 9.21714 21.8687 8.4381C20.5331 7.65906 18.6672 8.37009 17.4402 10.4737Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9572 43.599C19.9286 43.4801 19.902 43.3537 19.879 43.2219C19.7273 42.3521 19.7793 41.5227 20.1164 40.9449C20.5982 40.1187 21.1369 39.6857 21.5477 39.5064C21.9391 39.3356 22.1939 39.3903 22.3566 39.4852C22.5194 39.5801 22.6924 39.775 22.7364 40.1998C22.7826 40.6456 22.6709 41.3276 22.189 42.1538C21.852 42.7316 21.1556 43.1852 20.3239 43.4814C20.1978 43.5263 20.0747 43.5653 19.9572 43.599ZM18.7343 40.1388C17.5073 42.2424 18.745 45.2498 18.931 45.3583C19.117 45.4668 22.3441 45.0636 23.5711 42.9599C24.7981 40.8563 24.4984 38.8822 23.1628 38.1031C21.8271 37.3241 19.9613 38.0351 18.7343 40.1388Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.15312 22.2273C7.1805 22.1081 7.21296 21.9831 7.25112 21.8548C7.50283 21.0086 7.91888 20.2892 8.47804 19.922C9.27751 19.397 9.95269 19.2493 10.4003 19.2718C10.8268 19.2933 11.0306 19.4557 11.134 19.6132C11.2374 19.7707 11.3055 20.0223 11.1557 20.4221C10.9985 20.8418 10.5945 21.4027 9.79506 21.9277C9.2359 22.2948 8.41041 22.3907 7.53384 22.2854C7.40095 22.2694 7.27336 22.2495 7.15312 22.2273ZM7.59981 18.5846C5.56414 19.9213 5.33243 23.1652 5.45064 23.3452C5.56885 23.5252 8.63762 24.6018 10.6733 23.2651C12.709 21.9284 13.3201 20.0275 12.4714 18.735C11.6227 17.4425 9.63548 17.2479 7.59981 18.5846Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9513 11.028C23.9787 10.9089 24.0112 10.7839 24.0493 10.6556C24.301 9.80936 24.7171 9.08997 25.2763 8.72279C26.0757 8.19782 26.7509 8.05007 27.1985 8.07259C27.625 8.09405 27.8288 8.25651 27.9322 8.414C28.0356 8.57149 28.1037 8.82303 27.9539 9.22291C27.7967 9.64263 27.3927 10.2035 26.5933 10.7285C26.0341 11.0956 25.2086 11.1915 24.3321 11.0861C24.1992 11.0702 24.0716 11.0503 23.9513 11.028ZM24.398 7.38537C22.3624 8.72209 22.1306 11.966 22.2489 12.146C22.3671 12.326 25.4358 13.4026 27.4715 12.0659C29.5072 10.7292 30.1183 8.82826 29.2696 7.53577C28.4209 6.24328 26.4337 6.04864 24.398 7.38537Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.851 39.7248C28.8784 39.6056 28.9108 39.4807 28.949 39.3524C29.2007 38.5061 29.6168 37.7867 30.1759 37.4196C30.9754 36.8946 31.6506 36.7468 32.0982 36.7694C32.5247 36.7908 32.7284 36.9533 32.8319 37.1108C32.9353 37.2683 33.0034 37.5198 32.8536 37.9197C32.6963 38.3394 32.2924 38.9002 31.4929 39.4252C30.9338 39.7924 30.1083 39.8883 29.2317 39.7829C29.0988 39.767 28.9712 39.7471 28.851 39.7248ZM29.2977 36.0821C27.262 37.4189 27.0303 40.6627 27.1485 40.8428C27.2667 41.0228 30.3355 42.0994 32.3712 40.7627C34.4068 39.4259 35.018 37.525 34.1693 36.2325C33.3206 34.9401 31.3334 34.7454 29.2977 36.0821Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4561 32.726C30.4275 32.6071 30.4009 32.4807 30.3779 32.3488C30.2262 31.4791 30.2782 30.6497 30.6153 30.0718C31.0971 29.2457 31.6358 28.8126 32.0466 28.6334C32.438 28.4626 32.6928 28.5172 32.8555 28.6122C33.0183 28.7071 33.1913 28.902 33.2353 29.3267C33.2815 29.7725 33.1698 30.4546 32.6879 31.2808C32.3509 31.8586 31.6545 32.3122 30.8228 32.6084C30.6967 32.6533 30.5736 32.6923 30.4561 32.726ZM29.2332 29.2657C28.0062 31.3694 29.2439 34.3768 29.4299 34.4853C29.6159 34.5938 32.843 34.1905 34.07 32.0869C35.297 29.9833 34.9973 28.0091 33.6617 27.2301C32.326 26.451 30.4602 27.1621 29.2332 29.2657Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75893 14.5277C1.73035 14.4088 1.70373 14.2825 1.68074 14.1506C1.52909 13.2808 1.58109 12.4514 1.91812 11.8736C2.40001 11.0474 2.93866 10.6144 3.34946 10.4351C3.74084 10.2643 3.99564 10.319 4.15839 10.4139C4.32113 10.5089 4.49416 10.7037 4.53819 11.1285C4.58439 11.5743 4.47263 12.2563 3.99074 13.0825C3.65371 13.6603 2.95738 14.1139 2.12566 14.4101C1.99957 14.455 1.87648 14.494 1.75893 14.5277ZM0.536041 11.0675C-0.690954 13.1711 0.546732 16.1785 0.732762 16.287C0.918792 16.3955 4.14583 15.9923 5.37283 13.8886C6.59982 11.785 6.30016 9.81089 4.96452 9.03185C3.62888 8.2528 1.76304 8.96384 0.536041 11.0675Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5508 23.6272C29.5782 23.508 29.6107 23.383 29.6488 23.2547C29.9005 22.4085 30.3166 21.6891 30.8757 21.3219C31.6752 20.7969 32.3504 20.6492 32.798 20.6717C33.2245 20.6932 33.4283 20.8556 33.5317 21.0131C33.6351 21.1706 33.7032 21.4222 33.5534 21.822C33.3962 22.2417 32.9922 22.8026 32.1928 23.3276C31.6336 23.6947 30.8081 23.7906 29.9315 23.6853C29.7986 23.6693 29.6711 23.6494 29.5508 23.6272ZM29.9975 19.9845C27.9618 21.3212 27.7301 24.5651 27.8483 24.7451C27.9666 24.9251 31.0353 26.0017 33.071 24.665C35.1067 23.3283 35.7178 21.4274 34.8691 20.1349C34.0204 18.8424 32.0332 18.6478 29.9975 19.9845Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.3492 12.428C46.3765 12.3088 46.409 12.1838 46.4472 12.0555C46.6989 11.2093 47.1149 10.4899 47.6741 10.1227C48.4736 9.59772 49.1487 9.44997 49.5964 9.47249C50.0229 9.49395 50.2266 9.65641 50.33 9.8139C50.4334 9.97139 50.5015 10.2229 50.3517 10.6228C50.1945 11.0425 49.7906 11.6034 48.9911 12.1284C48.4319 12.4955 47.6065 12.5914 46.7299 12.486C46.597 12.4701 46.4694 12.4502 46.3492 12.428ZM46.7959 8.78527C44.7602 10.122 44.5285 13.3659 44.6467 13.5459C44.7649 13.7259 47.8337 14.8025 49.8693 13.4658C51.905 12.1291 52.5162 10.2282 51.6675 8.93567C50.8187 7.64318 48.8315 7.44854 46.7959 8.78527Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.132 24.0009C51.1034 23.882 51.0768 23.7556 51.0538 23.6237C50.9021 22.754 50.9541 21.9246 51.2912 21.3468C51.773 20.5206 52.3117 20.0875 52.7225 19.9083C53.1139 19.7375 53.3687 19.7922 53.5314 19.8871C53.6942 19.982 53.8672 20.1769 53.9112 20.6016C53.9574 21.0474 53.8457 21.7295 53.3638 22.5557C53.0268 23.1335 52.3304 23.5871 51.4987 23.8833C51.3726 23.9282 51.2495 23.9672 51.132 24.0009ZM49.9091 20.5406C48.6821 22.6443 49.9198 25.6517 50.1058 25.7602C50.2918 25.8687 53.5189 25.4654 54.7459 23.3618C55.9729 21.2582 55.6732 19.284 54.3376 18.505C53.0019 17.7259 51.1361 18.437 49.9091 20.5406Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4348 5.8031C22.4063 5.6842 22.3796 5.55785 22.3566 5.42599C22.205 4.55623 22.257 3.72682 22.594 3.149C23.0759 2.32283 23.6146 1.88977 24.0254 1.71051C24.4167 1.53972 24.6715 1.5944 24.8343 1.68932C24.997 1.78425 25.1701 1.97912 25.2141 2.40386C25.2603 2.84968 25.1485 3.53173 24.6666 4.3579C24.3296 4.93573 23.6333 5.38931 22.8016 5.6855C22.6755 5.73041 22.5524 5.76944 22.4348 5.8031ZM21.2119 2.34286C19.9849 4.4465 21.2226 7.45391 21.4087 7.56242C21.5947 7.67092 24.8217 7.26767 26.0487 5.16403C27.2757 3.0604 26.9761 1.08628 25.6404 0.30724C24.3048 -0.471805 22.4389 0.239229 21.2119 2.34286Z"
        fill="#FFC864"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4568 36.5995C24.4282 36.4806 24.4016 36.3542 24.3786 36.2224C24.227 35.3526 24.279 34.5232 24.616 33.9454C25.0979 33.1192 25.6365 32.6862 26.0473 32.5069C26.4387 32.3361 26.6935 32.3908 26.8563 32.4857C27.019 32.5806 27.192 32.7755 27.2361 33.2002C27.2823 33.6461 27.1705 34.3281 26.6886 35.1543C26.3516 35.7321 25.6553 36.1857 24.8235 36.4819C24.6974 36.5268 24.5744 36.5658 24.4568 36.5995ZM23.2339 33.1393C22.0069 35.2429 23.2446 38.2503 23.4306 38.3588C23.6167 38.4673 26.8437 38.0641 28.0707 35.9604C29.2977 33.8568 28.998 31.8827 27.6624 31.1036C26.3268 30.3246 24.4609 31.0356 23.2339 33.1393Z"
        fill="#FFC864"
      />
    </svg>
  );
}

BrownRice.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 56 / 46,
  svg: BrownRice,
  title,
};
