const response = {
  story_data: {
    version: 35,
    pages: [],
    autoAdvance: true,
    defaultPageDuration: 7,
  },
  style_presets: { colors: [], textStyles: [] },
  meta: { web_stories_publisher_logo: 0 },
  author: {
    id: 1,
    name: 'Storyteller'
  },
  // We dont use it, it is required for StoryEditor
  storyId: 1,
  title: '',
  status: 'draft',
  date: '2021-09-23T15:04:29',
  modified: '2021-10-11T07:11:16',
  slug: '10',
  excerpt: '',
  password: '',
  permalink_template: 'postname:pagename',
  featured_media: { url: '' },
  publisher_logo: { url: '' },
};

export default response;
