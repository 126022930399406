import { UserContext } from '@web-stories-wp/story-editor';
import { StorytellerStoryEditor } from '@src/components/storyEditor';
import { useStudioContext } from '@hooks/studioContext';
import { useStudioApiProvider } from '@hooks/api';
import { useFetchUser, useStoryEditorConfig } from '@hooks';

const useStoryEditor = () => {
  const studioContext = useStudioContext();
  const { user } = useFetchUser();
  const apiProvider = useStudioApiProvider({ studioContext });
  const config = useStoryEditorConfig({ apiProvider });

  const isLoading = !apiProvider || !user || !config;

  const storyEditor = (
    <UserContext.Provider value={user}>
      <StorytellerStoryEditor editorConfig={config?.editorConfig} />
    </UserContext.Provider>
  );

  return {
    isLoading,
    storyEditor,
  };
};

export default useStoryEditor;
