import { useEffect } from 'react';
import { Studio } from './components/studio';
import { useAuthorizationManager } from '@hooks/authorization';
import { StudioContextProvider } from '@components/studioContextProvider';

function App() {
  const { authContext } = useAuthorizationManager();

  useEffect(() => {
    if (authContext && !authContext.isAuthenticated) {
      authContext.redirectToLogin();
    }
  }, [authContext]);

  const editor = (
    <StudioContextProvider>
      <Studio />
    </StudioContextProvider>
  );

  return (
    <div className="App">
      {authContext && authContext.isAuthenticated && editor}
    </div>
  );
}

export default App;
