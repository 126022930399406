/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import PropTypes from 'prop-types';
import { StyleSheetManager } from 'styled-components';
import stylisRTLPlugin from 'stylis-plugin-rtl';

/**
 * Internal dependencies
 */
import { useConfig } from '../../app';

const withRTLPlugins = [stylisRTLPlugin];
const withoutRTLPlugins = [];

function DirectionAware({ children }) {
  const { isRTL } = useConfig();
  return (
    <StyleSheetManager
      stylisPlugins={isRTL ? withRTLPlugins : withoutRTLPlugins}
    >
      {children}
    </StyleSheetManager>
  );
}

DirectionAware.propTypes = {
  children: PropTypes.node,
};

export default DirectionAware;
