/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Preload image using a promise.
 *
 * @param {string} src Image source.
 * @param {string} [srcset] Image source set.
 * @return {Promise} Image object.
 */
const preloadImage = (src, srcset = undefined) => {
  return new Promise((resolve, reject) => {
    const image = new window.Image();
    image.onload = () => resolve(image);
    image.onerror = () =>
      reject(new Error('Failed to load image with src: ' + src));
    image.decoding = 'async';
    image.crossOrigin = 'anonymous';
    if (srcset) {
      image.srcset = srcset;
    }
    image.src = src;
  });
};

export default preloadImage;
