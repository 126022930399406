/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import { useState } from '@web-stories-wp/react';

const ResourceInfoWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 4px 8px;
  background-color: ${({ theme, active }) =>
    rgba(theme.colors.bg.primary, active ? 0.8 : 0.6)};
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
`;

const Title = styled.p`
  display: -webkit-box;
  font-size: 12px;
  font-weight: 700;
  line-height: 0.95rem;
  margin: 0;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PublishDate = styled.p`
  color: rgba(145, 152, 153, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 1rem;
  margin: 0;
`;

const ResourceInfo = ({ title, date }) => {
  const [active, setActive] = useState(false);
  const makeActive = () => setActive(true);
  const makeInactive = () => setActive(false);

  const currentDate = new Date(date);
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const formattedDate = formatter.format(currentDate);

  return (
    <ResourceInfoWrapper
      active={active}
      onPointerEnter={makeActive}
      onFocus={makeActive}
      onPointerLeave={makeInactive}
      onBlur={makeInactive}
    >
      <Title>{title}</Title>
      {date && formattedDate && <PublishDate>{formattedDate}</PublishDate>}
    </ResourceInfoWrapper>
  );
};

ResourceInfo.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
};

export default ResourceInfo;
