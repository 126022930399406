const convertMsToReadableFormat = (duration) => {
  const minute = 60;

  let minutes = Math.floor((duration / minute) % minute);
  let seconds = Math.floor(duration % minute);

  return `${minutes} : ${
    seconds < 10 ? '0' : ''
  }${seconds}`;
};

export default convertMsToReadableFormat;
