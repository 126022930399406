/**
 * External dependencies
 */
import { useState } from 'react';
import { useCallback } from '@web-stories-wp/react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Gallery from 'react-photo-gallery';
import MediaElement from './mediaElement';
import { useInsertElement } from '../../../../canvas';
import createResourceFromExternalMedia from '../externalMediaPane/helpers/createResourceFromExternalMedia';
import { mapExternalMediaTo3p } from './mediaDataMapping';
import { useExternalMedia } from '@src/web-stories-wp/story-editor/src/app/externalMedia';

const PHOTO_MARGIN = 4;

/**
 * @callback InsertionCallback
 * @param {Object} element Inserted element.
 */

/**
 * Displays a gallery of media elements arranged in a row-based format.
 *
 * @param {Object} props Component props.
 * @param {Array.<Object>} props.resources List of resources to display.
 * @param {InsertionCallback} props.onInsert Called when element is selected.
 * @param {string} props.providerType Provider of gallery's elements.
 * @return {*} The gallery element.
 */
function ExternalMediaGalleryElement({
  resources,
  lastMediaElementRef,
  providerType,
  enableDeletion,
  enableEditInAzzuu,
}) {
  const [expandedMenuIndex, setExpandedMenuIndex] = useState(-1);
  const {
    actions: { deleteMedia },
  } = useExternalMedia();

  const media = resources.map((resource) => mapExternalMediaTo3p(resource));
  const insertElement = useInsertElement();

  const onRowClicked = useCallback(
    (asset) => {
      const resource = createResourceFromExternalMedia(asset);
      insertElement(resource.type, { resource });
    },
    [insertElement]
  );

  const handleOnDelete = useCallback(
    (asset) => {
      deleteMedia(asset.id);
    },
    [deleteMedia]
  );

  const imageRenderer = useCallback(
    ({ index, photo }) => {
      const isLastElement = resources.length - 1 === index;
      const elementRef = isLastElement ? lastMediaElementRef : null;
      const onInsert = () => {
        onRowClicked(resources[index]);
      };

      const onDelete = () => {
        handleOnDelete(resources[index]);
      };

      const onEditInAzzuu = () => {
        const externalCallbackUrl = resources[index].externalCallbackUrl;
        if (!externalCallbackUrl) return;
        window.open(externalCallbackUrl, '_blank');
      };

      return (
        <div ref={elementRef}>
          <MediaElement
            key={index}
            index={index}
            isEditMenuExpanded={index === expandedMenuIndex}
            margin={PHOTO_MARGIN + 'px'}
            resource={media[index]}
            width={photo.width}
            height={photo.height}
            onInsert={onInsert}
            canEditMedia={enableDeletion || (enableEditInAzzuu && resources[index].externalCallbackUrl)}
            providerType={providerType}
            handleOnDelete={enableDeletion ? onDelete : undefined}
            handleOnEditInAzzuu={
              enableEditInAzzuu && resources[index].externalCallbackUrl
                ? onEditInAzzuu
                : undefined
            }
            setExpandedMenuIndex={setExpandedMenuIndex}
          />
        </div>
      );
    },
    [handleOnDelete, lastMediaElementRef, media, onRowClicked, providerType]
  );

  return (
    <div>
      <Gallery
        targetRowHeight={110}
        direction={'row'}
        // This should match the actual margin the element is styled with.
        margin={PHOTO_MARGIN}
        photos={media}
        renderImage={imageRenderer}
      />
    </div>
  );
}

ExternalMediaGalleryElement.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.object).isRequired,
  providerType: PropTypes.string.isRequired,
};

ExternalMediaGalleryElement.defaultProps = {};

export default ExternalMediaGalleryElement;
