import { useAPI } from '../../../api';
import { useMemo } from 'react';
import { apiFetcherFactory } from './apiFetcher';

export const useApiFetcher = () => {
  const {
    actions: { getMediaFromProvider, registerUsage },
  } = useAPI();

  const apiFetcher = useMemo(
    () => apiFetcherFactory({ getMediaFromProvider, registerUsage }),
    [getMediaFromProvider, registerUsage]
  );

  return apiFetcher;
};