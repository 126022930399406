import { useCallback } from 'react';
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_VARIANTS,
} from '@web-stories-wp/design-system';
import { StudioButton } from './studioButton';
import { useAuthorizationManager } from '@src/hooks/authorization';
import { useStudioContext } from '@src/hooks/studioContext';

const ariaLabel = 'Back to Story';

export function BackToStoryButton({ disabled }) {
  const context = useStudioContext();
  const { authContext } = useAuthorizationManager();

  const backToStory = useCallback(() => {
    authContext.redirectToCmsAfterDiscardingChanges(context);
  }, [authContext, context]);

  return (
    <StudioButton
      variant={BUTTON_VARIANTS.RECTANGLE}
      type={BUTTON_TYPES.SECONDARY}
      size={BUTTON_SIZES.SMALL}
      onClick={backToStory}
      aria-label={ariaLabel}
      disabled={disabled}
    >
      Discard Changes
    </StudioButton>
  );
}
