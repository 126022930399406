/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Open Hands', 'sticker name', 'web-stories');

function OpenHands({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 57 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M5.94735 2.22957C4.91878 0.858138 4.37592 1.94385 4.23306 2.65814C3.89973 3.56297 3.83306 5.74406 6.23306 7.22977C9.23306 9.08691 10.3759 9.51549 11.233 9.51549H12.0902C10.3759 8.94406 7.23306 3.94385 5.94735 2.22957Z"
        fill="#C44440"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.4809 2.1281C4.39697 2.30242 4.33791 2.49827 4.30311 2.67229L4.30201 2.67775L4.30009 2.68297C3.97617 3.56225 3.90487 5.70464 6.27066 7.16918C7.76992 8.09729 8.80226 8.66662 9.56549 9.00386C10.328 9.3408 10.8178 9.4442 11.2331 9.4442H11.7591C11.3854 9.23608 10.9694 8.87529 10.5366 8.4268C9.97786 7.84787 9.38211 7.11308 8.80108 6.34431C8.21987 5.57529 7.65226 4.77076 7.14996 4.0519C7.06431 3.92933 6.98059 3.80928 6.89904 3.69235C6.50182 3.12279 6.1561 2.62709 5.89021 2.27256C5.63641 1.93417 5.41918 1.75673 5.23889 1.68388C5.06359 1.61304 4.91972 1.63941 4.79786 1.72117C4.67183 1.80572 4.56538 1.95267 4.4809 2.1281ZM5.29241 1.55143C5.50766 1.6384 5.744 1.83953 6.00449 2.18685C6.27202 2.54355 6.61956 3.04187 7.01676 3.6114C7.09819 3.72817 7.18171 3.84793 7.26706 3.97008C7.76923 4.68875 8.33555 5.49142 8.91505 6.25817C9.49473 7.02516 10.0865 7.75471 10.6394 8.3276C11.1948 8.90317 11.7022 9.31101 12.1128 9.44786L12.0902 9.58706H11.2331C10.7911 9.58706 10.2809 9.47617 9.50776 9.13453C8.73528 8.7932 7.69621 8.21967 6.19547 7.29065C3.76581 5.78657 3.82307 3.57227 4.16412 2.63882C4.20089 2.45703 4.26291 2.25157 4.35219 2.06613C4.44183 1.87996 4.56216 1.70727 4.71827 1.60253C4.87856 1.495 5.07219 1.46244 5.29241 1.55143Z"
        fill="black"
      />
      <path
        d="M50.7226 2.22957C51.7511 0.858138 52.294 1.94385 52.4369 2.65814C52.7702 3.56297 52.8369 5.74406 50.4369 7.22977C47.4369 9.08691 46.294 9.51549 45.4369 9.51549H44.5797C46.294 8.94406 49.4369 3.94385 50.7226 2.22957Z"
        fill="#C44440"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.189 2.1281C52.2729 2.30242 52.332 2.49827 52.3668 2.67229L52.3679 2.67775L52.3698 2.68297C52.6938 3.56225 52.765 5.70464 50.3993 7.16918C48.9 8.09729 47.8677 8.66662 47.1044 9.00386C46.3419 9.3408 45.8521 9.4442 45.4369 9.4442H44.9108C45.2845 9.23608 45.7005 8.87529 46.1333 8.4268C46.6921 7.84787 47.2878 7.11308 47.8688 6.34431C48.4501 5.57529 49.0177 4.77076 49.52 4.0519C49.6056 3.92933 49.6893 3.80928 49.7709 3.69235C50.1681 3.12279 50.5138 2.62709 50.7797 2.27256C51.0335 1.93417 51.2507 1.75673 51.431 1.68388C51.6063 1.61304 51.7502 1.63941 51.8721 1.72117C51.9981 1.80572 52.1045 1.95267 52.189 2.1281ZM51.3775 1.55143C51.1623 1.6384 50.9259 1.83953 50.6654 2.18685C50.3979 2.54355 50.0504 3.04187 49.6532 3.6114C49.5717 3.72817 49.4882 3.84793 49.4029 3.97008C48.9007 4.68875 48.3344 5.49142 47.7549 6.25817C47.1752 7.02516 46.5834 7.75471 46.0306 8.3276C45.4751 8.90317 44.9677 9.31101 44.5571 9.44786L44.5797 9.58706H45.4369C45.8788 9.58706 46.389 9.47617 47.1622 9.13453C47.9346 8.7932 48.9737 8.21967 50.4745 7.29065C52.9041 5.78657 52.8469 3.57227 52.5058 2.63882C52.469 2.45703 52.407 2.25157 52.3177 2.06613C52.2281 1.87996 52.1078 1.70727 51.9517 1.60253C51.7914 1.495 51.5977 1.46244 51.3775 1.55143Z"
        fill="black"
      />
      <path
        d="M3.66146 1.65828C2.63289 0.286849 2.09003 1.37256 1.94717 2.08685C1.61384 2.99168 1.54717 5.17277 3.94717 6.65848C6.94717 8.51562 8.09 8.9442 8.94716 8.9442H9.80432C8.09003 8.37277 4.94717 3.37256 3.66146 1.65828Z"
        fill="#C44440"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.19526 1.55682C2.11133 1.73113 2.05226 1.92698 2.01746 2.101L2.01637 2.10646L2.01445 2.11168C1.69053 2.99096 1.61923 5.13335 3.98502 6.59789C5.48428 7.526 6.51662 8.09533 7.27985 8.43257C8.04238 8.76951 8.53221 8.87291 8.94741 8.87291H9.47346C9.09975 8.66479 8.68376 8.304 8.25093 7.85552C7.69221 7.27658 7.09647 6.5418 6.51544 5.77302C5.93422 5.004 5.36661 4.19947 4.86432 3.48061C4.77867 3.35804 4.69495 3.23799 4.6134 3.12106C4.21617 2.5515 3.87046 2.0558 3.60456 1.70127C3.35077 1.36288 3.13354 1.18544 2.95325 1.11259C2.77794 1.04175 2.63407 1.06813 2.51222 1.14988C2.38618 1.23443 2.27973 1.38138 2.19526 1.55682ZM3.00677 0.980138C3.22202 1.06711 3.45836 1.26824 3.71885 1.61556C3.98637 1.97226 4.33391 2.47058 4.73111 3.04011C4.81255 3.15688 4.89607 3.27664 4.98142 3.39879C5.48359 4.11746 6.04991 4.92013 6.6294 5.68688C7.20909 6.45387 7.80084 7.18342 8.35373 7.75631C8.9092 8.33188 9.4166 8.73972 9.82715 8.87657L9.80456 9.01577H8.94741C8.50545 9.01577 7.99528 8.90488 7.22211 8.56324C6.44964 8.22192 5.41056 7.64839 3.90982 6.71936C1.48017 5.21528 1.53743 3.00098 1.87848 2.06753C1.91524 1.88574 1.97726 1.68028 2.06655 1.49484C2.15618 1.30867 2.27652 1.13598 2.43263 1.03124C2.59291 0.92371 2.78654 0.891152 3.00677 0.980138Z"
        fill="black"
      />
      <path
        d="M53.0082 1.65828C54.0368 0.286849 54.5796 1.37256 54.7225 2.08685C55.0558 2.99168 55.1225 5.17277 52.7225 6.65848C49.7225 8.51562 48.5797 8.9442 47.7225 8.9442H46.8654C48.5796 8.37277 51.7225 3.37256 53.0082 1.65828Z"
        fill="#C44440"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4749 1.55682C54.5588 1.73113 54.6179 1.92698 54.6527 2.101L54.6538 2.10646L54.6557 2.11168C54.9796 2.99096 55.0509 5.13335 52.6851 6.59789C51.1859 7.526 50.1535 8.09533 49.3903 8.43257C48.6278 8.76951 48.138 8.87291 47.7228 8.87291H47.1967C47.5704 8.66479 47.9864 8.304 48.4192 7.85552C48.978 7.27658 49.5737 6.5418 50.1547 5.77302C50.7359 5.004 51.3036 4.19947 51.8059 3.48061C51.8915 3.35804 51.9752 3.23799 52.0568 3.12106C52.454 2.5515 52.7997 2.0558 53.0656 1.70127C53.3194 1.36288 53.5366 1.18544 53.7169 1.11259C53.8922 1.04175 54.0361 1.06813 54.158 1.14988C54.284 1.23443 54.3904 1.38138 54.4749 1.55682ZM53.6634 0.980138C53.4482 1.06711 53.2118 1.26824 52.9513 1.61556C52.6838 1.97226 52.3363 2.47058 51.9391 3.04011C51.8576 3.15688 51.7741 3.27664 51.6887 3.39879C51.1866 4.11746 50.6203 4.92013 50.0408 5.68688C49.4611 6.45387 48.8693 7.18342 48.3164 7.75631C47.761 8.33188 47.2536 8.73972 46.843 8.87657L46.8656 9.01577H47.7228C48.1647 9.01577 48.6749 8.90488 49.4481 8.56324C50.2205 8.22192 51.2596 7.64839 52.7603 6.71936C55.19 5.21528 55.1327 3.00098 54.7917 2.06753C54.7549 1.88574 54.6929 1.68028 54.6036 1.49484C54.514 1.30867 54.3937 1.13598 54.2375 1.03124C54.0773 0.92371 53.8836 0.891152 53.6634 0.980138Z"
        fill="black"
      />
      <path
        d="M10.6353 5.71415C9.60675 4.91415 10.0163 3.47605 10.3496 2.85701L23.0639 5.71415C26.6068 7.6569 27.1115 13.4761 26.921 16.1429L24.921 31H15.4925L17.921 15.5714C15.6353 15.0476 10.5782 13.8857 8.63532 13.4286C6.20675 12.8571 3.49243 8 1.921 6.28571C0.349572 4.57143 -0.650428 2.14272 0.49243 1.42843C1.40672 0.857006 2.20672 1.85701 2.49243 2.42843C5.77814 7.71415 9.06386 8.99986 12.4924 9.14272C15.921 9.28558 16.4924 7.71415 16.2067 7.85701C15.921 7.99986 11.921 6.71415 10.6353 5.71415Z"
        fill="#CE635F"
      />
      <path
        d="M46.0346 5.71415C47.0632 4.91415 46.6536 3.47605 46.3203 2.85701L33.606 5.71415C30.0632 7.6569 29.5584 13.4761 29.7489 16.1429L31.7489 31H41.1775L38.7489 15.5714C41.0346 15.0476 46.0917 13.8857 48.0346 13.4286C50.4632 12.8571 53.1775 8 54.7489 6.28571C56.3203 4.57143 57.3203 2.14272 56.1775 1.42843C55.2632 0.857006 54.4632 1.85701 54.1775 2.42843C50.8918 7.71415 47.6061 8.99986 44.1775 9.14272C40.7489 9.28558 40.1775 7.71415 40.4632 7.85701C40.7489 7.99986 44.7489 6.71415 46.0346 5.71415Z"
        fill="#CE635F"
      />
    </svg>
  );
}

OpenHands.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 57 / 31,
  svg: OpenHands,
  title,
};
