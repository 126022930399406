import React from 'react';
import './optionsList.css';
import PropTypes from 'prop-types';

const OptionsList = ({
  setChosenOption,
  options = [],
  onSearch,
  isRequestPending,
  shouldDisplayNoOptionFoundMessage,
  shouldDisplayOptionsList,
  disableAllOptions,
  customAllOptionName = 'All Options',
}) => {
  return (
    <>
      {shouldDisplayNoOptionFoundMessage && (
        <div className="optionsList__noResults">No results found</div>
      )}
      {!isRequestPending && shouldDisplayOptionsList ? (
        <ul className="optionsList">
          {!disableAllOptions && (
            <li className="optionsList__item" key={'all_options'}>
              <button onClick={() => setChosenOption(null)}>
                {customAllOptionName}
              </button>
            </li>
          )}
          {options.map((option) => (
            <li className="optionsList__item" key={option.key}>
              <button onClick={() => setChosenOption(option.key)}>
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};

OptionsList.propTypes = {
  setChosenOption: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRequestPending: PropTypes.bool.isRequired,
  shouldDisplayOptionsList: PropTypes.bool.isRequired,
  shouldDisplayNoOptionFoundMessage: PropTypes.bool.isRequired,
  disableAllOptions: PropTypes.bool,
  customOptionName: PropTypes.string,
};

export default OptionsList;
