export const ASSET_TYPE = 'assetType';
export const CONTENT_BANK_ASSET_TYPE = 'contentBankAssetType';
export const STORY_ID = 'storyId';
export const STUDIO_STORY_ID = 'studioStoryId';
export const RESOURCE_ID = 'resourceId';
export const STUDIO_DEFINITION_ID = 'studioDefinitionId';
export const CMS_PAGE_ID = 'cmsPageId';
export const ADD_NEW_PAGE = 'addNewPage';
export const IS_NEW = 'isNew';

export const AssetTypes = {
  Story: 'story',
  StoryThumbnail: 'storyThumbnail',
  StoryIcon: 'storyIcon',
  Landscape: 'landscape',
  Clip: 'clip',
  ClipThumbnail: 'clipThumbnail',
  ContentBankAsset: 'contentBankAsset',
  Portrait: 'portrait',
  Print: 'print',
  Vertical: 'vertical',
  Square: 'square',
  Ad: 'ad',
};
