import { useStudioStoryContextLoader } from '@hooks/studioContext';
import StudioContext from '@contexts/StudioContext';

export const StudioContextProvider = ({ children }) => {
  const studioStoryValue = useStudioStoryContextLoader();

  return (
    <StudioContext.Provider value={studioStoryValue}>
      {children}
    </StudioContext.Provider>
  );
};