import PropTypes from 'prop-types';

export default PropTypes.shape({
  gameClock: PropTypes.string,
  title: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  quarter: PropTypes.string,
  date: PropTypes.string,
  gameDescription: PropTypes.string,
});
