/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Yellow Dots', 'sticker name', 'web-stories');

function YellowDots({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 62 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M37.7127 38.8071C37.7127 38.2138 37.6594 37.6205 37.6594 37.0272C37.6594 36.9284 37.6594 36.5823 37.7127 36.5823L37.6594 36.6812C37.4461 36.7306 37.3928 36.7306 37.2329 36.6812C37.1263 36.6812 37.1796 36.6317 37.2329 36.6317C37.2862 36.6317 37.1263 36.8295 37.073 36.8295C37.1263 36.8295 36.913 36.9284 36.913 36.9284C36.8597 36.9284 36.8064 36.9778 36.7531 36.9778C37.0196 37.0272 37.3395 37.0272 37.606 37.0767C37.6594 37.1261 37.766 37.3239 37.6594 37.3733C38.1925 36.9284 38.1392 36.0879 37.6594 35.6429C37.1263 35.1485 36.3266 35.1979 35.7935 35.6429C34.5141 36.6812 34.9406 38.9555 36.6465 39.351C38.2991 39.6971 39.8984 38.0161 39.8451 36.5823C39.7918 34.9507 37.8193 33.7146 36.22 34.6046C34.6207 35.4945 34.9939 37.4228 35.0472 38.8566C35.0472 40.3893 37.7127 40.3893 37.7127 38.8071Z"
        fill="#A7AA2D"
      />
      <path
        d="M32.9531 15.6872C32.3134 16.0827 31.5671 16.5277 31.3538 17.2693C31.0873 18.0109 31.4071 18.802 31.9402 19.3459C32.7932 20.1864 34.3925 20.6808 35.6186 20.5819C37.2179 20.483 38.3907 19.0986 37.9109 17.6154C37.4312 16.231 35.7785 15.6872 34.3392 15.8355C33.6461 15.8849 33.0064 16.3794 33.0064 17.0715C33.0064 17.6648 33.5928 18.357 34.3392 18.3076C34.5524 18.3076 34.819 18.2581 35.0322 18.3076L35.4054 18.4065C35.2988 18.4559 35.4054 18.2087 35.4054 18.2087C35.3521 18.0109 35.6186 18.2581 35.2988 18.1098C35.0855 18.0109 34.4991 17.9615 34.1792 17.8132C33.9127 17.6648 33.8594 17.4671 33.8594 17.8626C33.8594 18.2581 33.966 18.0109 34.2859 17.8626C35.7252 16.9232 34.3925 14.7972 32.9531 15.6872Z"
        fill="#A7AA2D"
      />
      <path
        d="M16.6614 22.6469C15.2221 21.3614 15.9543 18.8624 17.1945 18.2466C18.4348 17.6307 19.9264 17.9126 20.9795 18.5432C22.6321 19.4826 22.5788 21.4603 21.1928 22.6469C19.9666 23.6852 17.8876 23.7841 16.6614 22.6469Z"
        fill="#A7AA2D"
      />
      <path
        d="M9.76331 10.9901C9.97655 12.0284 9.76331 13.8835 8.97945 14.3694C8.1956 14.8553 8.16656 14.7473 7.76012 14.9363C7.35368 15.1253 5.28527 15.341 5.33858 13.7589C5.28527 12.2756 5.01872 9.75409 6.99119 9.21023C8.32394 8.86414 9.55007 9.80353 9.76331 10.9901Z"
        fill="#A7AA2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0102 28.7994C18.0161 28.7798 18.0215 28.7599 18.0265 28.7398C18.2931 27.7509 17.4934 26.9104 16.4272 26.861C16.0466 26.861 15.666 26.9496 15.3138 27.1005C14.6734 26.8991 14.05 27.1483 13.655 27.6519C13.5218 27.8002 13.3485 27.9238 13.1753 28.0474C13.002 28.171 12.8287 28.2946 12.6955 28.443C12.1624 28.9868 12.0024 29.6296 12.0557 30.3712C12.2157 31.7556 13.2286 33.0411 14.8279 33.1399C14.9419 33.1481 15.0544 33.1375 15.163 33.1112C15.6254 33.5213 16.2166 33.7327 16.9603 33.5356C18.9845 33.0061 19.0885 30.1802 18.0102 28.7994Z"
        fill="#A7AA2D"
      />
      <path
        d="M29.4499 36.4834C29.4499 36.7306 29.5033 36.78 29.5033 36.6317C29.4499 36.8789 29.4499 36.9283 29.5033 36.78C29.3966 36.9778 29.3966 37.0272 29.4499 36.9283C29.4499 36.8789 29.5033 36.8789 29.5033 36.8295C29.3433 36.9778 29.3433 37.0272 29.3966 36.9283L29.4499 36.8789C29.5033 36.8295 29.5033 36.8295 29.29 36.9778C29.3433 36.9778 29.3433 36.9283 29.3966 36.9283C29.6099 36.78 29.1301 37.0272 29.3433 36.9778C29.5033 36.9283 29.4499 36.9283 29.1834 36.9778C29.2367 36.9778 29.29 36.9778 29.29 36.9778C29.2367 36.9778 29.1301 36.9778 29.0768 36.9283C29.1834 36.9778 29.1301 36.9283 28.9168 36.8789C28.7569 36.7306 28.7036 36.7306 28.8102 36.8295C28.7036 36.6811 28.6503 36.6317 28.7036 36.7306C28.7569 36.8295 28.7569 36.78 28.6503 36.5823C28.597 36.4339 28.597 36.2856 28.6503 36.0878C28.597 36.2856 28.8102 35.7912 28.7036 35.9395C28.8635 35.7417 28.9168 35.6923 28.8102 35.7912C28.7036 35.8901 28.7569 35.8406 28.9702 35.6923C28.9168 35.7417 28.8635 35.7417 28.8102 35.7912C28.597 35.8901 28.8102 35.7912 28.9168 35.7912C28.8102 35.7912 28.7569 35.8406 28.7036 35.8901C28.6503 35.8901 28.597 35.8901 28.5437 35.9395C28.3304 35.989 28.9169 35.9395 28.6503 35.9395C27.9573 35.9395 27.2642 36.4834 27.3175 37.1756C27.3709 37.8183 27.904 38.4116 28.6503 38.4116C29.3966 38.4116 30.143 38.1149 30.6761 37.67C31.529 37.0272 31.529 35.8901 30.7827 35.1484C30.0897 34.4563 28.9702 34.4068 28.1172 34.8518C27.2642 35.3462 26.7845 36.1867 26.9444 37.1261C27.1043 37.7689 27.904 38.1644 28.597 37.9666C29.29 37.8183 29.6099 37.1756 29.4499 36.4834Z"
        fill="#A7AA2D"
      />
      <path
        d="M28.2086 16.4288C28.2086 16.0827 28.2086 15.786 28.2086 15.4399C28.2086 14.7972 27.6222 14.1545 26.8759 14.2039C26.1295 14.2533 25.5431 14.7478 25.5431 15.4399C25.5431 15.786 25.5431 16.0827 25.5431 16.4288C25.5431 17.0715 26.1295 17.7143 26.8759 17.6648C27.6222 17.6154 28.2086 17.121 28.2086 16.4288Z"
        fill="#A7AA2D"
      />
      <path
        d="M44.0949 19.6919C43.8283 20.5324 44.0949 21.5707 45.0545 21.9168C45.3743 22.0651 45.7475 22.164 46.0673 22.0651C46.3872 21.9662 46.6538 21.7684 46.867 21.5212C46.9736 21.3729 47.1335 21.1751 47.2402 21.0268C47.6133 20.4829 47.3468 19.6424 46.7604 19.3458C46.0673 18.9997 45.3743 19.1975 44.9478 19.7908C44.8412 19.9391 44.6813 20.1368 44.5747 20.2852C45.1611 20.1368 45.8008 19.9885 46.3872 19.8402C46.2273 19.7908 46.2273 19.6919 46.4405 19.8896C46.3872 19.8402 46.3872 19.8402 46.4938 19.9391C46.6004 20.038 46.6538 20.0874 46.6538 20.2357C46.6004 20.038 46.6538 20.5818 46.6538 20.3346C46.6538 20.3841 46.6538 20.4829 46.6538 20.5324C46.6538 20.4829 46.6538 20.4335 46.7071 20.3841C46.9203 19.7908 46.4405 18.9997 45.8008 18.8514C45.0011 18.703 44.3081 19.0491 44.0949 19.6919Z"
        fill="#A7AA2D"
      />
      <path
        d="M42.4423 27.8993C42.9754 28.5915 43.7218 29.0365 44.6814 29.0859C45.3744 29.1354 46.1207 28.8882 46.6538 28.4432C47.2936 27.9488 47.5068 27.0588 47.0803 26.3666C46.6005 25.5756 45.5876 25.4273 44.6814 25.3284C43.9883 25.2789 43.2953 25.9217 43.3486 26.5644C43.4019 27.306 43.935 27.751 44.6814 27.8005C44.788 27.8005 45.0012 27.8993 44.628 27.8005C44.6814 27.8005 44.7347 27.8005 44.788 27.8499C44.8946 27.8499 45.0012 27.8993 45.1078 27.9488C45.3211 27.9982 44.788 27.8005 44.9479 27.8993C44.9479 27.8993 45.0012 27.9488 45.0545 27.9488C45.1612 27.9982 44.788 27.7016 44.8946 27.8499C45.0012 27.9488 44.6814 27.5532 44.788 27.7016C44.8946 27.8499 44.6814 27.3555 44.6814 27.5038C44.7347 27.6521 44.628 27.1083 44.628 27.2566C44.628 27.4049 44.6814 26.8611 44.6814 27.0094C44.628 27.1577 44.8413 26.6633 44.788 26.8116C44.6814 27.0094 45.0545 26.5644 44.8946 26.7127C44.6814 26.9105 45.1611 26.5644 45.0012 26.6633C44.9479 26.6633 44.8413 26.7127 44.8413 26.7622C44.8413 26.7622 45.2145 26.6633 45.0545 26.6633C45.0012 26.6633 45.0012 26.6633 44.9479 26.7127C44.8946 26.7127 44.8413 26.7127 44.8413 26.7622C44.6814 26.8116 45.2145 26.7127 45.0545 26.7127C44.9479 26.7127 44.8946 26.7127 44.788 26.7127C44.5747 26.7127 45.1078 26.7622 45.0012 26.7622C44.9479 26.7622 44.9479 26.7622 44.8946 26.7622C44.8413 26.7622 44.788 26.7127 44.788 26.7127C45.1078 26.7622 45.0545 26.8611 44.9479 26.7622C44.8946 26.7622 44.8946 26.7127 44.8413 26.7127C44.7347 26.6633 45.1078 26.9105 44.9479 26.8116C44.8946 26.7622 44.8946 26.7622 44.8413 26.7127C44.6814 26.5644 45.0545 26.9599 44.8946 26.8116C44.6814 26.5644 44.4681 26.3172 44.095 26.2678C43.7751 26.1689 43.3486 26.2183 43.0821 26.4161C42.3357 26.5644 42.0159 27.3555 42.4423 27.8993Z"
        fill="#A7AA2D"
      />
      <path
        d="M58.382 25.3282C58.4353 25.1305 58.4353 25.081 58.382 25.3282C58.222 25.4766 58.0621 25.526 57.8489 25.5755C57.529 25.4766 57.2091 25.3282 56.8893 25.2294C56.7293 25.0316 56.7293 25.0316 56.7827 25.1305C56.7827 25.1305 56.7827 25.1799 56.836 25.1799C56.8893 25.2788 56.8893 25.2788 56.836 25.1799C56.7827 25.081 56.7827 25.081 56.836 25.1799C56.836 25.2294 56.8893 25.3777 56.8893 25.3777C56.7826 25.2294 56.8893 25.1305 56.8893 25.3282C56.836 25.5755 56.9426 25.0316 56.8893 25.2788C56.8893 25.3777 56.7293 25.5755 56.9426 25.2294C56.836 25.3777 57.0492 25.1305 57.1025 25.081C57.1558 25.0316 57.2624 24.9822 57.3691 24.9327C57.2091 24.9822 57.2624 24.9822 57.4757 24.9327C57.8489 25.1305 58.222 25.3282 58.6485 25.526C58.5419 25.3777 58.382 25.1799 58.2753 25.0316C57.9022 24.4877 57.1025 24.1911 56.4628 24.5866C55.8764 24.9327 55.5565 25.7238 55.983 26.2676C56.0896 26.416 56.2496 26.6137 56.3562 26.7621C56.6227 27.1081 57.0492 27.4048 57.529 27.3554C58.4353 27.2565 59.1283 26.8609 59.4482 26.0699C59.6614 25.5755 59.6081 25.0316 59.5015 24.4877C59.2882 23.7461 58.7551 23.0045 57.7955 23.0539C56.836 23.1034 56.0363 23.7461 55.8764 24.6361C55.7164 25.2788 56.0896 26.0204 56.7827 26.1688C57.3691 26.3171 58.222 25.971 58.382 25.3282Z"
        fill="#A7AA2D"
      />
      <path
        d="M16.0539 14.6489C16.9069 16.0333 16.1224 17.2535 16.1072 17.4177C16.0921 17.5819 15.5208 18.6537 14.8278 18.6537C14.0814 18.6537 13.495 18.1099 13.495 17.4177C13.4417 16.6266 13.2818 15.7367 13.6017 14.9456C13.8149 14.4512 14.1881 14.1545 14.7212 14.0556C15.2543 14.0062 15.7874 14.204 16.0539 14.6489Z"
        fill="#A7AA2D"
      />
      <path
        d="M8.64378 4.21656C8.59047 4.41433 8.64378 4.36489 8.64378 4.21656C8.64378 4.16712 8.69709 4.11768 8.69709 4.06824C8.7504 3.96935 8.80371 3.87047 8.69709 4.0188C8.7504 3.91991 8.80371 3.87047 8.80371 3.77159C8.91033 3.62326 8.96364 3.47494 9.07026 3.32661C9.12357 3.22773 9.12357 3.22773 9.07026 3.32661C9.12357 3.27717 9.17688 3.17829 9.2835 3.12885C8.96364 3.22773 8.64378 3.37605 8.32392 3.47494C8.48385 3.47494 8.48385 3.47494 8.27061 3.47494C8.43054 3.52438 8.37723 3.47494 8.16399 3.4255C7.89744 3.27717 7.6842 2.93108 7.6842 2.63443C7.73751 2.38722 7.73751 2.33778 7.6842 2.43666C7.79082 2.23889 7.79082 2.18945 7.73751 2.28834C7.6842 2.33778 7.52427 2.53554 7.6842 2.38722C7.84413 2.23889 7.63089 2.43666 7.57758 2.4861C7.63089 2.58499 7.57758 2.63443 7.47096 2.68387C7.41765 2.73331 7.36434 2.78275 7.31103 2.8322C7.04448 3.02996 7.52427 2.68387 7.36434 2.78275C6.83124 3.17829 6.45807 3.87047 6.88455 4.46377C7.25772 4.95819 8.11068 5.30428 8.69709 4.90875C9.76329 4.16712 11.256 2.63443 9.92322 1.49727C9.55005 1.15118 9.01695 1.00285 8.48385 1.00285C7.73751 1.00285 7.31103 1.39839 6.93786 1.94224C6.40477 2.58499 6.08491 3.4255 5.97829 4.21656C5.92498 4.85931 6.618 5.50205 7.31103 5.45261C8.11068 5.40317 8.59047 4.90875 8.64378 4.21656Z"
        fill="#A7AA2D"
      />
      <path
        d="M17.3074 2.23154C17.3607 2.03377 17.2008 2.37986 17.1475 2.4293C16.9342 2.57763 17.3074 2.23154 17.1475 2.4293C16.8809 2.52819 17.3607 2.28098 17.1475 2.4293C17.3607 2.37986 16.8809 2.4293 17.2008 2.4293C16.9342 2.33042 17.3074 2.4293 17.2008 2.4293C17.0942 2.37986 17.4673 2.52819 17.2008 2.4293C17.1475 2.37986 17.4673 2.52819 17.2008 2.4293C17.2541 2.47875 17.2541 2.47875 17.0942 2.33042C17.0408 2.13265 16.9875 2.13265 17.0408 2.28098C17.1475 1.88544 17.2541 1.48991 17.3607 1.09438C17.6806 0.797727 17.9471 0.847169 18.2137 0.995494C18.267 1.04494 18.267 1.04494 18.1603 0.946052C18.267 1.04494 18.267 1.09438 18.2137 0.946052C17.8938 0.352752 16.9875 0.154985 16.4011 0.501077C15.7614 0.847169 15.6015 1.58879 15.9213 2.18209C16.5611 3.31925 18.2137 3.71479 19.2799 2.82484C19.653 2.52819 19.7596 2.03377 19.653 1.63824C19.0133 -0.487758 15.3882 -0.586641 14.7485 1.58879C14.2687 3.12149 16.8809 3.76423 17.3074 2.23154Z"
        fill="#A7AA2D"
      />
      <path
        d="M27.9528 3.84217C27.6329 4.33658 27.3664 4.88044 27.7395 5.47374C27.9528 5.86928 28.3259 6.16593 28.7524 6.31425C29.6054 6.56146 30.5116 6.16593 30.8848 5.47374C31.0981 5.07821 31.1514 4.58379 30.8848 4.2377C30.405 3.59496 29.8186 3.29831 29.019 3.24886C28.5925 3.19942 28.166 3.29831 27.6862 3.29831C26.9932 3.29831 26.3002 3.84217 26.3535 4.53435C26.4068 5.17709 26.9399 5.77039 27.6862 5.77039C28.0061 5.77039 28.2726 5.72095 28.5925 5.72095C28.6458 5.72095 28.7524 5.72095 28.8057 5.72095C28.9657 5.77039 29.019 5.72095 28.8057 5.72095C28.859 5.72095 28.9657 5.72095 28.9657 5.77039C28.859 5.77039 28.8057 5.72095 28.7524 5.67151C28.6991 5.62207 28.6458 5.57263 28.5925 5.52318C28.5925 5.12765 28.5925 4.68267 28.5925 4.28714C28.5925 4.33658 28.5392 4.33658 28.5392 4.38602C28.6991 4.13882 28.9123 4.03993 29.1789 4.08937C29.4454 3.99049 29.6587 4.03993 29.8186 4.2377C30.0852 4.48491 30.1385 4.48491 30.0319 4.33658C30.0319 4.73212 30.0319 5.17709 30.0319 5.57263C30.0852 5.47374 30.1918 5.32542 30.2451 5.22653C30.6183 4.68268 30.405 3.84217 29.7653 3.54552C29.1256 3.10054 28.3259 3.24886 27.9528 3.84217Z"
        fill="#A7AA2D"
      />
      <path
        d="M37.5228 3.07283C37.6295 3.02338 37.7361 2.97394 37.8427 2.9245C38.0026 2.87506 38.0026 2.82562 37.896 2.9245C37.6295 2.97394 37.4162 2.9245 37.203 2.77617C37.0964 2.67729 37.0964 2.62785 37.0964 2.52897C37.043 2.43008 37.3096 2.3312 37.043 2.52897C37.0964 2.47952 37.1497 2.43008 37.2563 2.38064C37.3629 2.43008 37.3629 2.38064 37.203 2.38064C37.3096 2.43008 37.3096 2.43008 37.1497 2.3312C37.3629 2.43008 37.0964 2.23232 37.043 2.18287C36.7765 1.93567 36.6166 1.68846 36.35 1.44125C35.8702 0.946832 34.964 0.996273 34.4842 1.44125C33.9511 1.93567 34.0044 2.67729 34.4842 3.17171C35.0706 3.76501 35.4971 4.40775 36.35 4.7044C37.4162 5.05049 38.589 4.75384 39.2288 3.86389C39.9218 2.97394 39.8685 1.78734 39.0155 0.996273C38.1626 0.155764 37.1497 0.452414 36.1901 0.89739C35.5504 1.19404 35.3371 2.03455 35.7103 2.57841C36.0835 3.22115 36.8831 3.36948 37.5228 3.07283Z"
        fill="#A7AA2D"
      />
      <path
        d="M46.0499 6.62171C46.6896 6.77004 47.3826 6.77004 47.969 6.42394C48.5021 6.12729 48.8753 5.53399 48.9286 4.94069C48.9286 4.44628 48.7153 3.95186 48.3422 3.65521C47.8091 3.21023 47.116 3.11135 46.423 3.25968C45.73 3.408 45.3035 4.14963 45.5168 4.79237C45.73 5.43511 46.423 5.83065 47.1694 5.63288C47.3826 5.58344 46.7962 5.63288 47.0094 5.63288C47.2227 5.63288 46.6896 5.58344 46.7962 5.58344C47.0094 5.63288 46.4763 5.43511 46.6363 5.53399C46.7429 5.58344 46.7962 5.68232 46.4763 5.43511C46.5829 5.53399 46.5829 5.63288 46.3697 5.28679C46.423 5.38567 46.423 5.48455 46.3164 5.08902C46.3164 5.13846 46.3164 5.13846 46.3164 5.1879C46.2631 4.89125 46.2631 4.84181 46.2631 4.94069C46.2631 5.03958 46.2631 4.99014 46.2631 4.84181C46.3164 4.5946 46.2631 4.69349 46.2631 4.79237C46.2098 4.89125 46.4763 4.44628 46.3164 4.64404C46.3164 4.64404 46.5829 4.34739 46.423 4.49572C46.423 4.49572 46.7429 4.29795 46.583 4.39684C46.423 4.49572 46.9561 4.24851 46.7429 4.34739C46.5296 4.44628 46.8495 4.29795 46.9028 4.34739H46.7429C46.5296 4.34739 47.116 4.39683 46.7962 4.34739C46.1032 4.19907 45.3035 4.54516 45.1436 5.1879C44.9303 5.73176 45.3035 6.47339 46.0499 6.62171Z"
        fill="#A7AA2D"
      />
      <path
        d="M57.2642 4.15728C57.3708 4.10784 57.4775 4.0584 57.6374 4.00896H57.744C57.9039 3.95952 57.8506 3.95952 57.6374 4.00896C57.4775 4.0584 57.3708 4.00896 57.2642 3.95952C57.2109 3.81119 57.1043 3.95952 57.2642 3.95952C57.2642 3.86063 57.4775 4.30561 57.4775 4.35505C57.4241 4.25617 57.4241 4.10784 57.5308 4.00896C57.5841 3.95952 57.6374 3.91007 57.6907 3.86063C57.7973 3.76175 57.4775 4.00896 57.6907 3.86063C57.6374 3.91007 57.9039 3.86063 57.6374 3.86063C57.5841 3.86063 57.2642 3.81119 57.4775 3.86063C57.6374 3.86063 57.2642 3.81119 57.3175 3.81119C57.1043 3.76175 57.3708 3.86063 57.2109 3.81119C57.1043 3.76175 57.051 3.71231 56.9444 3.66287C57.1043 3.76175 56.9977 3.66287 56.8911 3.61342C56.9444 3.76175 56.7844 3.51454 56.8911 3.61342C56.9444 3.66287 56.9444 4.00896 56.9977 3.81119C57.051 3.11901 56.3579 2.57515 55.6649 2.57515C54.9186 2.57515 54.3855 3.11901 54.3322 3.81119C54.2256 5.93719 57.6374 7.07434 59.29 5.88774C60.5694 4.89891 60.2496 3.36622 59.2367 2.2785C58.2771 1.28966 57.1576 1.58631 56.0381 2.08073C55.3984 2.37738 55.2384 3.21789 55.5583 3.76175C55.8248 4.30561 56.5712 4.40449 57.2642 4.15728Z"
        fill="#A7AA2D"
      />
      <path
        d="M56.836 17.4176C56.7827 18.0109 56.836 18.6536 57.1559 19.2469C57.3691 19.6425 57.689 19.8897 58.0622 20.038C58.6486 20.2358 59.1817 20.1863 59.7148 19.9391C60.5677 19.593 61.1541 18.8514 61.2074 17.9614C61.2607 16.8737 60.3012 16.1815 59.235 16.1815C58.5419 16.1815 57.8489 16.7254 57.9022 17.4176C57.9555 18.0603 58.4886 18.6536 59.235 18.6536C59.2883 18.6536 59.5015 18.6536 59.2883 18.6536C59.0217 18.6042 59.3949 18.7031 59.1283 18.6042C59.0217 18.5547 58.9684 18.5547 59.0217 18.5547C59.1283 18.6042 59.075 18.5547 58.8618 18.4064C58.8618 18.4064 58.6486 18.1098 58.7552 18.2581C58.7552 18.2581 58.5952 17.912 58.6486 18.0603C58.5419 17.7142 58.6486 17.7637 58.5952 17.8626C58.5952 17.9614 58.7019 17.5659 58.6486 17.6648C58.6486 17.6648 58.6486 17.7637 58.5952 17.7637C58.5952 17.7637 58.7552 17.467 58.6486 17.6154C58.6486 17.6648 58.5952 17.6648 58.5952 17.7142C58.5419 17.8131 58.7552 17.5165 58.7019 17.5659C58.6486 17.5659 58.6486 17.6153 58.5952 17.6648C58.4886 17.8131 58.7552 17.5659 58.7552 17.5659C58.7552 17.6153 58.6486 17.6648 58.5952 17.6648C58.382 17.7637 58.5419 17.6648 58.5952 17.6648C58.5952 17.6648 58.9684 17.6154 58.8085 17.6154C58.6486 17.6154 59.075 17.6648 59.0217 17.6648C59.2883 17.7637 59.3416 17.8131 59.235 17.7637C59.1817 17.7142 59.235 17.7637 59.3416 17.8131C59.5548 17.9614 59.4482 17.912 59.3949 17.8626C59.2883 17.7637 59.6081 18.1592 59.5015 18.0109C59.4482 17.8626 59.6081 18.2087 59.5548 18.2087L59.5015 18.0109C59.5015 18.0109 59.4482 17.912 59.5015 17.912C59.5015 17.912 59.5548 18.2581 59.5548 18.1098C59.5548 18.0109 59.5548 17.9614 59.5548 17.8626C59.5548 17.7142 59.5548 17.5659 59.5548 17.4176C59.6081 16.7748 58.9151 16.1321 58.2221 16.1815C57.4224 16.1815 56.836 16.7254 56.836 17.4176Z"
        fill="#A7AA2D"
      />
      <path
        d="M49.7457 36.4033C50.4387 37.0461 51.3983 37.2438 52.3046 36.8977C52.7844 36.7 53.0509 36.3044 53.3175 35.9089C53.4241 35.81 53.4774 35.6617 53.584 35.5134C53.7973 35.1673 53.7973 34.8212 53.744 34.4257C53.4241 32.7941 51.1851 32.5469 50.1189 33.6346C49.6391 34.129 49.5858 34.9201 50.1189 35.3651C50.652 35.81 51.5049 35.8595 51.9847 35.3651L52.0914 35.2662C52.0914 35.2662 51.8248 35.4145 52.038 35.3156C51.9847 35.3156 51.7715 35.4145 51.9314 35.3651C51.8781 35.3651 51.7715 35.3651 51.7182 35.3651C51.8248 35.3651 51.8248 35.3651 51.6649 35.3651C51.4516 35.3156 51.7182 35.4145 51.5049 35.3156C51.4516 35.2662 51.2384 35.1673 51.2917 35.1673C51.345 35.2167 51.1851 35.019 51.1318 34.9695C51.0785 34.8706 51.0785 34.8706 51.0785 34.9201C51.0785 35.019 51.0785 34.9695 51.0785 34.7718C51.1318 34.574 51.1851 34.3762 51.2384 34.129C51.1318 34.2773 51.0785 34.4257 50.9718 34.574C50.9185 34.6234 50.8652 34.6729 50.8652 34.7718C50.8119 34.8212 50.652 35.019 50.8119 34.8212C51.0252 34.7223 51.1851 34.6234 51.3983 34.5245C51.345 34.574 51.2917 34.574 51.1851 34.574C51.2917 34.5245 51.3983 34.5245 51.5049 34.574C51.345 34.5245 51.3983 34.5245 51.5583 34.574C51.6649 34.6729 51.6649 34.6729 51.5583 34.574C51.0785 34.129 50.1722 34.0796 49.6924 34.574C49.2659 35.1673 49.2126 35.9089 49.7457 36.4033Z"
        fill="#A7AA2D"
      />
      <path
        d="M4.91218 22.8561C4.64563 23.7461 3.07961 24.4789 2.88283 24.5739C2.68604 24.6688 2.19016 24.6982 1.92683 24.636C1.66349 24.5739 0.903781 23.669 0.850415 23.251C0.79705 22.833 0.680703 21.7161 1.05387 21.1723C1.64028 20.2823 2.72647 20.2357 3.73936 20.6312C4.69894 20.9773 5.23204 21.9167 4.91218 22.8561Z"
        fill="#A7AA2D"
      />
    </svg>
  );
}

YellowDots.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 62 / 40,
  svg: YellowDots,
  title,
};
