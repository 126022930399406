import { useMemo } from 'react';
import axios from 'axios';
import { useEnvConfig } from '@hooks';
import { refreshTokenUrl, useAuthorizationManager } from '@hooks/authorization';

const useApi = () => {
  const { authContext, deleteContext } = useAuthorizationManager();
  const config = useEnvConfig();

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: config.apiUrl,
      timeout: 930000,
    });

    instance.interceptors.request.use(async function (request) {
      if (request.url === refreshTokenUrl) {
        return request;
      }

      const { requestToken } = authContext;

      await requestToken(instance);

      return request;
    });

    instance.interceptors.request.use(function (request) {
      const token = authContext.token.access_token;
      request.headers.Authorization = `Bearer ${token}`;

      return request;
    });

    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.response?.status === 401) {
          deleteContext();
          authContext.redirectToLogin();
        } else {
          return Promise.reject(error);
        }
      }
    );

    return instance;
  }, [authContext, deleteContext, config]);

  return api;
};

export default useApi;
