/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useCallback, useMemo } from '@web-stories-wp/react';

/**
 * Internal dependencies
 */
import { useStory } from '../../../app';
import useElementOutOfCanvas from '../utils/useElementOutOfCanvas';
import normalizeRotationDegrees from '../utils/normalizeRotationDegrees';

function useSingleSelectionRotate({
  selectedElement,
  isEditMode,
  pushTransform,
  frame,
  setTransformStyle,
  shouldThrottleRotation,
  resetMoveable,
}) {
  const { updateSelectedElements } = useStory((state) => ({
    updateSelectedElements: state.actions.updateSelectedElements,
  }));

  const { handleElementOutOfCanvas } = useElementOutOfCanvas();

  const onRotateStart = useCallback(
    ({ set }) => {
      if (isEditMode) {
        // In edit mode, we need to signal right away that the action started.
        pushTransform(selectedElement.id, frame);
      }
      set(frame.rotate);
    },
    [frame, isEditMode, pushTransform, selectedElement.id]
  );

  const onRotate = useCallback(
    ({ target, beforeRotate }) => {
      setTransformStyle(target, {
        rotate: normalizeRotationDegrees(beforeRotate),
      });
    },
    [setTransformStyle]
  );

  const onRotateEnd = useCallback(
    ({ target }) => {
      if (handleElementOutOfCanvas(target)) {
        return;
      }
      const properties = { rotationAngle: Math.round(frame.rotate) };
      updateSelectedElements({ properties });
      resetMoveable(target);
    },
    [frame, handleElementOutOfCanvas, resetMoveable, updateSelectedElements]
  );

  const rotateProps = useMemo(
    () => ({
      // ⇧ key rotates the element 15 degrees at a time
      throttleRotate: shouldThrottleRotation ? 15 : 0,
      onRotate,
      onRotateEnd,
      onRotateStart,
    }),
    [shouldThrottleRotation, onRotate, onRotateEnd, onRotateStart]
  );

  return rotateProps;
}

export default useSingleSelectionRotate;
