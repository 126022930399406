import styled from 'styled-components';

export const AssetMetadata = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 60%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  // Needed for ellipsis in flex item parent: https://css-tricks.com/flexbox-truncated-text/
  padding-right: 4px;
  gap: 14px;
`;

export const AssetMetadataTitle = styled.div`
  display: -webkit-box;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const AssetMetadataAuthor = styled(AssetMetadataTitle)`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5em;
`;

export const AssetMetadataSubtitle = styled(AssetMetadataTitle)`
  color: #848484;
  font-weight: 400;
  font-size: 0.75rem;
`;
