import { Text, THEME_CONSTANTS } from '@web-stories-wp/design-system';
import Dialog from '@web-stories-wp/story-editor/src/components/dialog';

function PagesValidationDialog({ isOpen, onClose, onClick, validationResult }) {
  const element = validationResult[0].element;
  const assetName = element?.resource?.title ?? element?.resource?.alt;
  return (
    <Dialog
      title="An error occurred while saving the Story"
      isOpen={isOpen}
      onClose={onClose}
      ariaHideApp={false}
      primaryText="Ok"
      onPrimary={onClick}
    >
      {validationResult?.length > 0 && (
        <>
          <Text size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.MEDIUM}>
            There was a problem uploading the selected asset:
          </Text>
          <Text isBold size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.MEDIUM}>
            Page {validationResult[0].pageNumber}: {assetName}{' '}
            {element.isBackground && '(background)'}
          </Text>
          <Text size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.MEDIUM}>
            Please re-upload this asset and try again.
          </Text>
        </>
      )}
    </Dialog>
  );
}

export default PagesValidationDialog;
