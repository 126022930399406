import { __ } from '@web-stories-wp/i18n';
import { useRef, useMemo } from '@web-stories-wp/react';
import { Section } from '../../common';
import useRovingTabIndex from '../../../../utils/useRovingTabIndex';
import StickerPreview from './stickerPreview';
import { SectionContent } from './sectionContent';
import { SvgFromUrl } from '@src/web-stories-wp/story-editor/src/elements/sticker';

export function CustomStickersSection({ customStickers }) {
  const customStickersRef = useRef(null);
  useRovingTabIndex({ ref: customStickersRef });

  const customStickersElems = useMemo(() => {
    if (!customStickers) return [];

    return customStickers.map(({ id, url, name, ratio }) => ({
      svg: ({ style }) => <SvgFromUrl style={style} url={url} loadable />,
      aspectRatio: ratio,
      title: name,
      url,
      id,
    }));
  }, [customStickers]);

  if (!customStickers || !customStickers.length) {
    return null;
  }

  return (
    <Section
      data-testid="custom-stickers-library-pane"
      title={__('Your Stickers', 'web-stories')}
    >
      <SectionContent ref={customStickersRef}>
        {customStickersElems.map((sticker) => (
          <StickerPreview
            customSticker={sticker}
            stickerType="custom"
            key={sticker.id}
          />
        ))}
      </SectionContent>
    </Section>
  );
}
