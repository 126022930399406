import styled from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as UnMuteIcon } from '@src/web-stories-wp/design-system/src/icons/muted.svg';
import { ReactComponent as MuteIcon } from '@src/web-stories-wp/design-system/src/icons/unmuted.svg';
import { ReactComponent as ReplayIcon } from '@src/web-stories-wp/design-system/src/icons/replay_circle_filled.svg';
import convertMsToReadableFormat from '@src/web-stories-wp/story-editor/src/app/helpers/convertMsToReadableFormat';
import { useExternalMedia } from '@src/web-stories-wp/story-editor/src/app/externalMedia';
import { useMemo } from '@web-stories-wp/react';
import createResourceFromExternalMedia from '../helpers/createResourceFromExternalMedia';

const playerRatio = 9 / 16;

const ExternalMediaContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
  height: ${({ containerWidth }) => playerRatio * containerWidth}px;
  background: ${({ theme }) => theme.colors.interactiveBg.disable};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Video = styled.video`
  height: ${({ containerWidth }) => playerRatio * containerWidth}px;
  width: ${({ containerWidth }) => containerWidth}px;
`;

const MuteIconContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background: rgba(23, 26, 37, 0.65);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressContainer = styled.div`
  position: absolute;
  bottom: 10.5px;
  left: 8px;
  background: rgba(23, 26, 37, 0.65);
  border-radius: 29px;
  font-size: 12px;
  line-height: 18px;
  padding: 0 6px;
`

const ReplayIconContainer = styled.button`
  width: 56px;
  height: 56px;
  position: absolute;
  background: 0;
  color: ${({ theme }) => theme.colors.fg.primary};
  border: none;
  padding: 0;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    color: ${({ theme }) => theme.colors.standard.white};
    border: none;
    outline: none;
  }
`;

function ExternalMediaPlayer({ video, containerWidth, onPreviewVisible }) {
  const {
    actions: { 
      setPreviewMuted,
      setPreviewPaused,
      setVideoAsInPreview,
      navigatePreviews,
    },
    state: { preview: { muted, paused } }
  } = useExternalMedia();

  const resource = useMemo(() => createResourceFromExternalMedia(video), [video]);

  const [progress, setProgress] = useState(0);
  const [playbackState, setPlaybackState] = useState('playing');
  const videoRef = useRef(null);

  const handleToggleMuted = useCallback((event) => {
    event.stopPropagation();
    setPreviewMuted(!muted);
    videoRef.current.muted = false;
  }, [muted, setPreviewMuted]);

  const handleProgress = useCallback((event) => {
    setProgress(event.target.currentTime);
  }, []);

  const handlePlaybackEnded = useCallback(() => {
    setPlaybackState('ended');
    setPreviewPaused(true);
  }, [setPreviewPaused]);

  const handleReplay = useCallback((event) => {
    event?.stopPropagation();
    setPlaybackState('playing');
    setPreviewPaused(false);
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  }, [setPreviewPaused]);

  useEffect(() => {
    if (paused) {
      videoRef.current?.pause();
      return;
    }

    if (playbackState === 'ended') {
      handleReplay();
      return;
    }

    videoRef.current?.play();
  }, [paused]);

  const togglePaused = useCallback(() => {
    setPreviewPaused(!paused);
  }, [paused, setPreviewPaused]);

  const handleFocusLostClick = useCallback(() => {
    setVideoAsInPreview(null);
  }, [setVideoAsInPreview]);

  const handleKeyDown = useCallback((e) => {
    switch(e.code) {
      case 'Space':
        togglePaused();
        return;
      case 'ArrowDown':
        navigatePreviews(1);
        return;
      case 'ArrowUp':
        navigatePreviews(-1);
        return;
    }
  }, [togglePaused, navigatePreviews]);

  useEffect(() => {
    window.addEventListener('click', handleFocusLostClick);
    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('click', handleFocusLostClick);
      document.body.removeEventListener('keydown', handleKeyDown);
    }
  }, [togglePaused, handleKeyDown]);

  useEffect(onPreviewVisible, []);

  return (
    <ExternalMediaContainer containerWidth={containerWidth}>
      <Video 
        src={video.url}
        poster={video.thumbnailUrl}
        containerWidth={containerWidth}
        autoPlay
        muted={muted}
        onTimeUpdate={handleProgress}
        onEnded={handlePlaybackEnded}
        ref={videoRef}
      />
      <ProgressContainer>
        {convertMsToReadableFormat(progress)}
      </ProgressContainer>
      <MuteIconContainer onClick={handleToggleMuted}>
        {muted 
          ? <UnMuteIcon width={16} height={16} /> 
          : <MuteIcon width={16} height={16} />}
      </MuteIconContainer>
      {playbackState === 'ended' &&
        <ReplayIconContainer onClick={handleReplay}>
          <ReplayIcon />
        </ReplayIconContainer>
      }
    </ExternalMediaContainer>
  );
}

export default ExternalMediaPlayer;
