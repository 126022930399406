const getValueFromSelectedOption = (option) => {
  if (!option) return null;

  if (Array.isArray(option)) {
    return option.map((opt) => opt.value);
  }

  return option.value;
};

export default function extractFiltersSimpleValues(filters) {
  return Object.keys(filters).reduce((acc, key) => {
    return { ...acc, [key]: getValueFromSelectedOption(filters[key]) };
  }, {});
}
