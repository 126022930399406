/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Image = styled.img`
  display: block;
  height: 28px;
  width: 28px;
  border-radius: ${({ theme }) => theme.borders.radius.small};
  object-fit: cover;
`;

function VisibleImage({ ...attrs }) {
  // eslint-disable-next-line styled-components-a11y/alt-text
  return <Image {...attrs} crossOrigin="anonymous" />;
}

VisibleImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default VisibleImage;
