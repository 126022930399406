import React from 'react';
import styled from 'styled-components';

const PosterImage = styled.img`
  width: 100%;
`;

export const PreviewTemplatePoster = ({ posterUrl }) => {
  return (
    <PosterImage src={posterUrl} alt="Template poster" />
  );
};
