/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Green Cutlery', 'sticker name', 'web-stories');

function GreenCutlery({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <circle cx="25" cy="25" r="25" fill="white" />
      <path
        d="M23.4817 14.5835C23.2766 14.5835 23.0991 14.6584 22.9492 14.8083C22.7994 14.9582 22.7244 15.1356 22.7244 15.3407V20.6412C22.7244 21.1775 22.5825 21.6232 22.2985 21.9781C22.0146 22.3331 21.6517 22.5973 21.21 22.7708V15.3407C21.21 15.1356 21.1351 14.9582 20.9852 14.8083C20.8354 14.6584 20.6579 14.5835 20.4528 14.5835C20.2477 14.5835 20.0703 14.6584 19.9204 14.8083C19.7705 14.9582 19.6956 15.1356 19.6956 15.3407V22.7708C19.2539 22.5973 18.8911 22.3331 18.6071 21.9781C18.3231 21.6232 18.1812 21.1775 18.1812 20.6412V15.3407C18.1812 15.1356 18.1062 14.9582 17.9564 14.8083C17.8065 14.6584 17.629 14.5835 17.424 14.5835C17.2189 14.5835 17.0414 14.6584 16.8915 14.8083C16.7417 14.9582 16.6667 15.1356 16.6667 15.3407V20.6412C16.6667 21.5562 16.9546 22.3607 17.5304 23.0548C18.1062 23.7489 18.828 24.1827 19.6956 24.3563V35.9737C19.6956 36.1788 19.7705 36.3563 19.9204 36.5061C20.0703 36.656 20.2477 36.7309 20.4528 36.7309C20.6579 36.7309 20.8354 36.656 20.9852 36.5061C21.1351 36.3563 21.21 36.1788 21.21 35.9737V24.3563C22.0777 24.1827 22.7994 23.7489 23.3752 23.0548C23.951 22.3607 24.2389 21.5562 24.2389 20.6412V15.3407C24.2389 15.1356 24.1639 14.9582 24.0141 14.8083C23.8642 14.6584 23.6867 14.5835 23.4817 14.5835Z"
        fill="#19584D"
      />
      <path
        d="M35.4178 21.7229C35.4178 23.03 35.1609 24.1275 34.6471 25.0154C34.1333 25.9034 33.4482 26.5141 32.5918 26.8476L33.2003 35.8655C33.2183 36.0999 33.1462 36.3027 32.9839 36.474C32.8217 36.6453 32.6234 36.7309 32.389 36.7309H29.7928C29.5585 36.7309 29.3602 36.6453 29.1979 36.474C29.0356 36.3027 28.9635 36.0999 28.9815 35.8655L29.59 26.8476C28.7336 26.5141 28.0486 25.9034 27.5347 25.0154C27.0209 24.1275 26.764 23.03 26.764 21.7229C26.764 20.5691 26.9556 19.4445 27.3387 18.3493C27.7218 17.254 28.2514 16.3526 28.9275 15.6449C29.6035 14.9373 30.3247 14.5835 31.0909 14.5835C31.8572 14.5835 32.5783 14.9373 33.2544 15.6449C33.9305 16.3526 34.4601 17.254 34.8432 18.3493C35.2263 19.4445 35.4178 20.5691 35.4178 21.7229Z"
        fill="#19584D"
      />
    </svg>
  );
}

GreenCutlery.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 50 / 50,
  svg: GreenCutlery,
  title,
};
