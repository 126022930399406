/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @param {Object} props             All props.
 * @param {number} props.rowIndex    Identifies the virtualized row index.
 * @param {number} props.columnIndex Identifies the virtualized column index.
 * @return {number} itemIndex        Returns the true index of an item in a virtualized grid
 */
export const getVirtualizedItemIndex = ({ columnIndex, rowIndex }) =>
  columnIndex === 0 ? rowIndex * 2 : rowIndex * 2 + 1;
