/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const AMPToWAAPIProps = {
  iterations: {
    infinity: Infinity,
  },
};

function createKeyframeEffect(target, keyframes, timings) {
  // Translate AMP timings to Web API timings
  const convertedTimings = Object.keys(timings).reduce(
    (acc, property) => ({
      ...acc,
      [property]:
        (AMPToWAAPIProps[property] &&
          AMPToWAAPIProps[property][timings[property]]) ||
        timings[property],
    }),
    {}
  );

  return new KeyframeEffect(target, keyframes, convertedTimings);
}

export default createKeyframeEffect;
