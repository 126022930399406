/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Toggle element id in selection.
 *
 * If the given id is currently selected, remove it from selection.
 *
 * Otherwise add the given id to the current selection.
 *
 * If no id is given, do nothing.
 *
 * @param {Object} state Current state
 * @param {Object} payload Action payload
 * @param {string} payload.elementId Id to either add or remove from selection.
 * @return {Object} New state
 */
function toggleElement(state, { elementId }) {
  if (!elementId) {
    return state;
  }

  const wasSelected = state.selection.includes(elementId);
  const currentPage = state.pages.find(({ id }) => id === state.current);
  const backgroundElementId = currentPage.elements[0].id;
  const isBackgroundElement = backgroundElementId === elementId;
  const hasExistingSelection = state.selection.length > 0;
  const allIds = [elementId];

  // If it wasn't selected, we're adding the element to the selection.
  if (!wasSelected) {
    // The bg element can't be added to non-empty selection
    if (isBackgroundElement && hasExistingSelection) {
      return state;
    }

    // The resulting selection will be only the new element under three circumstances:
    // * if old selection was just the background element
    // * if old selection was just a locked element
    // * if new selection is a locked element
    const selectionWasOnlyBackground =
      state.selection.includes(backgroundElementId);
    const getElementById = (byId) =>
      currentPage.elements.find(({ id }) => id === byId);
    const oldElementIsLocked =
      state.selection.length > 0
        ? getElementById(state.selection[0])?.isLocked
        : false;
    const newElement = getElementById(elementId);
    const resultIsOnlyNewElement =
      selectionWasOnlyBackground || oldElementIsLocked || newElement?.isLocked;

    // If either of those, return a selection with only the new element(s)
    if (resultIsOnlyNewElement) {
      return {
        ...state,
        selection: allIds,
      };
    }

    // Otherwise add the new element(s) to the existing selection and make unique
    return {
      ...state,
      selection: [...new Set(state.selection.concat(allIds))],
    };
  }

  // Otherwise we're removing from selection, so just filter out from current selection
  return {
    ...state,
    selection: state.selection.filter((id) => id !== elementId),
  };
}

export default toggleElement;
