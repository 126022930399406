import { useCallback } from '@web-stories-wp/react';
import { __ } from '@web-stories-wp/i18n';
import useInsertElement from '@web-stories-wp/story-editor/src/components/canvas/useInsertElement';
import { useExternalMedia } from '@src/web-stories-wp/story-editor/src/app/externalMedia';
import GeneralVideoRowContainer from '../mediaContainers/generalVideoRowContainer';
import { useMemo } from 'react';
import GeneralImageRowContainer from '../mediaContainers/generalImageRowContainer';
import createResourceFromExternalMedia from '../helpers/createResourceFromExternalMedia';
import { is3pMediaProvider } from '@src/web-stories-wp/story-editor/src/app/media/media3p/providerConfiguration';

export default function ExternalMediaRow({
  asset,
  lastElementRef,
  listContainerRef,
  enableDeletion,
  enableEditInAzzuu,
  ...editMenuProps
}) {
  const {
    actions: { deleteMedia },
    state: { mediaType },
  } = useExternalMedia();

  const is3pMedia = is3pMediaProvider(mediaType.value);

  const insertElement = useInsertElement();

  const onRowClicked = useCallback(async () => {
    const resource = await createResourceFromExternalMedia(asset);
    insertElement(resource.type, { resource });
  }, [insertElement]);

  const isVideoAsset = useMemo(
    () => asset.metadata?.filetype?.includes('video'),
    [asset]
  );

  const handleOnDelete = useCallback(() => {
    if (is3pMedia) return;
    deleteMedia(asset.id);
  }, [asset.id, deleteMedia]);

  const handleOnEditInAzzuu = useCallback(() => {
    if (is3pMedia) return;
    if (!asset.externalCallbackUrl) return;
    window.open(asset.externalCallbackUrl, '_blank');
  }, [asset.externalCallbackUrl]);

  return isVideoAsset ? (
    <GeneralVideoRowContainer
      video={asset}
      onRowClicked={onRowClicked}
      lastElementRef={lastElementRef}
      listContainerRef={listContainerRef}
      providerType={mediaType.value}
      handleOnDelete={enableDeletion && !is3pMedia ? handleOnDelete : undefined}
      handleOnEditInAzzuu={
        enableEditInAzzuu && !is3pMedia && asset.externalCallbackUrl
          ? handleOnEditInAzzuu
          : undefined
      }
      {...editMenuProps}
    />
  ) : (
    <GeneralImageRowContainer
      asset={asset}
      onRowClicked={onRowClicked}
      lastElementRef={lastElementRef}
      providerType={mediaType.value}
      handleOnDelete={enableDeletion && !is3pMedia ? handleOnDelete : undefined}
      handleOnEditInAzzuu={
        enableEditInAzzuu && !is3pMedia && asset.externalCallbackUrl
          ? handleOnEditInAzzuu
          : undefined
      }
      {...editMenuProps}
    />
  );
}
