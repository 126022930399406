import styled, { css } from 'styled-components';
import { Button } from '@web-stories-wp/design-system';

export const ButtonStyle = css`
  color: white;
  height: 32px;
  svg {
    width: 24px;
    height: auto;
  }
`;

export const StudioButton = styled(Button)`
  ${ButtonStyle}
`;
