/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  useEffect,
  useCallback,
  useMemo,
  memo,
  useState,
  useRef,
} from '@web-stories-wp/react';
import { rgba } from 'polished';
import { __ } from '@web-stories-wp/i18n';
import {
  LoadingBar,
  useKeyDownEffect,
  Icons,
} from '@web-stories-wp/design-system';

/**
 * Internal dependencies
 */
import { KEYBOARD_USER_SELECTOR } from '../../../../../utils/keyboardOnlyOutline';
import useRovingTabIndex from '../../../../../utils/useRovingTabIndex';
import { ContentType } from '../../../../../app/media';
import Tooltip from '../../../../tooltip';
import InnerElement from './innerElement';
import ResourceInfo from './resourceInfo';
import UnstyledMediaEditMenu from './mediaEditMenu';
import DeleteDialog from '../local/deleteDialog';
import MediaEditDialog from '../local/mediaEditDialog';

const AUTOPLAY_PREVIEW_VIDEO_DELAY_MS = 600;

const Container = styled.div.attrs((props) => ({
  style: {
    width: props.width + 'px',
    height: props.height + 'px',
    margin: props.margin,
    backgroundColor: 'transparent',
    color: 'inherit',
    border: 'none',
    padding: 0,
    ...(props.width > 0 ? {} : { maxWidth: `calc(50% - ${props.margin} * 3)` }),
  },
}))``;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.standard.black, 0.3)};
  body${KEYBOARD_USER_SELECTOR} .mediaElement:focus > & {
    outline: solid 2px #fff;
  }
`;

const VideoIndicator = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

function Element({
  index,
  isEditMenuExpanded,
  resource,
  width: requestedWidth,
  height: requestedHeight,
  margin,
  onInsert,
  providerType,
  canEditMedia,
  handleOnDelete,
  handleOnEditInAzzuu,
  setExpandedMenuIndex,
}) {
  const {
    id: resourceId,
    src,
    type,
    width: originalWidth,
    height: originalHeight,
    local,
    alt,
    isMuted,
    isTranscoding,
    isMuting,
    isTrimming,
  } = resource;

  const oRatio =
    originalWidth && originalHeight ? originalWidth / originalHeight : 1;
  const width = requestedWidth || requestedHeight / oRatio;
  const height = requestedHeight || width / oRatio;

  const mediaElement = useRef();
  const [showVideoDetail, setShowVideoDetail] = useState(true);
  const [active, setActive] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const makeActive = useCallback(() => setActive(true), []);
  const makeInactive = useCallback(() => setActive(false), []);

  const [hoverTimer, setHoverTimer] = useState(null);
  const activeRef = useRef(active);
  activeRef.current = active;

  const isProcessing = local || isTranscoding || isMuting || isTrimming;

  const handleOpenEditMenu = useCallback(() => {
    setExpandedMenuIndex?.(index);
  }, [index, setExpandedMenuIndex]);

  const handleCloseEditMenu = useCallback(() => {
    setExpandedMenuIndex?.(-1);
  }, [setExpandedMenuIndex]);

  const canEditOrDeleteMedia = useMemo(
    () => providerType === 'local' && canEditMedia,
    [providerType, canEditMedia]
  );

  const canDeleteMedia = useMemo(
    () => canEditOrDeleteMedia || handleOnDelete,
    [canEditOrDeleteMedia, handleOnDelete]
  );

  const handleEditMedia = useCallback(
    () => setIsEditDialogOpen(true),
    [setIsEditDialogOpen]
  );

  const handleDeleteMedia = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, [setIsDeleteDialogOpen]);

  const canMediaPlayOnHover = useMemo(
    () => !isEditDialogOpen && !isDeleteDialogOpen,
    [isEditDialogOpen, isDeleteDialogOpen]
  );

  useEffect(() => {
    if (![ContentType.VIDEO, ContentType.GIF].includes(type)) {
      return;
    }

    if (
      !canMediaPlayOnHover &&
      mediaElement.current &&
      !mediaElement.current.paused
    ) {
      mediaElement.current.pause();
    }
  }, [canMediaPlayOnHover, type]);

  useEffect(() => {
    if (![ContentType.VIDEO, ContentType.GIF].includes(type)) {
      return undefined;
    }
    const resetHoverTime = () => {
      if (hoverTimer !== null) {
        clearTimeout(hoverTimer);
        setHoverTimer(null);
      }
    };

    if (active) {
      setShowVideoDetail(false);
      if (mediaElement.current && hoverTimer == null && canMediaPlayOnHover) {
        mediaElement.current.muted = false;
        const timer = setTimeout(() => {
          if (activeRef.current && src) {
            mediaElement.current.play().catch(() => {});
          }
        }, AUTOPLAY_PREVIEW_VIDEO_DELAY_MS);
        setHoverTimer(timer);
        // Pointer still in the media element, continue the video.
      }
    } else {
      setShowVideoDetail(true);
      resetHoverTime();
      if (mediaElement.current && src) {
        // Stop video and reset position.
        mediaElement.current.pause();
        mediaElement.current.currentTime = 0;
        mediaElement.current.muted = true;
      }
    }

    return resetHoverTime;
  }, [
    active,
    canMediaPlayOnHover,
    type,
    src,
    hoverTimer,
    setHoverTimer,
    activeRef,
  ]);

  const onClick = (thumbnailUrl, baseColor) => () => {
    if (isProcessing) return;
    onInsert({ ...resource, baseColor }, mediaElement.current, thumbnailUrl);
  };

  const shouldShowResourceInfo = active && resource.title;

  const resourceInfo = shouldShowResourceInfo && (
    <ResourceInfo
      title={resource.title}
      date={resource.updateTime || resource.creationTime}
    />
  );

  const ref = useRef();

  useRovingTabIndex({ ref });

  const handleKeyDown = useCallback(
    ({ key }) => {
      if (key === 'Enter') {
        onInsert(resource, mediaElement.current);
      } else if (key === ' ') {
        handleOpenEditMenu();
      }
    },
    [handleOpenEditMenu, onInsert, resource, width, height]
  );

  useKeyDownEffect(
    ref,
    {
      key: ['enter', 'space'],
    },
    handleKeyDown,
    [handleKeyDown]
  );

  const MediaEditMenu = styled(UnstyledMediaEditMenu)`
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 10;
  `;

  const canShowEditMenu = useMemo(
    () =>
      setExpandedMenuIndex &&
      (canEditOrDeleteMedia || handleOnDelete || handleOnEditInAzzuu),
    [
      setExpandedMenuIndex,
      canEditOrDeleteMedia,
      handleOnDelete,
      handleOnEditInAzzuu,
    ]
  );

  return (
    <Container
      ref={ref}
      data-testid={`mediaElement-${type}`}
      data-id={resourceId}
      className={'mediaElement'}
      width={width}
      height={height}
      margin={margin}
      onPointerEnter={makeActive}
      onFocus={makeActive}
      onPointerLeave={makeInactive}
      onBlur={makeInactive}
      tabIndex={index === 0 ? 0 : -1}
    >
      <InnerContainer>
        {canShowEditMenu && (
          <MediaEditMenu
            isOpen={isEditMenuExpanded}
            handleClose={handleCloseEditMenu}
            handleOpen={handleOpenEditMenu}
            handleOnDelete={canDeleteMedia ? handleDeleteMedia : undefined}
            handleOnEdit={canEditOrDeleteMedia ? handleEditMedia : undefined}
            handleOnEditInAzzuu={handleOnEditInAzzuu}
          />
        )}
        <InnerElement
          type={type}
          src={src}
          mediaElement={mediaElement}
          resource={resource}
          alt={alt}
          isMuted={isMuted}
          width={width}
          height={height}
          onClick={onClick}
          showVideoDetail={showVideoDetail}
          active={active}
        />
        <VideoIndicator>
          {!active && [ContentType.VIDEO, ContentType.GIF].includes(type) && (
            <Icons.PlayFilled />
          )}
        </VideoIndicator>
        {resourceInfo}
        {isProcessing && (
          <LoadingBar loadingMessage={__('Uploading media', 'web-stories')} />
        )}
        {canEditOrDeleteMedia && (
          <MediaEditDialog
            isOpen={isEditDialogOpen}
            resource={resource}
            onClose={() => setIsEditDialogOpen(false)}
          />
        )}
        {canDeleteMedia && (
          <DeleteDialog
            isOpen={isDeleteDialogOpen}
            mediaId={resource.id}
            type={resource.type}
            onClose={() => setIsDeleteDialogOpen(false)}
            handleOnDelete={handleOnDelete}
          />
        )}
      </InnerContainer>
    </Container>
  );
}

Element.propTypes = {
  index: PropTypes.number.isRequired,
  isEditMenuExpanded: PropTypes.bool.isRequired,
  resource: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.string,
  onInsert: PropTypes.func,
  providerType: PropTypes.string,
  canEditMedia: PropTypes.bool,
  handleOnDelete: PropTypes.func,
  handleOnEditInAzzuu: PropTypes.func,
  setExpandedMenuIndex: PropTypes.func,
};

/**
 * Get a formatted element for different media types.
 *
 * @param {Object} props Component props.
 * @param {number} props.index Index of the media element in the gallery.
 * @param {boolean|undefined} props.isEditMenuExpanded Whether the edit menu is expanded.
 * @param {Object} props.resource Resource object
 * @param {number} props.width Width that element is inserted into editor.
 * @param {number} props.height Height that element is inserted into editor.
 * @param {string?} props.margin The margin in around the element
 * @param {Function} props.onInsert Insertion callback.
 * @param {string} props.providerType Which provider the element is from.
 * @param {Function|undefined} props.setExpandedMenuIndex Sets the index of the currently expanded edit menu.
 * @return {null|*} Element or null if does not map to video/image.
 */
function MediaElement(props) {
  const { isTranscoding } = props.resource;

  if (isTranscoding) {
    return (
      <Tooltip title={__('Video optimization in progress', 'web-stories')}>
        <Element {...props} />
      </Tooltip>
    );
  }

  return <Element {...props} />;
}

MediaElement.propTypes = Element.propTypes;

MediaElement.defaultProps = {
  providerType: 'local',
  canEditMedia: false,
};

export default memo(MediaElement);
