/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { _x } from '@web-stories-wp/i18n';
import PropTypes from 'prop-types';

const title = _x('Green Bag', 'sticker name', 'web-stories');
function GreenBag({ style }) {
  return (
    <svg
      style={style}
      viewBox="0 0 39 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        d="M19.3719 12.8035C13.1102 12.8035 7.97003 17.9437 7.97003 24.2988C7.97003 30.6539 13.1102 35.7941 19.3719 35.7941C25.6336 35.7941 30.7738 30.6539 30.7738 24.2988C30.6803 17.9437 25.6336 12.8035 19.3719 12.8035ZM19.3719 34.7661C13.671 34.7661 8.99807 30.0932 8.99807 24.2988C8.99807 18.5044 13.671 13.8315 19.3719 13.8315C25.0728 13.8315 29.7457 18.5044 29.7457 24.2988C29.7457 30.0932 25.0728 34.7661 19.3719 34.7661Z"
        fill="#235524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0261078 0V4.20561C0.0261078 4.96432 0.200513 6.13405 0.726785 7.22418C1.18822 8.18002 1.93554 9.10203 3.09413 9.60206C2.62052 10.6047 2.23808 12.1349 1.95891 13.984C1.62378 16.2037 1.42798 18.9534 1.42798 21.9626C1.42798 24.9916 1.86107 28.6049 2.48767 31.8867C3.07711 34.9739 3.84491 37.8053 4.60277 39.5977C3.97841 40.1719 3.15795 41.0209 2.45686 41.9498C2.05572 42.4813 1.68626 43.0487 1.41546 43.6138C1.14684 44.1742 0.960687 44.7624 0.960687 45.3271V50H37.4093V45.3271C37.4093 44.7624 37.2231 44.1742 36.9545 43.6138C36.6837 43.0487 36.3143 42.4813 35.9131 41.9498C35.2123 41.0213 34.3923 40.1727 33.768 39.5984C34.534 37.8157 35.412 34.9927 36.1127 31.9024C36.8565 28.6215 37.4093 25.0015 37.4093 21.9626C37.4093 18.9408 37.0946 16.1836 36.6405 13.9602C36.2614 12.1044 35.7787 10.5881 35.2826 9.59912C36.4374 9.09843 37.1827 8.17815 37.6432 7.22418C38.1695 6.13405 38.3439 4.96432 38.3439 4.20561V0H0.0261078ZM0.960687 0.934579V4.20561C0.960687 4.84876 1.11338 5.8753 1.56842 6.81787C2.01999 7.75327 2.74899 8.56968 3.8928 8.89648L4.49478 9.06848L4.15866 9.59667C3.67088 10.3632 3.21348 11.9347 2.88302 14.1235C2.55616 16.2885 2.36256 18.9905 2.36256 21.9626C2.36256 24.915 2.78616 28.4668 3.40567 31.7114C4.02649 34.963 4.836 37.8637 5.5917 39.5263L5.73546 39.8425L5.47401 40.0713C4.87008 40.5997 3.95874 41.5112 3.20283 42.5128C2.82516 43.0132 2.49368 43.5264 2.25825 44.0177C2.02064 44.5135 1.89527 44.9572 1.89527 45.3271V49.0654H36.4747V45.3271C36.4747 44.9572 36.3493 44.5135 36.1117 44.0177C35.8763 43.5264 35.5448 43.0132 35.1671 42.5128C34.4112 41.5112 33.4999 40.5997 32.896 40.0713L32.6345 39.8425L32.7783 39.5263C33.5388 37.8532 34.4649 34.9433 35.2012 31.6957C35.9371 28.4501 36.4747 24.905 36.4747 21.9626C36.4747 19.0031 36.1663 16.3086 35.7248 14.1473C35.2801 11.9701 34.7095 10.3795 34.2113 9.59667L33.8752 9.06848L34.4772 8.89648C35.621 8.56968 36.35 7.75327 36.8016 6.81787C37.2566 5.8753 37.4093 4.84876 37.4093 4.20561V0.934579H0.960687Z"
        fill="#235524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.942 45.3271H1.42798V44.3925H36.942V45.3271Z"
        fill="#235524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8766 3.27103H0.493398V2.33645H37.8766V3.27103Z"
        fill="#235524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83919 8.41121C9.83919 8.15314 10.0484 7.94393 10.3065 7.94393H27.1289C27.387 7.94393 27.5962 8.15314 27.5962 8.41121C27.5962 8.66929 27.387 8.8785 27.1289 8.8785H10.3065C10.0484 8.8785 9.83919 8.66929 9.83919 8.41121Z"
        fill="#235524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83919 40.1869C9.83919 39.9288 10.0484 39.7196 10.3065 39.7196H27.1289C27.387 39.7196 27.5962 39.9288 27.5962 40.1869C27.5962 40.445 27.387 40.6542 27.1289 40.6542H10.3065C10.0484 40.6542 9.83919 40.445 9.83919 40.1869Z"
        fill="#235524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1188 26.718C18.3117 25.8397 18.2413 24.6888 17.8055 23.4968C17.4047 22.401 16.5746 21.5412 15.7202 20.9881C15.2959 20.7134 14.891 20.5314 14.5695 20.4362C14.3481 20.3706 14.2214 20.3619 14.1707 20.36C14.1332 20.3942 14.042 20.4826 13.9151 20.6755C13.7309 20.9556 13.5389 21.3559 13.3918 21.8395C13.0957 22.8133 13.016 24.0057 13.4168 25.1016C13.8526 26.2935 14.5413 27.2184 15.2552 27.7651C15.9796 28.3198 16.6112 28.4114 17.0554 28.249C17.4995 28.0866 17.9231 27.6092 18.1188 26.718ZM17.3763 29.1268C19.073 28.5064 19.6581 25.8421 18.6832 23.1759C17.7083 20.5097 14.7236 19.1507 13.8459 19.4716C12.9681 19.7926 11.5641 22.7563 12.539 25.4225C13.5139 28.0887 15.6797 29.7472 17.3763 29.1268Z"
        fill="#235524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3763 29.1268C17.134 29.2154 16.8656 29.0908 16.777 28.8484L15.1722 24.4597C15.0836 24.2173 15.2082 23.949 15.4506 23.8603C15.693 23.7717 15.9613 23.8964 16.05 24.1387L17.6547 28.5274C17.7434 28.7698 17.6187 29.0382 17.3763 29.1268Z"
        fill="#235524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1463 28.6488C23.8874 28.1395 24.6228 27.2513 25.1194 26.0833C25.5759 25.0095 25.5577 23.8146 25.3121 22.8269C25.1901 22.3363 25.019 21.9267 24.8494 21.6375C24.7326 21.4383 24.6461 21.3453 24.6104 21.3092C24.5596 21.3086 24.4326 21.3107 24.2082 21.3648C23.8822 21.4434 23.4685 21.6043 23.0306 21.8568C22.1488 22.3652 21.2756 23.1811 20.819 24.2549C20.3225 25.4229 20.193 26.5687 20.3404 27.4558C20.49 28.3558 20.8884 28.8544 21.3236 29.0394C21.7588 29.2245 22.3944 29.1655 23.1463 28.6488ZM20.958 29.8995C22.6205 30.6064 24.8687 29.0615 25.9794 26.449C27.0902 23.8365 25.8405 20.8045 24.9805 20.4388C24.1204 20.0731 21.0698 21.2767 19.959 23.8892C18.8482 26.5018 19.2954 29.1926 20.958 29.8995Z"
        fill="#235524"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.958 29.8995C20.7205 29.7985 20.6098 29.5241 20.7108 29.2866L22.5392 24.9863C22.6402 24.7488 22.9146 24.6381 23.1521 24.7391C23.3896 24.8401 23.5002 25.1145 23.3992 25.352L21.5708 29.6523C21.4698 29.8898 21.1955 30.0005 20.958 29.8995Z"
        fill="#235524"
      />
    </svg>
  );
}

GreenBag.propTypes = {
  style: PropTypes.object,
};

export default {
  aspectRatio: 39 / 50,
  svg: GreenBag,
  title,
};
