import { useMemo } from 'react';
import { PROVIDERS } from './media3p/providerConfiguration';
import { ProviderType } from './media3p/constants';
import { useConfig } from '../config';

function useMediaProvidersOptions() {
  const { mediaProviders } = useConfig();

  return useMemo(() => {
    if (!mediaProviders) return [];

    return mediaProviders.map((provider) => {
      const unifiedCode = provider.code.toLowerCase();

      return {
        label: provider.title,
        value: {
          ...provider,
          providerConfigurationKey: unifiedCode,
        },
      };
    });
  }, [mediaProviders]);
}

export default useMediaProvidersOptions;
