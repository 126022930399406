import { useMemo } from 'react';
import { useEnvConfig } from '@hooks';
import {
  authContext,
  deleteContext,
  refreshTokenUrl,
} from '@services/authorization';

const useAuthorizationManager = () => {
  const config = useEnvConfig();
  const initializedAuthContext = useMemo(() => {
    if (config.cmsUrl) {
      authContext.initialize(config.cmsUrl);
    }
    return authContext;
  }, [config.cmsUrl]);

  return { authContext: initializedAuthContext, deleteContext };
};

export { refreshTokenUrl, useAuthorizationManager };
