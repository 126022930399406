import { __ } from '@web-stories-wp/i18n';
import { useRef } from '@web-stories-wp/react';

import { Section } from '../../common';
import useRovingTabIndex from '../../../../utils/useRovingTabIndex';
import ShapePreview from './shapePreview';
import { SectionContent } from './sectionContent';

export function CustomShapesSection({ customShapes }) {
  const customShapesRef = useRef(null);
  useRovingTabIndex({ ref: customShapesRef });

  if (!customShapes || !customShapes.length) {
    return null;
  }

  return (
    <Section
      data-testid="custom-shapes-library-pane"
      title={__('Your Shapes', 'web-stories')}
    >
      <SectionContent ref={customShapesRef}>
        {customShapes.map((mask, i) => (
          <ShapePreview mask={mask} key={mask.type} index={i} isPreview />
        ))}
      </SectionContent>
    </Section>
  );
}