import { useCallback } from 'react';
import {
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_VARIANTS,
  Icons,
  Tooltip,
  useSnackbar,
} from '@web-stories-wp/design-system';
import { StudioButton } from './studioButton';
import { useConfig, useStory } from '@web-stories-wp/story-editor';
import getStoryMarkup from '@web-stories-wp/story-editor/src/output/utils/getStoryMarkup';

const ariaLabel = 'Copy AMP HTML Page definition to clipboard.';

export function CopyPageAmpHtmlToClipboardButton({ disabled }) {
  const { showSnackbar } = useSnackbar();

  const copyToClipboard = useCallback(
    (content) => {
      navigator.clipboard.writeText(content).then(
        () => {
          showSnackbar({
            message: 'AMP HTML Page definition copied to clipboard.',
            dismissable: true,
          });
        },
        () => {
          showSnackbar({
            message: 'AMP HTML Page definition copy to clipboard failed!',
            dismissable: true,
          });
        }
      );
    },
    [showSnackbar]
  );

  const { currentPage, story } = useStory(
    ({ state: { currentPage, story } }) => ({
      currentPage,
      story,
    })
  );
  const { metadata } = useConfig();

  return (
    <Tooltip title={ariaLabel} hasTail>
      <StudioButton
        variant={BUTTON_VARIANTS.SQUARE}
        type={BUTTON_TYPES.SECONDARY}
        size={BUTTON_SIZES.SMALL}
        onClick={() => {
          const storyMarkup = getStoryMarkup(story, [currentPage], metadata);
          copyToClipboard(storyMarkup);
        }}
        aria-label={ariaLabel}
        disabled={disabled}
      >
        <Icons.PagePlus />
      </StudioButton>
    </Tooltip>
  );
}
