/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import styled from 'styled-components';
import {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from '@web-stories-wp/react';
import { useUnits } from '@web-stories-wp/units';

/**
 * Internal dependencies
 */
import StoryPropTypes from '../../types';
import { getDefinitionForType } from '../../elements';
import { useStory, useTransform, useCanvas, useDropTargets } from '../../app';
import {
  elementWithPosition,
  elementWithSize,
  elementWithRotation,
} from '../../elements/shared';
import WithMask from '../../masks/frame';
import WithLink from '../elementLink/frame';
import { useTransformHandler } from '../transform';
import useDoubleClick from '../../utils/useDoubleClick';

// @todo: should the frame borders follow clip lines?

// Pointer events are disabled in the display mode to ensure that selection
// can be limited to the mask.
const Wrapper = styled.div`
  ${elementWithPosition}
  ${elementWithSize}
	${elementWithRotation}

  outline: 1px solid transparent;
  transition: outline-color 0.5s;

  &:focus,
  &:active {
    outline-color: ${({ theme, hasMask }) =>
      hasMask ? 'transparent' : theme.colors.border.selection};
  }

  ${({ isLocked, hasMask, theme }) =>
    !isLocked &&
    !hasMask &&
    `
    &:hover {
      outline-color: ${theme.colors.border.selection};
    }
  `}

  ${({ isClickable }) => !isClickable && `pointer-events: none;`}
`;

const EmptyFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const NOOP = () => {};

function FrameElement({ element }) {
  const {
    setEditingElement,
    setNodeForElement,
    handleSelectElement,
    isEditing,
  } = useCanvas((state) => ({
    setEditingElement: state.actions.setEditingElement,
    setNodeForElement: state.actions.setNodeForElement,
    handleSelectElement: state.actions.handleSelectElement,
    isEditing: state.state.isEditing,
  }));

  const {
    draggingResource,
    activeDropTargetId,
    isDropSource,
    registerDropTarget,
    unregisterDropTarget,
  } = useDropTargets(
    ({
      state: { draggingResource, activeDropTargetId },
      actions: { isDropSource, registerDropTarget, unregisterDropTarget },
    }) => ({
      draggingResource,
      activeDropTargetId,
      isDropSource,
      registerDropTarget,
      unregisterDropTarget,
    })
  );

  const { id, type, flip, isLocked, isHidden } = element;

  const { isSelected, isOnlySelectedElement, isBackground } = useStory(
    ({ state }) => {
      const isSelected = state.selectedElementIds.includes(id);
      const isOnlySelectedElement =
        isSelected && state.selectedElementIds.length === 1;

      return {
        isSelected,
        isBackground: state.currentPage?.elements[0].id === id,
        element: state.currentPage?.elements.find((el) => el.id === id),
        isOnlySelectedElement,
      };
    }
  );

  // Unlocked elements are always clickable,
  // locked elements are only clickable if selected,
  // hidden elements are never clickable.
  const isClickable = (!isLocked || isSelected) && !isHidden;
  const { Frame, isMaskable, Controls } = getDefinitionForType(type);
  const elementRef = useRef();
  const [hovering, setHovering] = useState(false);

  const isAnythingTransforming = useTransform(
    ({ state }) => !isSelected && hovering && !state.isAnythingTransforming
  );

  const onPointerEnter = () => setHovering(true);
  const onPointerLeave = () => setHovering(false);

  const { getBox } = useUnits((state) => ({
    getBox: state.actions.getBox,
  }));

  useLayoutEffect(() => {
    setNodeForElement(id, elementRef.current);
  }, [id, setNodeForElement]);
  const box = getBox(element);

  const [isTransforming, setIsTransforming] = useState(false);

  useTransformHandler(id, (transform) => {
    const target = elementRef.current;
    if (transform?.dropTargets?.hover !== undefined) {
      target.style.opacity = transform.dropTargets.hover ? 0 : 1;
    }
    setIsTransforming(transform !== null);
  });

  // Media needs separate handler for double click.
  const { isMedia } = getDefinitionForType(type);
  const handleMediaDoubleClick = useCallback(
    (evt) => {
      if (!isSelected) {
        handleSelectElement(id, evt);
      }
      setEditingElement(id);
    },
    [id, setEditingElement, handleSelectElement, isSelected]
  );
  const handleMediaClick = useDoubleClick(NOOP, handleMediaDoubleClick);

  const eventHandlers = {
    onMouseDown: (evt) => {
      if (!isSelected) {
        handleSelectElement(id, evt);
      }
      elementRef.current.focus({ preventScroll: true });
      if (!isBackground) {
        evt.stopPropagation();
      }
    },
    onFocus: (evt) => {
      if (!isSelected) {
        handleSelectElement(id, evt);
      }
    },
    onPointerEnter,
    onPointerLeave,
    onClick: isMedia ? handleMediaClick(id) : null,
  };

  return (
    <WithLink
      element={element}
      active={isAnythingTransforming && !isLocked}
      anchorRef={elementRef}
    >
      {Controls && (
        <Controls
          isTransforming={isTransforming}
          isSelected={isSelected}
          isSingleElement={isOnlySelectedElement}
          isEditing={isEditing}
          box={box}
          elementRef={elementRef}
          element={element}
        />
      )}
      <Wrapper
        ref={elementRef}
        data-element-id={id}
        {...box}
        // Needed for being able to focus on the selected element on canvas, e.g. for entering edit mode.
        // eslint-disable-next-line styled-components-a11y/no-noninteractive-tabindex
        tabIndex={0}
        aria-labelledby={`layer-${id}`}
        hasMask={isMaskable}
        isClickable={isClickable}
        data-testid="frameElement"
        {...eventHandlers}
      >
        <WithMask
          element={element}
          fill
          flip={flip}
          draggingResource={draggingResource}
          activeDropTargetId={activeDropTargetId}
          isDropSource={isDropSource}
          registerDropTarget={registerDropTarget}
          unregisterDropTarget={unregisterDropTarget}
          isSelected={isSelected}
        >
          {Frame ? (
            <Frame wrapperRef={elementRef} element={element} box={box} />
          ) : (
            <EmptyFrame />
          )}
        </WithMask>
      </Wrapper>
    </WithLink>
  );
}

FrameElement.propTypes = {
  element: StoryPropTypes.element.isRequired,
};

export default FrameElement;
