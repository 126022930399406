import { useEffect } from '@web-stories-wp/react';
import { useHistory } from '@web-stories-wp/story-editor/src/app/history';
import { usePrevious } from '@web-stories-wp/react';

const useClearImportHistory = (isImporting) => {
  const previousIsImporting = usePrevious(isImporting);

  const {
    actions: { clearHistory },
  } = useHistory();

  useEffect(() => {
    if (previousIsImporting && !isImporting) {
      clearHistory();
    }
  }, [isImporting, clearHistory, previousIsImporting]);
};

export default useClearImportHistory;
