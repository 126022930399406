import styled from 'styled-components';
import { theme } from '@src/web-stories-wp/design-system/src';

const FailedToLoadAssets = styled.div`
  text-align: center;
  margin-top: 24px;
  color: ${theme.colors.gray[30]};
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RetryButton = styled.button`
  margin: 0 auto;
  padding: 8px 20px;
  color: white;
  border: none;
  background: ${({ theme }) => theme.colors.gray[70]};
  border-radius: 6px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[60]};
    border: ;
  }
`;

function MediaError({ onRetry }) {
  return (
    <FailedToLoadAssets>
      Failed to load assets.
      <RetryButton onClick={onRetry}>Retry</RetryButton>
    </FailedToLoadAssets>
  );
}

export default MediaError;
